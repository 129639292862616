import {
  Box,
  Card,
  CardBody,
  Flex,
  Image,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { getBackgroundColor } from "../../utils";

const GrowthPlanCard = ({
  leftTitle = "",
  rightTitle = "",
  leftAmount = "$0.00",
  rightAmount = "$0.00",
  rightPercentage = "0%",
  cardHeight = "108px",
  cardWidth = "100%",
  color = "black",
  image = null,
  breakdownTitle,
  type,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  return (
    <Card
      fontFamily={"lato"}
      width={cardWidth}
      height={cardHeight}
      borderRadius="10px"
      overflow="hidden"
      position="relative"
      onClick={() => {
        type && setBreakdownData({ title: breakdownTitle, type: type });
        type && triggerExpenseBreakdown(true);
      }}
      _hover={type && { boxShadow: "xl", cursor: "pointer" }}
    >
      <CardBody
        p={"16px 24px"}
        display="flex"
        alignItems="center"
        position="relative"
      >
        {/* Left Section */}
        <Box flex="1" height="64px" textAlign="left">
          <Flex
            height="auto"
            align="center"
            justify="flex-start" /* Ensures content aligns to the very left */
            gap={4}
            position="relative"
          >
            <Box width="100%" textAlign="left">
              <Box
                p="0px 0px 2px 0px"
                whiteSpace="nowrap"
                fontSize="16px"
                color="#202224"
                height="25px"
              >
                {leftTitle}
              </Box>
              <Box fontWeight={800} height="auto" maxHeight="30px">
                <Text
                  whiteSpace="nowrap"
                  fontSize="clamp(22px, calc(1.6cqw - 0.09vw), 30px)"
                >
                  {leftAmount}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>

        {/* Divider (fixed position) */}
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="2px"
          height="64px"
          backgroundColor="gray.300"
        ></Box>

        {/* Right Section */}
        <Box flex="1" height="64px" pl="20px" textAlign="left">
          <Flex
            height="auto"
            align="center"
            justify="flex-start"
            gap={4}
            position="relative"
          >
            <Box width="100%" textAlign="left">
              <Box
                p="0px 0px 2px 0px"
                fontSize="16px"
                height="25px"
                color="#202224"
              >
                <Text whiteSpace="nowrap">{rightTitle}</Text>
              </Box>
              <Box height="64px">
                <Stack p={0}>
                  <Box
                    whiteSpace="nowrap"
                    fontSize="clamp(14px, calc(1.5cqw - 0.11vw), 18px)"
                    fontWeight={600}
                    lineHeight={1}
                  >
                    {rightAmount}
                    <Text
                      whiteSpace="nowrap"
                      fontSize="clamp(12px, calc(1.5cqw - 0.11vw), 14px)"
                      fontWeight={200}
                      p="6px 0px"
                      color={color}
                    >
                      {rightPercentage}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Flex>
        </Box>

        {/* Image Section */}
        <Box
          position="absolute"
          top="50%"
          right="20px"
          transform="translateY(-50%)"
          backgroundColor={!isMobile && getBackgroundColor(image)}
          borderRadius="15px"
          width="64px"
          height="64px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!isMobile && (
            <Image src={image} alt="Image" boxSize="40px" objectFit="contain" />
          )}
        </Box>
      </CardBody>
    </Card>
  );
};

export default GrowthPlanCard;
