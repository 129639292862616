import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  createTag,
  deleteTag,
  fetchTags,
  updateTag,
} from "../../api/resources";
import { Context } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";
import { GenericModal } from "../generic/modal";

export const ResourceTagManagment = ({ toggle, customer_id }) => {
  const [loading, setLoading] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState({});

  const context = React.useContext(Context);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTags(customer_id);
      setTagList(data);
    };
    fetchData();
    setLoading(false);
  }, [customer_id]);

  const startCreateTag = () => {
    setCurrentTag({});
    setIsModalOpen(true);
  };

  const toggleEdit = () => {
    setCurrentTag({});
    setIsModalOpen(!isModalOpen);
  };

  const startUpdateTag = (resourceTagData) => {
    setCurrentTag(resourceTagData);
    setIsModalOpen(true);
  };

  const toggleDelete = () => {
    setCurrentTag({});
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const startDeleteTag = (resourceTagData) => {
    setCurrentTag(resourceTagData);
    setIsDeleteModalOpen(true);
  };

  const handleSubmit = async (event, tag) => {
    event.preventDefault();
    setLoading(true);

    try {
      let res;
      if (tag.id) {
        // Updating an existing resource tag
        res = await updateTag(customer_id, tag.id, tag);

        if (res.status < 300) {
          setTagList(tagList.map((o) => (o.id === res.data.id ? res.data : o)));
          context.fireToast("Success", "Tag has been updated", "success");
          toggleEdit();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      } else {
        // Creating a new resource tag
        res = await createTag(customer_id, tag);

        if (res.status < 300) {
          setTagList([...tagList, res.data]);
          context.fireToast("Success", "Tag added", "success");
          toggleEdit();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      }
    } catch (err) {
      console.error("There was an error updating the resource tag!", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (resourceTagData) => {
    setLoading(true);

    try {
      const res = await deleteTag(customer_id, resourceTagData.id);

      if (res.status < 300) {
        setTagList(tagList.filter((o) => o.id !== resourceTagData.id));
        context.fireToast("Success", "Tag has been deleted", "success");
      } else {
        context.fireToast("Error", res.request.responseText, "error");
      }
    } catch (err) {
      console.error("Error deleting tag:", err);
      context.fireToast("Error", "Failed to delete tag", "error");
    } finally {
      setLoading(false);
      toggleDelete();
    }
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <>
        {loading && (
          <FontAwesomeIcon
            className="float-end fa-spin"
            icon={faSpinner}
            color="green"
          />
        )}
        <Stack direction="column" spacing={2} align="center">
          <CustomTableContainer width="100%">
            <ResourceTagEditModal
              isOpen={isModalOpen}
              toggleEdit={toggleEdit}
              title="Resource Tag"
              resourceTagData={currentTag}
              loading={loading}
              handleSubmit={handleSubmit}
            />
            <ResourceTagDelete
              colorScheme="red"
              isOpen={isDeleteModalOpen}
              toggle={toggleDelete}
              resourceTagData={currentTag}
              loading={loading}
              handleDelete={handleDelete}
            />
            <Flex pb="5">
              <Box>
                <Heading as="h3" size="lg">
                  Resource Tags
                </Heading>
              </Box>
              <Spacer />
              <Box>
                <HStack direction="row" spacing={6} align="center">
                  <Button
                    onClick={startCreateTag}
                    isDisabled={loading}
                    leftIcon={<AddIcon boxSize={3} />}
                  >
                    Create Tag
                  </Button>
                </HStack>
              </Box>
            </Flex>
            <Box maxHeight="calc(100vh - 350px)" bg="white" overflowY={"auto"}>
              <Table variant="unstyled">
                <Thead>
                  <Tr>
                    <Th textAlign={"center"}>Display</Th>
                    <Th textAlign={"center"}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tagList.map((tag) => (
                    <Tr key={tag.id}>
                      <Td textAlign={"center"}>
                        <Box
                          p={"2px, 10px"}
                          fontSize={"13px"}
                          borderRadius={"40px"}
                          style={{ backgroundColor: tag.label_color }}
                        >
                          {tag.name}
                        </Box>
                      </Td>
                      <Td p={"5px 5px"}>
                        <HStack justifyContent="center" alignItems="center">
                          <Button
                            h={"30px"}
                            onClick={() => startUpdateTag(tag)}
                          >
                            Edit
                          </Button>
                          <Button
                            h={"30px"}
                            colorScheme="red"
                            onClick={() => startDeleteTag(tag)}
                          >
                            Delete
                          </Button>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Button mt={"10px"} onClick={() => toggle(false)}>
              Back to resource categories
            </Button>
          </CustomTableContainer>
        </Stack>
      </>
    </Container>
  );
};

const ResourceTagDelete = ({
  isOpen,
  toggle,
  resourceTagData,
  loading,
  handleDelete,
}) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onClose={toggle}
      title="Delete Tag"
      loading={loading}
      body={`Are you sure you want to delete this tag ${resourceTagData.name}?`}
      submitButton={
        <Button
          ml={3}
          colorScheme="red"
          onClick={() => handleDelete(resourceTagData)}
          isDisabled={loading}
        >
          {loading ? "Processing..." : "Delete"}
        </Button>
      }
    />
  );
};

const ResourceTagEditModal = ({
  isOpen,
  toggleEdit,
  title,
  resourceTagData,
  loading,
  handleSubmit,
}) => {
  const confirmText = !resourceTagData.id ? "Create" : "Update";
  const [resourceData, setResourceData] = useState({});
  const [isHexValid, setIsHexValid] = useState(true);

  useEffect(() => {
    setResourceData({
      ...resourceTagData,
      name: resourceTagData.name || "",
      label_color: resourceTagData.label_color || "",
    });
  }, [resourceTagData]);

  const hexRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "label_color") {
      setIsHexValid(hexRegex.test(value));
    }

    setResourceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isHexValid) return;
    handleSubmit(e, resourceData);
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={toggleEdit}
      title={`${confirmText} ${title}`}
      loading={loading}
      body={
        <form id="ResourceTagForm" onSubmit={handleFormSubmit}>
          <FormControl>
            <Text fontSize="20px" textDecoration="underline" padding="10px 0px">
              Tag Name
            </Text>
            <Input
              type="text"
              name="name"
              placeholder="Please enter the name of this resource category."
              value={resourceData.name}
              onChange={handleInputChange}
              required
            />
          </FormControl>

          <FormControl isInvalid={!isHexValid}>
            <Text fontSize="20px" textDecoration="underline" padding="10px 0px">
              Resource Label Color
            </Text>
            <InputGroup>
              <Input
                type="text"
                name="label_color"
                placeholder="Enter a hex code (e.g., #FFFFFF)"
                value={resourceData.label_color}
                onChange={handleInputChange}
                required
              />
              <InputRightElement width="3rem">
                <Box
                  width="2rem"
                  height="2rem"
                  borderRadius="md"
                  border="1px solid"
                  borderColor="gray.300"
                  backgroundColor={
                    isHexValid ? resourceData.label_color : "transparent"
                  }
                />
              </InputRightElement>
            </InputGroup>
            {!isHexValid && (
              <FormErrorMessage>Invalid hex code format.</FormErrorMessage>
            )}
          </FormControl>
        </form>
      }
      submitButton={
        <Button
          type="submit"
          form="ResourceTagForm"
          isDisabled={loading || !isHexValid}
          ml={3}
        >
          {loading ? <Spinner size="sm" /> : confirmText}
        </Button>
      }
    />
  );
};
