import axios from "axios";
import { AccessToken } from "../utils";

const get = (url, params = {}, dropdown_format = false) => {
  if (AccessToken() === null) {
    window.location.href = "/login";
  }
  return axios
    .get(url, {
      headers: { "Content-Type": "application/json" },
      params: params,
      withCredentials: true,
    })
    .then((res) => {
      if (dropdown_format && url.endsWith("/accounts/")) {
        return res.data.map((o) => ({
          value: o.id,
          label: o.number + " - " + o.name,
        }));
      } else if (dropdown_format) {
        const items = res.data.results || res.data;
        return items.map((o) => ({ value: o.id, label: o.name }));
      } else {
        return res.data;
      }
    })
    .catch((err) => console.log(err));
};

const patch = (url, data, contentType = "application/json") => {
  return axios.patch(url, data, {
    headers: { "Content-Type": contentType },
    withCredentials: true,
  });
};

const post = (url, data, contentType = "application/json") => {
  return axios.post(url, data, {
    headers: { "Content-Type": contentType },
    withCredentials: true,
  });
};

const deleteRequest = (url) => {
  return axios.delete(url);
};

export { get, patch, post, deleteRequest };
