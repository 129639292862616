import {
  Box,
  Card,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { customCurrencyFormat } from "../../utils";
import React, { useState } from "react";
import { Circle } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const ProfitCard = ({
  profit = "0.00",
  profitPercentage = "0.00",
  data = {},
  perfectPL = "0.00",
  color = "#458CFF",
  status = false,
  showPL = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Card
      fontFamily={"lato"}
      width="248px"
      height="248px"
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow={"xl"}
    >
      <StatusModal
        isOpen={isModalOpen}
        toggle={toggle}
        color={color}
        data={data}
      />
      <CircularProgress
        value={100}
        size="268px" // Adjusted size to fit within the card better
        thickness="6px"
        color={color}
      >
        <CircularProgressLabel>
          <Box textAlign="center">
            <Box
              fontSize="20px"
              fontWeight="600"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="#6F7D8C"
            >
              Profit
              {status && (
                <Circle
                  cursor="pointer"
                  fontSize="large"
                  color="#6F7D8C"
                  size="16px"
                  bg="white"
                  marginLeft={1}
                  onClick={openModal}
                >
                  <InfoOutlineIcon size={"16px"} />
                </Circle>
              )}
            </Box>
          </Box>
          <Box>
            <Text
              fontSize="clamp(32px, calc(3cqw - 0.11vw), 40px)"
              fontWeight="700"
              color={color}
            >
              {profit}
            </Text>
            <Text fontSize="22px" fontWeight="700">
              {profitPercentage}
            </Text>
            {showPL && (
              <Text
                fontSize="15px"
                lineHeight={"18px"}
                fontWeight="600"
                color={"#6F7D8C"}
              >
                Perfect P&L: {perfectPL}
              </Text>
            )}
          </Box>
        </CircularProgressLabel>
      </CircularProgress>
    </Card>
  );
};

export default ProfitCard;

const StatusModal = ({ isOpen, toggle, color, data }) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  return (
    <Modal isOpen={isOpen} onClose={toggle} isCentered>
      <ModalOverlay />
      <ModalContent
        height={!isMobile ? "auto" : "95%"}
        fontFamily={"lato"}
        maxW={1000}
        width={"95%"}
        padding={"20px 0"}
        borderRadius="12px"
      >
        <ModalHeader
          textAlign={"center"}
          fontSize="24px"
          fontWeight="bold"
          paddingTop={"8px"}
          pb={"2px"}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text>Business Status</Text>
            <Box
              border="1px"
              borderColor={"#536DEC"}
              width="95px"
              marginTop="4px"
            />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flex={"2 2"}
          flexWrap={"wrap"}
          justifyContent="center"
          overflowY="auto"
          padding={0}
          height={"100%"}
          overflowX={"hidden"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "9px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#30384A",
              borderRadius: "7px",
            },
            "&::-webkit-scrollbar-track": {
              background: "gray.100",
              borderRadius: "4px",
            },
          }}
          mr={isMobile ? 4 : 0}
          ml={isMobile ? 7 : 0}
        >
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
            alignItems="center"
            justifyItems="center"
          >
            <Box textAlign={"center"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Card
                boxShadow={"xl"}
                margin={6}
                marginBottom={3}
                width={color === "#68BA65" ? "210px" : "200px"}
                height={color === "#68BA65" ? "210px" : "200px"}
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={color === "#68BA65" ? "100%" : "70%"}
                borderWidth={color === "#68BA65" ? "3px" : "0px"}
                borderColor={color === "#68BA65" ? "#536DEC" : "black"}
              >
                <CircularProgress
                  value={100}
                  size={color === "#68BA65" ? "220px" : "210px"}
                  thickness="6px"
                  color="#68BA65"
                >
                  <CircularProgressLabel>
                    <Box textAlign="center">
                      <Text
                        fontSize="17px"
                        fontWeight="bold"
                        marginBottom={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Positive
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontSize="35px"
                        fontWeight="bold"
                        marginBottom={1}
                        color="#68BA65"
                      >
                        {customCurrencyFormat(data.positive_value)}
                      </Text>
                      <Text fontSize="14px" fontWeight="bold" marginBottom={1}>
                        {customCurrencyFormat(data.positive_percent, true)}+
                      </Text>
                    </Box>
                  </CircularProgressLabel>
                </CircularProgress>
              </Card>
              {color === "#68BA65" ? (
                <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>
                  Your Current Status
                </Text>
              ) : (
                <></>
              )}
            </Box>

            <Box textAlign={"center"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Card
                boxShadow={"xl"}
                margin={6}
                marginBottom={3}
                width={color === "#458CFF" ? "210px" : "200px"}
                height={color === "#458CFF" ? "210px" : "200px"}
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={color === "#458CFF" ? "100%" : "70%"}
                borderWidth={color === "#458CFF" ? "3px" : "0px"}
                borderColor={color === "#458CFF" ? "#536DEC" : "black"}
              >
                <CircularProgress
                  value={100}
                  size={color === "#458CFF" ? "220px" : "210px"}
                  thickness="6px"
                  color="#458CFF"
                >
                  <CircularProgressLabel>
                    <Box textAlign="center">
                      <Text
                        fontSize="17px"
                        fontWeight="bold"
                        marginBottom={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Neutral
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontSize="35px"
                        fontWeight="bold"
                        marginBottom={1}
                        color="#458CFF"
                      >
                        {customCurrencyFormat(data.neutral_value)}
                      </Text>
                      <Text fontSize="14px" fontWeight="bold" marginBottom={1}>
                        {customCurrencyFormat(data.neutral_percent, true)}+
                      </Text>
                    </Box>
                  </CircularProgressLabel>
                </CircularProgress>
              </Card>
              {color === "#458CFF" ? (
                <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>
                  Your Current Status
                </Text>
              ) : (
                <></>
              )}
            </Box>

            <Box textAlign={"center"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Card
                boxShadow={"xl"}
                margin={6}
                marginBottom={3}
                width={color === "#F1B20E" ? "210px" : "200px"}
                height={color === "#F1B20E" ? "210px" : "200px"}
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={color === "#F1B20E" ? "100%" : "70%"}
                borderWidth={color === "#F1B20E" ? "3px" : "0px"}
                borderColor={color === "#F1B20E" ? "#536DEC" : "black"}
              >
                <CircularProgress
                  value={100}
                  size={color === "#F1B20E" ? "220px" : "210px"}
                  thickness="6px"
                  color="#F1B20E"
                >
                  <CircularProgressLabel>
                    <Box textAlign="center">
                      <Text
                        fontSize="17px"
                        fontWeight="bold"
                        marginBottom={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Warning
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontSize="35px"
                        fontWeight="bold"
                        marginBottom={1}
                        color="#F1B20E"
                      >
                        {customCurrencyFormat(data.warning_value)}
                      </Text>
                      <Text fontSize="14px" fontWeight="bold" marginBottom={1}>
                        {customCurrencyFormat(data.warning_percent, true)}+
                      </Text>
                    </Box>
                  </CircularProgressLabel>
                </CircularProgress>
              </Card>
              {color === "#F1B20E" ? (
                <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>
                  Your Current Status
                </Text>
              ) : (
                <></>
              )}
            </Box>

            <Box textAlign={"center"} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Card
                boxShadow={"xl"}
                margin={6}
                marginBottom={3}
                width={color === "#FF5E5E" ? "210px" : "200px"}
                height={color === "#FF5E5E" ? "210px" : "200px"}
                borderRadius="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={color === "#FF5E5E" ? "100%" : "70%"}
                borderWidth={color === "#FF5E5E" ? "3px" : "0px"}
                borderColor={color === "#FF5E5E" ? "#536DEC" : "black"}
              >
                <CircularProgress
                  value={100}
                  size={color === "#FF5E5E" ? "220px" : "210px"}
                  thickness="6px"
                  color="#FF5E5E"
                >
                  <CircularProgressLabel>
                    <Box textAlign="center">
                      <Text
                        fontSize="17px"
                        fontWeight="bold"
                        marginBottom={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        Caution
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontSize="35px"
                        fontWeight="bold"
                        marginBottom={1}
                        color="#FF5E5E"
                      >
                        {customCurrencyFormat(data.caution_value)}
                      </Text>
                      <Text fontSize="14px" fontWeight="bold" marginBottom={1}>
                        {customCurrencyFormat(data.caution_percent, true)}-
                      </Text>
                    </Box>
                  </CircularProgressLabel>
                </CircularProgress>
              </Card>
              {color === "#FF5E5E" ? (
                <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>
                  Your Current Status
                </Text>
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
