import { deleteRequest, get, patch, post } from "./api";

const fetchVendors = (params = {}, dropdown_format = false) => {
  return get("/api/v2/vendors/", params, dropdown_format);
};

const updateVendor = (id, data) => {
  return patch(`/api/v2/vendors/${id}/`, data, "multipart/form-data");
};

const createVendor = (data) => {
  data.is_active = true;
  return post("/api/v2/vendors/", data, "multipart/form-data");
};

const deleteVendor = (id) => {
  return deleteRequest(`/api/v2/vendors/${id}/`);
};

const mergeVendors = (data) => {
  return post("/api/v2/merge_vendors/", data);
};

export { fetchVendors, updateVendor, createVendor, deleteVendor, mergeVendors };
