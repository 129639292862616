import { useMemo, useRef, useCallback, useEffect, useState } from "react";
import {
  VStack,
  Stack,
  HStack,
  Flex,
  Image,
  Text,
  Box,
  Collapse,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "@chakra-ui/icons";

const ClientSelector = ({
  customers,
  customerInfo,
  setCustomerInfo,
  setVisible,
  visible,
  isClientSelectOpen,
  setClientSelectOpen,
}) => {
  const clientSelectRef = useRef(null);
  const searchRef = useRef(null);
  const scrollRef = useRef(null);
  const [filteredCustomers, setFilteredCustomers] = useState(customers);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!clientSelectRef.current.contains(event.target)) {
        setClientSelectOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [setClientSelectOpen]);

  const customerName = useMemo(() => customerInfo.name, [customerInfo.name]);
  const customerLogo = useMemo(() => customerInfo.logo, [customerInfo.logo]);

  const handleSearch = useCallback(() => {
    const query = searchRef.current.value.toLowerCase();
    setFilteredCustomers(
      customers.filter((customer) =>
        customer.name.toLowerCase().includes(query)
      )
    );
  }, [customers]);

  const handleClientSelectClick = useCallback(() => {
    setVisible((prev) => prev || true);
    setClientSelectOpen((prev) => !prev);
  }, [setClientSelectOpen, setVisible]);

  return (
    <VStack
      width="100%"
      align="stretch"
      spacing="2"
      overflow="visible"
      maxHeight="40%"
      mb={2}
      position="relative"
    >
      <Stack ref={clientSelectRef}>
        <HStack
          pt={2}
          width="100%"
          onClick={handleClientSelectClick}
          cursor="pointer"
        >
          <Flex width="100%" align="center">

            <Flex justify="center" align="center" w="57px" h="57px" minW="57px">
              <Image
                src={
                  customerLogo ||
                  `https://ui-avatars.com/api/?size=24&name=${customerName}`
                }
                alt={customerName || "Placeholder"}
                boxSize="44px"
                objectFit="cover"
                borderRadius="full"
              />
            </Flex>

            {visible && (
              <HStack
                fontSize="16px"
                width="100%"
                justify="space-between"
                align="center"
              >
                <Text textAlign="left">{customerName}</Text>
                {isClientSelectOpen ? (
                  <ChevronUpIcon fontSize="16px" mr={2} />
                ) : (
                  <ChevronDownIcon fontSize="16px" mr={2} />
                )}
              </HStack>
            )}
          </Flex>
        </HStack>

        {visible && (
          <Box
            position="absolute"
            top="100%"
            left="0"
            zIndex="1000"
            width="100%"
            marginTop={2}
            boxShadow="lg"
            borderRadius="10px"
          >
            <Collapse in={isClientSelectOpen && visible} animateOpacity>
              <Box
                bg="white"
                p="12px"
                zIndex="1000"
                height="auto"
                maxH="260px"
                borderRadius="10px"
              >
                <InputGroup size="md">
                  <Input
                    ref={searchRef}
                    placeholder="Search..."
                    borderRadius="5px"
                  />
                  <InputRightElement width="20%">
                    <Button
                      h="100%"
                      w="100%"
                      bg="#30384A"
                      color="white"
                      borderRadius="0 5px 5px 0"
                      _hover={{ bg: "gray.800" }}
                      onClick={handleSearch}
                    >
                      <SearchIcon boxSize="100%" />
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Box
                  ref={scrollRef}
                  mt="12px"
                  mb={1}
                  height="calc(100% - 56px)"
                  maxH="185px"
                  overflowY="auto"
                  overflowX="hidden"
                  sx={{
                    paddingRight: filteredCustomers.length > 4 ? "10px" : "0px",
                    "&::-webkit-scrollbar": { width: "8px", height: "8px" },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#30384A",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "gray.100",
                      borderRadius: "4px",
                    },
                  }}
                >
                  {filteredCustomers.length > 0 ? (
                    filteredCustomers.map((customer) => (
                      <HStack
                        key={customer.id}
                        p="10px 12px"
                        gap={1}
                        mb="2px"
                        onClick={() => setCustomerInfo(customer)}
                        _hover={{
                          bg: "#536DEC",
                          color: "white",
                          cursor: "pointer",
                          borderRadius: "4px",
                        }}
                        sx={
                          customerInfo?.id === customer?.id
                            ? {
                                color: "white",
                                backgroundColor: "#536DEC",
                                borderRadius: "4px",
                              }
                            : {}
                        }
                      >
                        <Image
                          src={
                            customer.logo ||
                            `https://ui-avatars.com/api/?size=24&name=${customer.name}`
                          }
                          alt={customer.name || "Placeholder"}
                          boxSize="24px"
                          objectFit="cover"
                          borderRadius="full"
                          mr={visible ? 2 : 0}
                        />
                        <Text
                          width="100%"
                          whiteSpace="nowrap"
                          overflow="hidden"
                        >
                          {customer.name}
                        </Text>
                      </HStack>
                    ))
                  ) : (
                    <Text pt={2} pb={2} pl={2} color="gray.500">
                      No customers found.
                    </Text>
                  )}
                </Box>
              </Box>
            </Collapse>
          </Box>
        )}
      </Stack>
    </VStack>
  );
};

export default ClientSelector;
