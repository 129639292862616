import {
  Box,
  Card,
  CardBody,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { getBackgroundColor } from "../../utils";

const MetricCard = ({
  title = "",
  amount = "0.00",
  forecast = "0.00",
  forecast_percentage = "0.00",
  ytd = "0.00",
  ytd_percentage = "0.00",
  forecast_color = "black",
  ytd_color = "black",
  image = null,
  breakdownTitle,
  type,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 375px)");
  return (
    <Stack width="100%" align="center" p="10px 0px" fontFamily={"lato"}>
      <Card
        width="100%"
        height={"187px"}
        borderRadius="10px"
        position="relative"
        onClick={() => {
          type && setBreakdownData({ title: breakdownTitle, type: type });
          type && triggerExpenseBreakdown(true);
        }}
        _hover={type && { boxShadow: "xl", cursor: "pointer" }}
      >
        <CardBody padding={"16px 24px"}>
          <Box align={"left"}>
            <Text fontSize={"16px"} margin={0} color={"#202224"}>
              {title}
            </Text>
            <Text fontSize={"32px"} fontWeight={800} margin={0}>
              {amount}
            </Text>
          </Box>

          <SimpleGrid templateColumns={"1fr 1fr"} align="left" p={"5px 0px"}>
            <Box p={0} borderRightWidth={"2px"} width={"85%"} height={"80px"}>
              <Text
                fontSize={"14px"}
                margin={0}
                gridColumn={1}
                gridRow={1}
                color={"#202224"}
              >
                Forecast
              </Text>
              <Text
                fontSize={"clamp(14px, 4vh, 22px)"}
                margin={0}
                fontWeight={600}
                gridColumn={1}
                gridRow={2}
              >
                {forecast}
              </Text>
              <Text
                fontSize={"16px"}
                margin={0}
                gridColumn={1}
                gridRow={3}
                color={forecast_color}
              >
                {forecast_percentage}
              </Text>
            </Box>
            <Box>
              <Text
                fontSize={"14px"}
                margin={0}
                gridColumn={2}
                gridRow={1}
                color={"#202224"}
              >
                YTD Average
              </Text>
              <Text
                fontSize={"clamp(14px, 4vh, 22px)"}
                margin={0}
                fontWeight={600}
                gridColumn={2}
                gridRow={2}
              >
                {ytd}
              </Text>
              <Text
                fontSize={"16px"}
                margin={0}
                gridColumn={2}
                gridRow={3}
                color={ytd_color}
              >
                {ytd_percentage}
              </Text>
            </Box>
          </SimpleGrid>
        </CardBody>

        <Box
          position="absolute"
          top="20px"
          right="20px"
          backgroundColor={ !isMobile && getBackgroundColor(image)}
          borderRadius="15px"
          width="75px"
          height="75px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!isMobile && (
            <Image
              src={image}
              alt="Top Right Icon"
              boxSize="44px"
              objectFit="contain"
            />
          )}
        </Box>
      </Card>
    </Stack>
  );
};

export default MetricCard;
