import {
  Card,
  Grid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MetricView from "./metricView";
import DetailView from "./detailView";
import CalendarComponent from "./calendarComponent";

const PerformanceReview = ({
  customer_id,
  dashDate,
  trigger,
  triggerSetter,
  updateDate,
  triggerExpenseBreakdown,
  setBreakdownData,
  setIsInsights,
  setExpenseBreakdownReturn,
  toggleView="metric",
  setNavIndex
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)", {ssr: false});
  const [tab, setTab] = useState(toggleView ? toggleView : "metric");

  useEffect(() => {
    setIsInsights(false);
    setExpenseBreakdownReturn("performanceReview");
  }, [setIsInsights, setExpenseBreakdownReturn]);

  return (
    <Stack>
      <Card
        borderRadius={0}
        h="48px"
        borderBottom="1px solid #e0e0e0"
        mt={isMobile ? "122px" : "72px"}
        zIndex={300}
        position={"fixed"}
      >
        <Tabs index={tab === "metric" ? 0 : 1}>
          <Grid
            templateColumns="1fr 2fr 9fr"
            width="100%"
            alignItems="center" /* Vertically center all children */
            p={!isMobile ? "0px 20px 0px" : "0px 8px 0px"}
            height="50px" /* Ensure Grid has a defined height */
          >
            <TabList display="flex" alignItems="center" height="50px">
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                  fontWeight: 700
                }}
                cursor="pointer"
                onClick={() => {setTab("metric"); setNavIndex ? setNavIndex(1) : setNavIndex = null}}
                _active={{ background: "none"}}
                width={isMobile ? "80px" : "90px"}
                h="100%" /* Matches parent height */
                p={0}
                display="flex"
                alignItems="center" /* Vertically center text */
                justifyContent="center" /* Horizontally center text */
              >
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  margin="0"
                >
                  Metric View
                </Text>
              </Tab>
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                  fontWeight: 700
                }}
                cursor="pointer"
                onClick={() => {setTab("detail"); setNavIndex ? setNavIndex(2) : setNavIndex = null}}
                _active={{ background: "none"}}
                width={isMobile ? "85px" : "105px"}
                h="100%" /* Matches parent height */
                p={0}
                display="flex"
                alignItems="center" /* Vertically center text */
                justifyContent="center" /* Horizontally center text */
                whiteSpace="nowrap"
              >
                <Text
                  fontSize={isMobile ? "14px" : "16px"}
                  margin="0"
                >
                  Detail View
                </Text>
              </Tab>
            </TabList>

            <Stack
              gridColumn={9}
              textAlign="right"
              justifyContent="center" /* Vertically center content */
            >
              {!isMobile && (
                <CalendarComponent
                  height="34px"
                  callbackFunctionDate={updateDate}
                  triggerSetter={triggerSetter}
                  date={dashDate}
                />
              )}
            </Stack>
          </Grid>
        </Tabs>
      </Card>
      {tab === "metric" && (
        <MetricView
          customer_id={customer_id}
          dashDate={dashDate}
          trigger={trigger}
          triggerSetter={triggerSetter}
          triggerExpenseBreakdown={triggerExpenseBreakdown}
          setBreakdownData={setBreakdownData}
        />
      )}
      {tab === "detail" && (
        <DetailView
          customer_id={customer_id}
          dashDate={dashDate}
          trigger={trigger}
          triggerSetter={triggerSetter}
        />
      )}
    </Stack>
  );
};

export default PerformanceReview;
