import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import CustomTableContainer from "../../theme/components/tableContainer";
import { LoadingTable, renderAmount } from "../../utils";
import { Context } from "../../ContextWrapper";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { DropDown } from "../generic/dropDown";
import { fetchCustomerAccounts } from "../../api/accounts";
import { VendorSelect } from "../account/vendorDropDown";

export const ClientGeneralLedger = ({ customer_id }) => {
  const [loading, setLoading] = useState(true);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountList, setAccountList] = useState([]);
  const [journalEntryList, setJournalEntryList] = useState([]);
  const context = React.useContext(Context);
  const [allSelected, setAllSelected] = useState(false);
  const {
    isOpen: isReclassifyOpen,
    onOpen: onReclassifyOpen,
    onClose: onReclassifyClose,
  } = useDisclosure();
  const {
    isOpen: isUpdateVendorOpen,
    onOpen: onUpdateVendorOpen,
    onClose: onUpdateVendorClose,
  } = useDisclosure();
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);

  useEffect(() => {
    const updateAccount = (account_id, balance) => {
      setAccountList((accountList) => {
        return accountList.map((account) => {
          if (account.id === account_id) {
            console.log(`updating ${account.number} to ${balance}`);
            return {
              ...account,
              current_balance: balance,
            };
          } else {
            return account;
          }
        });
      });
    };

    var host = window.location.host;
    if (host.indexOf(":3000") === -1) {
      host = "wss://" + host;
    } else {
      host = "ws://" + host.replace(":3000", ":8000");
    }
    console.log(`${host}/ws/accountbalance/`);
    const wsClient = new W3CWebSocket(
      `${host}/ws/${customer_id}/accountbalance/`
    );
    wsClient.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    wsClient.onmessage = (message) => {
      console.log(">>> web socket message");
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer) {
        console.log(dataFromServer);
        const { account_id, balance } = dataFromServer.text;
        console.log(account_id);
        console.log(balance);

        updateAccount(account_id, balance);
      }
    };
  }, [customer_id]);

  useEffect(() => {
    const year = selectedYear ? selectedYear : "2024";
    const getData = async () => {
      var accountResults = fetchCustomerAccounts(customer_id, {
        fiscal_year: year,
      });
      setAccountList(await accountResults);
    };
    getData();

    setLoading(false);
  }, [customer_id, selectedYear]);

  useEffect(() => {
    const refreshFiscalYears = (customer_id) => {
      axios
        .get(
          `/api/v2/customers/${customer_id}/fiscalyears/`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setFiscalYears(
            res.data.fiscal_years.map((year) => ({ value: year, label: year }))
          );
          if (res.data.fiscal_years) {
            setSelectedYear(res.data.fiscal_years[0]);
          }
        })
        .catch((err) => console.log(err));
    };
    refreshFiscalYears(customer_id);
    setLoading(false);
  }, [customer_id]);

  const selectedEntries = journalEntryList.filter((entry) => entry.selected);
  const accounts = accountList
    .filter((a) => a.id !== selectedAccount?.id)
    .map((a) => ({ value: a.id, label: a.number + " - " + a.name }));

  const refreshJournalEntryList = useCallback(
    (customer_id, cursor = null) => {
      const startDate = selectedYear + "-1-1";
      const endDate = selectedYear + "-12-31";

      let params = {
        account: selectedAccount.id,
        date_after: startDate,
        date_before: endDate,
      };

      if (cursor) {
        const urlObj = new URL(cursor);
        params.cursor = urlObj.searchParams.get("cursor");
      }

      axios
        .get(`/api/v2/customers/${customer_id}/journalentries/`, {
          headers: { "Content-Type": "application/json" },
          params: params,
          withCredentials: true,
        })
        .then((res) => {
          setJournalEntryList(res.data.results);
          setLoadingEntries(false);

          setNextCursor(res.data.next);
          setPreviousCursor(res.data.previous);
        })
        .catch((err) => console.log(err));
    },
    [selectedYear, selectedAccount]
  );

  useEffect(() => {
    if (selectedAccount) {
      setJournalEntryList([]);
      setAllSelected(false);
      setLoadingEntries(true);

      refreshJournalEntryList(customer_id);
    }
  }, [selectedAccount, customer_id, selectedYear, refreshJournalEntryList]);

  const exportGeneralLedger = () => {
    context.fireToast("Error", "Not implemented yet", "error");
  };

  const selectAllEntries = (e) => {
    setAllSelected(e.target.checked);
    setJournalEntryList(
      journalEntryList.map((entry) => {
        entry.selected = e.target.checked;
        return entry;
      })
    );
  };

  const selectEntry = (entry, e) => {
    entry.selected = e.target.checked;
    setJournalEntryList(
      journalEntryList.map((o) => (o.id === entry.id ? entry : o))
    );
  };

  const selectYear = (year) => {
    setSelectedYear(year.value);
  };

  const startReclassify = () => {
    onReclassifyOpen();
  };

  const startUpdateVendor = () => {
    onUpdateVendorOpen();
  };

  const handleReclassify = (account_id) => {
    const journalEntries = selectedEntries.map((entry) => {
      entry.account = account_id;
      return entry;
    });

    axios
      .patch(
        `/api/v2/customers/${customer_id}/bulkjournalentries/`,
        journalEntries
      )
      .then(() => {
        onReclassifyClose();
        setAllSelected(false);
        setJournalEntryList(
          journalEntryList.filter((entry) => !entry.selected)
        );
        context.fireToast("Success", "Transaction(s) reclassified.", "success");
      })
      .catch((err) => console.log(err));
  };

  const handleUpdateVendor = (vendor) => {
    const journalEntries = selectedEntries.map((entry) => {
      entry.vendor_id = vendor.value;
      entry.description = vendor.label;
      return entry;
    });

    axios
      .patch(
        `/api/v2/customers/${customer_id}/bulkjournalentries/`,
        journalEntries
      )
      .then((res) => {
        onUpdateVendorClose();
        setAllSelected(false);
        setJournalEntryList(
          journalEntryList.map((entry) => {
            entry = res.data[entry.id] || entry;
            entry.selected = false;
            return entry;
          })
        );
        context.fireToast("Success", "Transaction(s) reclassified.", "success");
      })
      .catch((err) => console.log(err));
  };

  const handleNextPage = () => {
    if (nextCursor) {
      refreshJournalEntryList(customer_id, nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshJournalEntryList(customer_id, previousCursor);
    }
  };

  const year = selectedYear ? selectedYear : 2024;
  const openingDate = new Date(year - 1 + "-12-31").toISOString().split("T")[0];
  const closingDate = new Date(year + "-12-31").toISOString().split("T")[0];
  const openingBalance = accountList.find(
    (a) => a.id === selectedAccount?.id
  )?.opening_balance;
  const closingBalance = accountList.find(
    (a) => a.id === selectedAccount?.id
  )?.current_balance;

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="100%">
          <ReclassifyModal
            isOpen={isReclassifyOpen}
            loading={loading}
            onClose={onReclassifyClose}
            accounts={accounts}
            handleReclassify={handleReclassify}
          />
          <UpdateVendorModal
            isOpen={isUpdateVendorOpen}
            onClose={onUpdateVendorClose}
            loading={loading}
            handleUpdate={handleUpdateVendor}
          />
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                General Ledger
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                {selectedEntries.length > 0 && (
                  <>
                    <Button onClick={startReclassify}>Reclassify</Button>
                    <Button onClick={startUpdateVendor}>Update Vendor</Button>
                  </>
                )}
                <Button onClick={exportGeneralLedger}>
                  Export General Ledger
                </Button>
                <DropDown
                  name="year"
                  options={fiscalYears}
                  selectedValue={selectedYear}
                  onChange={selectYear}
                  w="120px"
                  menuPortalTarget={document.body}
                />
              </Stack>
            </Box>
          </Flex>
          <HStack>
            <Box
              maxHeight="calc(100vh - 350px)"
              w="535px"
              bg="white"
              overflowX="hidden"
            >
              {loading && <LoadingTable />}
              {accountList && (
                <Table variant="unstyled" size="sm">
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Td>Account</Td>
                      <Td>Balance</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {accountList
                      .filter((a) => a.is_main_account || !a.parent)
                      .map((account) => (
                        <AccountRow
                          key={account.id}
                          account={account}
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          accountList={accountList}
                          space={0}
                        />
                      ))}
                  </Tbody>
                </Table>
              )}
            </Box>
            <Box
              h="calc(100vh - 350px)"
              w="calc(100% - 544px)"
              bg="white"
              overflowX="auto"
            >
              {loadingEntries && <LoadingTable />}
              {selectedAccount && !loadingEntries && journalEntryList && (
                <>
                  <Table variant="unstyled" size="sm">
                    <Thead position="sticky" top={-1} zIndex={1}>
                      <Tr>
                        <Td w="40px">
                          <Checkbox
                            isChecked={allSelected}
                            onChange={selectAllEntries}
                          />
                        </Td>
                        <Td>Date</Td>
                        <Td>Description</Td>
                        <Td>Vendor</Td>
                        <Td>Memo</Td>
                        <Td>Debit</Td>
                        <Td>Credit</Td>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr fontWeight={"bold"}>
                        <Td></Td>
                        <Td>{openingDate}</Td>
                        <Td>Opening Balance</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          {selectedAccount.account_type === "debit" &&
                            renderAmount(openingBalance)}
                        </Td>
                        <Td>
                          {selectedAccount.account_type === "credit" &&
                            renderAmount(openingBalance)}
                        </Td>
                      </Tr>
                      {journalEntryList.map((entry) => (
                        <Tr key={entry.id}>
                          <Td>
                            <Checkbox
                              isChecked={entry.selected}
                              onChange={(e) => selectEntry(entry, e)}
                            />
                          </Td>
                          <Td>{entry.date}</Td>
                          <Td>{entry.description}</Td>
                          <Td>{entry.vendor_name}</Td>
                          <Td maxW={"200px"}>
                            <Tooltip label={entry.memo} fontSize="md">
                              <Text isTruncated m={0}>
                                {entry.memo}
                              </Text>
                            </Tooltip>
                          </Td>
                          <Td>
                            {entry.entry_type === "debit" &&
                              renderAmount(entry.amount)}
                          </Td>
                          <Td>
                            {entry.entry_type === "credit" &&
                              renderAmount(entry.amount)}
                          </Td>
                        </Tr>
                      ))}
                      <Tr fontWeight={"bold"}>
                        <Td></Td>
                        <Td>{closingDate}</Td>
                        <Td>Closing Balance</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                          {selectedAccount.account_type === "debit" &&
                            renderAmount(closingBalance)}
                        </Td>
                        <Td>
                          {selectedAccount.account_type === "credit" &&
                            renderAmount(closingBalance)}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  <Stack
                    direction="row"
                    spacing={4}
                    mt={4}
                    justifyContent="center"
                  >
                    {previousCursor && (
                      <Button onClick={handlePreviousPage}>Previous</Button>
                    )}
                    {nextCursor && (
                      <Button onClick={handleNextPage} disabled={!nextCursor}>
                        Next
                      </Button>
                    )}
                  </Stack>
                </>
              )}
              {/* {selectedAccount && journalEntryList.length ===0 && <Text>No journal entries for account</Text>} */}
            </Box>
          </HStack>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const AccountRow = ({
  account,
  selectedAccount,
  setSelectedAccount,
  accountList,
  space,
}) => {
  const next_space = space + 15;
  return (
    <>
      <Tr
        background={account.id === selectedAccount?.id ? "brand.200" : null}
        onClick={() => setSelectedAccount(account)}
      >
        <Td>
          <Box pl={space} w="200px" overflow={"ellipsis"}>
            <Text isTruncated m={0}>
              {account.number} - {account.name}
            </Text>
          </Box>
        </Td>
        <Td>{renderAmount(account.current_balance)}</Td>
      </Tr>
      {accountList
        .filter((a) => a.parent === account.id)
        .map((inner_account) => (
          <AccountRow
            key={inner_account.id}
            account={inner_account}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            accountList={accountList}
            space={next_space}
          />
        ))}
    </>
  );
};

const ReclassifyModal = ({
  isOpen,
  onClose,
  loading,
  accounts,
  handleReclassify,
}) => {
  const [account, setAccount] = useState(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reclassify entries</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Move to account</FormLabel>
            <DropDown name="account" options={accounts} onChange={setAccount} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => handleReclassify(account.value)}
            isDisabled={loading}
          >
            {loading ? "Processing..." : "Reclassify"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const UpdateVendorModal = ({ isOpen, onClose, loading, handleUpdate }) => {
  const [vendor, setVendor] = useState(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Vendor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Vendor</FormLabel>
            <VendorSelect onChange={setVendor} w={400} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => handleUpdate(vendor)} isDisabled={loading}>
            {loading ? "Updating..." : "Update"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
