import { deleteRequest, get, patch, post } from "./api";

const fetchTags = (customer_id) => {
  return get(
    `/api/v2/customers/${customer_id}/resourcetags/`,
  );
};

const updateTag = (customer_id, tagID, data) => {
  return patch(
    `/api/v2/customers/${customer_id}/resourcetags/${tagID}/`,
    data,
    "multipart/form-data"
  );
};

const createTag = (customer_id, data) => {
  return post(
    `/api/v2/customers/${customer_id}/resourcetags/`,
    data,
    "multipart/form-data"
  );
};

const deleteTag = (customer_id, tagID) => {
  return deleteRequest(
    `/api/v2/customers/${customer_id}/resourcetags/${tagID}/`
  );
};

export { fetchTags, updateTag, createTag, deleteTag };
