import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useCurrentProfile } from "../../ContextWrapper";
import { fetchCustomers } from "../../api/customers";

export const ClientDropDown = ({ customer }) => {
  const [customerList, setCustomerList] = useState([]);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const { profile } = useCurrentProfile();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    let params = {};
    if (searchInput) {
      params.search = searchInput;
    }
    const fetchData = async () => {
      var results = await fetchCustomers(profile, params, true);

      if (customer.id) {
        if (!results.find((c) => c.value === customer.id)) {
          results = results.concat({
            value: customer.id,
            label: customer.name,
          });
        }
        setSelected(results.find((c) => c.value === customer.id) || "test");
      }
      setCustomerList(results);
    };
    fetchData();
  }, [customer, profile, searchInput]);

  const onInputChange = (inputValue) => {
    let result = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setSearchInput(result);
  };

  return (
    <Box mt={5} w="275px" ml="5">
      {selected && selected.name}
      <Select
        menuPortalTarget={document.body}
        name="client"
        options={customerList}
        value={selected}
        placeholder="Select client"
        onChange={(c) => navigate(`/client/${c.value}`)}
        onInputChange={onInputChange}
        useBasicStyles
        variant="unstyled"
        m="10"
      />
    </Box>
  );
};
