import {
  Box,
  Tooltip as ChakraTooltip,
  HStack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { customCurrencyFormat } from "../../utils";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

export const ExpenseGraph = ({ metricGraphData, selectedPeriod, dashDate }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipData = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const verticalLinePlugin = {
      id: "verticalLinePlugin",
      beforeDraw: (chart) => {
        if (chart.tooltip?._active?.length) {
          const activePoint = chart.tooltip._active[0];
          const x = activePoint.element.x;
          const yAxis = chart.scales.y;
          const ctx = chart.ctx;

          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([5, 5]);
          ctx.moveTo(x, yAxis.top);
          ctx.lineTo(x, yAxis.bottom);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "black";
          ctx.stroke();
          ctx.restore();
        }
      },
    };

    Chart.register(verticalLinePlugin);

    const canvas = document.getElementById("expenseTrendChart");
    const ctx = canvas.getContext("2d");

    const createTooltip = (tooltipModel) => {
      if (!tooltipModel.opacity) {
        setIsTooltipVisible(false);
        return;
      }

      const dataPoints = tooltipModel.dataPoints;
      if (!dataPoints || dataPoints.length === 0) {
        setIsTooltipVisible(false);
        return;
      }

      const point = dataPoints[0];
      const { datasetIndex, dataIndex } = point;
      const chart = tooltipModel.chart;
      const datasetMeta = chart.getDatasetMeta(datasetIndex);

      if (datasetMeta.type !== "line") {
        setIsTooltipVisible(false);
        return;
      }

      const position = point.element;

      const canvasRect = chart.canvas.getBoundingClientRect();
      const parentRect = chart.canvas.parentNode.getBoundingClientRect();
      const tooltipX = position.x + canvasRect.left - parentRect.left;
      const tooltipY = position.y + canvasRect.top - parentRect.top;

      setTooltipPosition({ x: tooltipX, y: tooltipY });

      const datasets = chart.data.datasets;

      const label = chart.data.labels[dataIndex];
      const formattedLabel =
        selectedPeriod === "year"
          ? label
          : `${label}/${(dashDate.getMonth() + 1).toString().padStart(2, "0")}`;

      tooltipData.current = {
        label: formattedLabel,
        value: datasets[datasetIndex]?.data[dataIndex] || 0,
      };

      setIsTooltipVisible(true);
    };

    const data = {
      labels: metricGraphData?.date_interval || Array(12).fill("-"),
      datasets: [
        {
          type: "line",
          label: "Expense Trend Line",
          data: metricGraphData?.values || Array(12).fill(0),
          borderColor: "#FF5A5C",
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) return "#FF5A5C";

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, "rgba(255, 90, 92, 0.7)");
            gradient.addColorStop(1, "rgba(255, 90, 92, 0.1)");
            return gradient;
          },
          fill: true,
          tension: 0.26,
          pointRadius: 0,
          hoverRadius: 5,
          pointBackgroundColor: "#FF5A5C",
          pointBorderWidth: 1,
          borderWidth: 3,
          pointHoverBorderColor: "white",
          pointHoverBorderWidth: 2,
          PointStyle: "circle",
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "nearest",
        intersect: false,
        axis: "x",
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              if (value === 0) {
                return "0";
              }
              if (Math.abs(value) >= 1000) {
                return (value / 1000).toFixed(0) + "K";
              }
              if (Math.abs(value) > 1000) {
                return value.toFixed(0);
              }
              return (
                (value < 0 ? "-$" : "$") +
                Math.abs(value).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              );
            },
          },
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
            drawTicks: true,
            tickLength: 10,
            color: "#CCCCCC",
            lineWidth: 1,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: (context) => createTooltip(context.tooltip),
        },
        legend: {
          display: false,
        },
      },
    };

    const chartInstance = new Chart(ctx, {
      type: "line",
      data,
      options,
    });

    return () => {
      Chart.unregister(verticalLinePlugin);
      chartInstance.destroy();
    };
  }, [metricGraphData, selectedPeriod, dashDate, isMobile]);

  return (
    <Box position="relative" width="100%" height="100%">
      <canvas
        id="expenseTrendChart"
        style={{ width: "100%", height: "100%" }}
      />
      {isTooltipVisible && tooltipData.current && (
        <Box
          position="absolute"
          top={`${tooltipPosition.y}px`}
          left={`${tooltipPosition.x}px`}
          transform="translate(-50%, -100%)"
          pointerEvents="none"
        >
          <ChakraTooltip
            ref={tooltipRef}
            backgroundColor="white"
            borderRadius="10px"
            boxShadow="lg"
            borderWidth={"1px"}
            borderColor={"grey.300"}
            textColor="black"
            isOpen
            hasArrow
            placement="right"
            label={
              <Box padding={1}>
                <HStack>
                  <Box
                    height={"14px"}
                    width={"14px"}
                    borderRadius={"full"}
                    backgroundColor={"#FF5A5C"}
                  ></Box>
                  <Text>{tooltipData.current.label}: </Text>
                  <Text fontWeight="bold">
                    {customCurrencyFormat(tooltipData.current.value)}
                  </Text>
                </HStack>
              </Box>
            }
            arrowSize={10}
            arrowShadowColor="rgba(0, 0, 0, 0.15)"
            onOpen={() => {
              const tooltipWidth = tooltipRef.current.offsetWidth;
              const canvasContainer = tooltipRef.current.parentElement;
              const canvasWidth = canvasContainer.clientWidth;

              if (tooltipPosition.current.x + tooltipWidth > canvasWidth) {
                tooltipRef.current.setAttribute("data-placement", "left");
                tooltipPosition.current.x -= tooltipWidth;
              } else {
                tooltipRef.current.setAttribute("data-placement", "right");
              }
            }}
          >
            <Box />
          </ChakraTooltip>
        </Box>
      )}
    </Box>
  );
};
