import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Container,
  Flex,
  Grid,
  HStack,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Menu } from "react-feather";
import { useCurrentProfile } from "../../ContextWrapper";
import {
  colorExpenseFormat,
  colorFormat,
  customCurrencyFormat,
} from "../../utils";
import NotificationContainer from "../generic/updateNotification";
import CalendarComponent from "./calendarComponent";
import ExpenseBreakdown from "./expenseBreakdown";
import ExpenseCard from "./expenseCard";
import GrowthPlanCard from "./growthPlanCard";
import IndustryInsightsSelection from "./industryInsightsSelection";
import PerformanceReview from "./performanceReview";
import ProfitCard from "./profitCard";
import RevenueDropdown from "./revenueDropdown";

const ResourceCard = ({ title, description, listing, column }) => {
  return (
    <Stack
      fontFamily={"lato"}
      width="100%"
      align="center"
      gridColumn={column}
      p="15px 10px"
      position={"relative"}
    >
      <Card
        width="100%"
        height="111px"
        borderRadius="12px"
        position="relative"
        role="group"
      >
        {/* <Image
          src="/resource-arrow.png"
          alt="Client Navigation Open"
          boxSize="35px"
          objectFit="contain"
          position="absolute"
          top="10px"
          right="15px"
          _groupHover={{
            filter: "brightness(1.2)",
          }}
        /> */}
        <CardBody textAlign="center" pb={0}>
          <Box p="10px 0 0 0">
            <Text fontSize="20px" lineHeight={"21px"} fontWeight={600} pb={1}>
              {title}
            </Text>
            <Text fontSize={"14px"}>{description}.</Text>
          </Box>
        </CardBody>
      </Card>

      <Card
        position="absolute"
        top="-15px"
        left="50%"
        transform="translateX(-50%)"
        width="50px"
        height="50px"
        borderRadius="100%"
        bg="#536DEC"
      >
        <CardBody p={"10px 0 0 0"}>
          <Text textAlign="center" fontSize={"20px"} color="white">
            {listing}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
};

const ResourceOverview = ({ resourceOverviewData }) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  const recommendedResources =
    resourceOverviewData?.recommended_resources?.filter(
      (resource) => resource !== undefined && resource !== null
    ) || [];

  const calculateColumns = () => {
    if (recommendedResources.length === 1) {
      return { base: "1fr", md: "1fr", lg: "1fr" };
    } else if (recommendedResources.length === 2) {
      return { base: "1fr", md: "1fr", lg: "1fr 1fr" };
    } else if (recommendedResources.length === 3) {
      return { base: "1fr", md: "1fr", lg: "1fr 1fr 1fr" };
    } else {
      return { base: "1fr" };
    }
  };

  const renderResourceCards = (resources) => {
    return resources.map((resource, index) => (
      <ResourceCard
        key={index}
        title={resource.name}
        description={resource.description}
        listing={index + 1}
      />
    ));
  };

  return (
    <Stack p={"10px 0 0 0"}>
      {recommendedResources.length > 0 && (
        <SimpleGrid
          templateColumns={calculateColumns()}
          spacing={2}
          p="5px"
          alignItems="center"
          justifyItems="center"
        >
          {renderResourceCards(recommendedResources)}
        </SimpleGrid>
      )}

      {resourceOverviewData?.performance_overview && (
        <Stack
          width="100%"
          align="center"
          p={!isMobile ? "0px 15px 10px 15px" : "0px 10px 10px"}
          fontFamily={"lato"}
        >
          <Card
            width="100%"
            minHeight="171px"
            maxH={"100%"}
            borderRadius="12px"
          >
            <CardBody textAlign={"center"}>
              <Box>
                <Text fontSize={"24px"} lineHeight={"10px"}>
                  Performance Overview
                </Text>
                <Stack
                  as="span"
                  display="inline-block"
                  borderTop="2px solid #536DEC"
                  width="150px"
                  textAlign="center"
                />
              </Box>
              <Text fontSize={"16px"}>
                {resourceOverviewData.performance_overview}
              </Text>
            </CardBody>
          </Card>
        </Stack>
      )}
    </Stack>
  );
};

const GrowthPlanContent = ({
  customer_id,
  date,
  trigger,
  triggerSetter,
  setNavIndex,
  setIsInsights,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const formattedDate = date.toISOString().split("T")[0];
  const [growthPlanData, setGrowthPlanData] = useState({
    income: "0.00",
    income_forecast: "0.00",
    income_forecast_percentage: "0.00",
    cash_on_hand: "0.00",
    last_cash_on_hand: "0.00",
    last_cash_on_hand_percentage: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    expense_forecast_percentage: "0.00",
    equity: "0.00",
    last_equity: "0.00",
    last_equity_percentage: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
    team_percentage: "0.00",
    marketing_percentage: "0.00",
    overhead_percentage: "0.00",
    cogs_percentage: "0.00",
    team: "0.00",
    marketing: "0.00",
    overhead: "0.00",
    cogs: "0.00",
  });

  const [resourceOverview, setResourceOverview] = useState({
    first_recommended_resource: null,
    second_recommended_resource: null,
    third_recommended_resource: null,
    performance_overview: null,
  });

  const [pLData, setPLData] = useState({
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });
  const [isMobile] = useMediaQuery("(max-width: 900px)", { ssr: false });
  const [mobileHeader] = useMediaQuery("(max-width: 1280px)", { ssr: false });

  useEffect(() => {
    setIsInsights(false);
  }, [setIsInsights]);

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshGrowthPlanData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/growthplan/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setGrowthPlanData(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshGrowthPlanData(customer_id);

    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshResourceOverview = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/resourceperformanceview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setResourceOverview(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshResourceOverview(customer_id);

    // Cleanup to handle stale state and abort ongoing requests
    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshPLData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/plvalues/${formattedDate}`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setPLData(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshPLData(customer_id);
    triggerSetter(false); // Reset the trigger state

    // Cleanup to handle stale state and abort ongoing requests
    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  return (
    <Stack
      mt={mobileHeader ? "130px" : "72px"}
      p={isMobile ? "10px 10px" : "0px"}
      fontFamily={"lato"}
    >
      <NotificationContainer rounded={true} p={"12px 15px 2px"} />
      {!isMobile && (
        <Grid
          templateColumns="2fr 248px 2fr"
          rowGap="0" // Removes vertical space between rows
          columnGap="10px" // Optional: Adjust spacing between columns
          p="10px 5px 0px 5px" // Reduce padding for the entire grid
          alignItems="center"
          justifyItems="center"
        >
          <Stack
            width="100%"
            align="center"
            gridColumn={1}
            gridRow={1}
            p="10px" // Reduce padding here
          >
            <GrowthPlanCard
              leftTitle="Total Income"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.income_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="108px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.income_forecast_percentage
                  : "0.00"
              )}
              image={"/money-up-arrow.png"}
              breakdownTitle="Total Income"
              type="income"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          </Stack>

          <Stack
            width="100%"
            align="center"
            gridColumn={1}
            gridRow={2}
            p="10px" // Reduce padding here
          >
            <GrowthPlanCard
              leftTitle="Cash on Hand"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.cash_on_hand : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_cash_on_hand : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.last_cash_on_hand_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="108px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.last_cash_on_hand_percentage
                  : "0.00"
              )}
              image={"/money-bag.png"}
            />
          </Stack>

          <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
            <ProfitCard
              profit={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit : 0.0
              )}
              profitPercentage={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.profit_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.profit_pl : "0.00",
                growthPlanData ? growthPlanData.profit_percentage : "0.00",
                true,
                true
              )}
              status={true}
              data={growthPlanData ? growthPlanData : {}}
            />
          </Stack>

          <Stack
            width="100%"
            align="center"
            gridColumn={3}
            gridRow={1}
            p="10px" // Reduce padding here
          >
            <GrowthPlanCard
              leftTitle="Total Expenses"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.expense_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="108px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.expense_forecast_percentage
                  : "0.00",
                true
              )}
              image={"/money-down-arrow.png"}
              breakdownTitle="Total Expense"
              type="expense"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          </Stack>

          <Stack
            width="100%"
            align="center"
            gridColumn={3}
            gridRow={2}
            p="10px" // Reduce padding here
          >
            <GrowthPlanCard
              leftTitle="Equity"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.equity : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_equity : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData ? growthPlanData.last_equity_percentage : 0.0
                }`,
                true
              )}
              cardHeight="108px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData ? growthPlanData.last_equity_percentage : 0.0
              )}
              image={"/bar-chart-with-arrow.png"}
            />
          </Stack>
        </Grid>
      )}
      {isMobile && (
        <VStack align="center" justify="center" w="100%" h="100%">
          <Stack width="100%" align="center" mb={"15px"}>
            <ProfitCard
              profit={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit : 0.0
              )}
              profitPercentage={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.profit_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.profit_pl : "0.00",
                growthPlanData ? growthPlanData.profit_percentage : "0.00",
                true,
                true
              )}
              status={true}
              data={growthPlanData ? growthPlanData : {}}
            />
          </Stack>
          <Grid
            w="100%"
            h="100%"
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
            }}
          >
            <Stack width="100%" align="center" p="10px 10px">
              <GrowthPlanCard
                leftTitle="Total Income"
                rightTitle="Forecast"
                leftAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.income : 0.0}00`
                )}
                rightAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.income_forecast : 0.0}`
                )}
                rightPercentage={customCurrencyFormat(
                  `${
                    growthPlanData
                      ? growthPlanData.income_forecast_percentage
                      : 0.0
                  }`,
                  true
                )}
                cardHeight="120px"
                cardWidth="100%"
                color={colorFormat(
                  growthPlanData
                    ? growthPlanData.income_forecast_percentage
                    : "0.00"
                )}
                image={"/money-up-arrow.png"}
                breakdownTitle="Total Income"
                type="income"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>
            <Stack width="100%" align="center" p="10px 10px">
              <GrowthPlanCard
                leftTitle="Total Expenses"
                rightTitle="Forecast"
                leftAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.expense : 0.0}`
                )}
                rightAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.expense_forecast : 0.0}`
                )}
                rightPercentage={customCurrencyFormat(
                  `${
                    growthPlanData
                      ? growthPlanData.expense_forecast_percentage
                      : 0.0
                  }`,
                  true
                )}
                cardHeight="120px"
                cardWidth="100%"
                color={colorFormat(
                  growthPlanData
                    ? growthPlanData.expense_forecast_percentage
                    : "0.00",
                  true
                )}
                image={"/money-down-arrow.png"}
                breakdownTitle="Total Expense"
                type="expense"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>
            <Stack width="100%" align="center" p="10px 10px">
              <GrowthPlanCard
                leftTitle="Cash on Hand"
                rightTitle="Last Month"
                leftAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.cash_on_hand : 0.0}`
                )}
                rightAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.last_cash_on_hand : 0.0}`
                )}
                rightPercentage={customCurrencyFormat(
                  `${
                    growthPlanData
                      ? growthPlanData.last_cash_on_hand_percentage
                      : 0.0
                  }`,
                  true
                )}
                cardHeight="120px"
                cardWidth="100%"
                color={colorFormat(
                  growthPlanData
                    ? growthPlanData.last_cash_on_hand_percentage
                    : "0.00"
                )}
                image={"/money-bag.png"}
              />
            </Stack>
            <Stack width="100%" align="center" p="10px 10px">
              <GrowthPlanCard
                leftTitle="Equity"
                rightTitle="Last Month"
                leftAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.equity : 0.0}`
                )}
                rightAmount={customCurrencyFormat(
                  `${growthPlanData ? growthPlanData.last_equity : 0.0}`
                )}
                rightPercentage={customCurrencyFormat(
                  `${
                    growthPlanData ? growthPlanData.last_equity_percentage : 0.0
                  }`,
                  true
                )}
                cardHeight="120px"
                cardWidth="100%"
                color={colorFormat(
                  growthPlanData
                    ? growthPlanData.last_equity_percentage
                    : "0.00"
                )}
                image={"/bar-chart-with-arrow.png"}
              />
            </Stack>
          </Grid>
        </VStack>
      )}
      {!isMobile ? (
        <SimpleGrid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "1fr 1fr 1fr 1fr",
          }}
          spacing={2}
          p="5px 5px 15px 5px"
          alignItems="center"
          justifyItems="center"
        >
          <ExpenseCard
            title="Team"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.team_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.team_pl : "0.00",
              growthPlanData ? growthPlanData.team_percentage : "0.00"
            )}
            type="team"
            triggerExpenseBreakdown={triggerExpenseBreakdown}
            setBreakdownData={setBreakdownData}
          />
          <ExpenseCard
            title="Marketing"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.marketing_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.marketing_pl : "0.00",
              growthPlanData ? growthPlanData.marketing_percentage : "0.00"
            )}
            type="marketing"
            triggerExpenseBreakdown={triggerExpenseBreakdown}
            setBreakdownData={setBreakdownData}
          />
          <ExpenseCard
            title="Overheads"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.overhead_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.overhead_pl : "0.00",
              growthPlanData ? growthPlanData.overhead_percentage : "0.00"
            )}
            type="overhead"
            triggerExpenseBreakdown={triggerExpenseBreakdown}
            setBreakdownData={setBreakdownData}
          />
          <ExpenseCard
            title="Products (COGS)"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.cogs_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.cogs_pl : "0.00",
              growthPlanData ? growthPlanData.cogs_percentage : "0.00"
            )}
            type="cogs"
            triggerExpenseBreakdown={triggerExpenseBreakdown}
            setBreakdownData={setBreakdownData}
          />
        </SimpleGrid>
      ) : (
        <VStack margin={"15px 0px"}>
          <Grid
            w="100%"
            h="100%"
            paddingTop={"5px"}
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
            }}
          >
            <ExpenseCard
              title="Team"
              percentage={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.team_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.team : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.team_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.team_pl : "0.00",
                growthPlanData ? growthPlanData.team_percentage : "0.00"
              )}
              type="team"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <ExpenseCard
              title="Marketing"
              percentage={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.marketing_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.marketing : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.marketing_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.marketing_pl : "0.00",
                growthPlanData ? growthPlanData.marketing_percentage : "0.00"
              )}
              type="marketing"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <ExpenseCard
              title="Overheads"
              percentage={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.overhead_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.overhead : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.overhead_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.overhead_pl : "0.00",
                growthPlanData ? growthPlanData.overhead_percentage : "0.00"
              )}
              type="overhead"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <ExpenseCard
              title="Products (COGS)"
              percentage={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.cogs_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.cogs : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.cogs_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.cogs_pl : "0.00",
                growthPlanData ? growthPlanData.cogs_percentage : "0.00"
              )}
              type="cogs"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          </Grid>
        </VStack>
      )}
      <ResourceOverview resourceOverviewData={resourceOverview} />
    </Stack>
  );
};

export const GrowthPlan = ({
  customer_id,
  dateFromNav = null,
  setDateFromNav = null,
  toggleView = false,
  setNavIndex = null,
  setNavVisible = null,
  toggleSubView = null,
}) => {
  const [tab, setTab] = useState(toggleView ? toggleView : "growthPlan");
  const [triggerRefesh, setTriggerRefresh] = useState(true);
  const [expenseBreakdown, setExpenseBreakdown] = useState(false);
  const [revenueGroup, setRevenueGroup] = useState("fiftyk");
  const [expenseBreakdownReturn, setExpenseBreakdownReturn] =
    useState("growthPlan");
  const [isInsights, setIsInsights] = useState(false);
  const { profile } = useCurrentProfile();

  // ------ Restore when ready -----

  // const now = new Date();
  // const firstDayOfCurrentMonthUTC = new Date(
  //   Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)
  // );

  // const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonthUTC);
  // lastDayOfPreviousMonth.setUTCDate(0);

  //----- Temporary logic -----
  const firstDayOfCurrentMonthUTC = new Date(Date.UTC(2025, 2, 1));
  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonthUTC);
  lastDayOfPreviousMonth.setUTCDate(0);
  // ---------------------------------------------------------------

  const [selectedPeriod, setSelectedPeriod] = useState("month");
  const [isMobile] = useMediaQuery("(max-width: 1280px)", { ssr: false });
  const [shiftToggle] = useMediaQuery("(max-width: 720px)", { ssr: false });

  const [dashDate, setDashDate] = useState(
    dateFromNav ? dateFromNav : lastDayOfPreviousMonth
  );
  const [breakdownData, setBreakdownData] = useState({ title: "", type: "" });
  const updateDate = (newDate) => {
    setDashDate(new Date(newDate));
    if (setDateFromNav) {
      setDateFromNav(newDate);
    }
  };

  return (
    <Container
      maxW="100%"
      bg="#F4F4F2"
      minH={"100vh"}
      p="0px"
      overflowY={setNavIndex ? "hidden" : "auto"}
      overflowX={"hidden"}
      position={"relative"}
    >
      {isMobile && (
        <Card
          position="fixed"
          zIndex={302}
          borderRadius={0}
          h="72px"
          borderBottom="1px solid #e0e0e0"
          width="100%"
        >
          <HStack
            justifyContent="space-between"
            alignItems="center"
            h={"100%"}
            w="100%"
            px={4}
          >
            <Button
              bg="white"
              _hover={{ color: "white" }}
              _active={{ color: "white" }}
              w="50px"
              h="50px"
              p={2}
              onClick={() => setNavVisible && setNavVisible(true)}
            >
              <Menu style={{ width: "50px", height: "100%" }} color="black" />
            </Button>
            {!expenseBreakdown ? (
              <>
                {!isInsights && (
                  <CalendarComponent
                    height="36px"
                    callbackFunctionDate={updateDate}
                    triggerSetter={setTriggerRefresh}
                    date={dashDate}
                  />
                )}

                {isInsights === true && (
                  <RevenueDropdown
                    revenueGroup={revenueGroup}
                    setRevenueGroup={setRevenueGroup}
                  />
                )}
              </>
            ) : (
              <>
                {isMobile && !shiftToggle && (
                  <ButtonGroup isAttached variant="outline">
                    <Button
                      _hover={{
                        background: "initial",
                        boxShadow: "none",
                        opacity: "100%",
                        backgroundColor:
                          selectedPeriod === "month" ? "#536DEC" : "white",
                      }}
                      backgroundColor={
                        selectedPeriod === "month" ? "#536DEC" : "white"
                      }
                      textColor={
                        selectedPeriod === "month" ? "white" : "#536DEC"
                      }
                      onClick={() => setSelectedPeriod("month")}
                      border={"1px solid #536DEC"}
                      width={"195px"}
                    >
                      Current Month
                    </Button>
                    <Button
                      _hover={{
                        background: "initial",
                        boxShadow: "none",
                        opacity: "100%%",
                        backgroundColor:
                          selectedPeriod === "year" ? "#536DEC" : "white",
                      }}
                      backgroundColor={
                        selectedPeriod === "year" ? "#536DEC" : "white"
                      }
                      textColor={
                        selectedPeriod === "year" ? "white" : "#536DEC"
                      }
                      onClick={() => setSelectedPeriod("year")}
                      border={"1px solid #536DEC"}
                      width={"195px"}
                    >
                      12 - Month Breakdown
                    </Button>
                  </ButtonGroup>
                )}

                {shiftToggle && (
                  <Box
                    gridRow={1}
                    gridColumn={1}
                    textAlign={"left"}
                    fontWeight={500}
                    fontSize={"20px"}
                    whiteSpace={"nowrap"}
                  >
                    {" "}
                    {breakdownData?.title} Breakdown
                  </Box>
                )}
              </>
            )}
          </HStack>
        </Card>
      )}
      {!isMobile && <></>}
      {!expenseBreakdown ? (
        <>
          <Card
            position={"fixed"}
            zIndex={301}
            borderRadius={0}
            mt={isMobile ? "72px" : "0px"}
            h={isMobile ? "50px" : "72px"}
            borderBottom="1px solid #e0e0e0"
            width={isMobile ? "100%" : "auto"}
            marginBottom={100}
          >
            <Tabs
              index={
                tab === "performanceReview" ? 1 : tab === "growthPlan" ? 0 : 2
              }
            >
              {!isMobile && (
                <Grid
                  templateColumns="1fr 2fr 1fr 9fr"
                  width="100%"
                  alignItems="center"
                  p="0px 20px"
                  h={isMobile ? "50px" : "72px"}
                >
                  <TabList
                    display="flex"
                    alignItems="center"
                    height={isMobile ? "50px" : "73px"}
                  >
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                        fontWeight: 700,
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("growthPlan");
                        setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                      }}
                      width="90px"
                      height="73px"
                      p="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize="16px">Growth Plan</Text>
                    </Tab>

                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                        fontWeight: 700,
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("performanceReview");
                        setNavIndex ? setNavIndex(1) : (setNavIndex = null);
                      }}
                      width="170px"
                      height="73px"
                      p="0"
                      whiteSpace="nowrap"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize="16px">Performance Review</Text>
                    </Tab>
                    {profile?.type === "admin" && (
                      <Tab
                        _focus={{
                          outline: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        _selected={{
                          borderBottom: "3px solid #536DEC",
                          color: "#536DEC",
                          fontWeight: 700,
                        }}
                        _active={{ background: "none" }}
                        cursor="pointer"
                        onClick={() => {
                          setTab("industryInsights");
                          setNavIndex ? setNavIndex(2) : (setNavIndex = null);
                        }}
                        width="120px"
                        height="73px"
                        p="0"
                        whiteSpace="nowrap"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="16px">Industry Insights</Text>
                      </Tab>
                    )}
                  </TabList>

                  <Flex
                    gridColumn={9}
                    textAlign="right"
                    alignItems="center"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    {tab === "growthPlan" && (
                      <CalendarComponent
                        height="36px"
                        callbackFunctionDate={updateDate}
                        triggerSetter={setTriggerRefresh}
                        date={dashDate}
                      />
                    )}
                  </Flex>
                </Grid>
              )}
              {isMobile && (
                <HStack width="100%" align="center" justify="flex-start">
                  <TabList
                    display="flex"
                    justifyContent="flex-start"
                    paddingLeft={2}
                    paddingRight={2}
                    w="100%"
                  >
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                        fontWeight: 700,
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("growthPlan");
                        setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                      }}
                      h="50px"
                      p="2px 4px"
                      whiteSpace="nowrap" // Prevents text from wrapping
                    >
                      <Text fontSize="14px" textAlign="center">
                        Growth Plan
                      </Text>
                    </Tab>
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                        fontWeight: 700,
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("performanceReview");
                      }}
                      minWidth="127px" // Ensures minimum width for Performance Review
                      h="50px"
                      p="2px 4px"
                      whiteSpace="nowrap" // Prevents text from wrapping
                    >
                      <Text fontSize="14px" textAlign="center">
                        Performance Review
                      </Text>
                    </Tab>
                    {profile?.type === "admin" && (
                      <Tab
                        _focus={{
                          outline: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        _selected={{
                          borderBottom: "3px solid #536DEC",
                          color: "#536DEC",
                          fontWeight: 700,
                        }}
                        _active={{ background: "none" }}
                        cursor="pointer"
                        onClick={() => {
                          setTab("industryInsights");
                          setNavIndex ? setNavIndex(2) : (setNavIndex = null);
                        }}
                        minWidth="101px"
                        h="50px"
                        p="2px 4px"
                        whiteSpace="nowrap"
                      >
                        <Text fontSize="14px" textAlign="center">
                          Industry Insights
                        </Text>
                      </Tab>
                    )}
                  </TabList>
                </HStack>
              )}
            </Tabs>
          </Card>

          {tab === "growthPlan" ? (
            <GrowthPlanContent
              customer_id={customer_id}
              date={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
              setNavIndex={setNavIndex}
              setIsInsights={setIsInsights}
              triggerExpenseBreakdown={setExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          ) : tab === "performanceReview" ? (
            <PerformanceReview
              customer_id={customer_id}
              dashDate={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
              updateDate={updateDate}
              triggerExpenseBreakdown={setExpenseBreakdown}
              setBreakdownData={setBreakdownData}
              setIsInsights={setIsInsights}
              setExpenseBreakdownReturn={setExpenseBreakdownReturn}
              toggleView={toggleSubView}
              setNavIndex={setNavIndex}
            />
          ) : (
            profile?.type === "admin" && (
              <IndustryInsightsSelection
                customer_id={customer_id}
                revenueGroup={revenueGroup}
                setRevenueGroup={setRevenueGroup}
                setIsInsights={setIsInsights}
              />
            )
          )}
        </>
      ) : (
        <ExpenseBreakdown
          customer_id={customer_id}
          dashDate={dashDate}
          setExpenseBreakdown={setExpenseBreakdown}
          setTab={setTab}
          title={breakdownData.title}
          type={breakdownData.type}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          originalTab={expenseBreakdownReturn}
        />
      )}
    </Container>
  );
};
