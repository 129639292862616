import React, { useState, useEffect } from "react";
import { Select } from "chakra-react-select";
import { Box } from "@chakra-ui/react";
import axios from "axios";

export const DropDown = ({
  name,
  options,
  selectedValue,
  onChange,
  metaData,
  w,
  menuPortalTarget,
  isReadOnly = false,
  isRequired=true
}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(options.find((a) => a.value === selectedValue));
  }, [options, selectedValue]);

  const onChangeLocal = (option) => {
    setSelected(option);
    onChange(option, metaData);
  };

  return (
    <Box w={w ? w : null} zIndex={1}>
      <Select
        menuPortalTarget={menuPortalTarget}
        name={name}
        options={options}
        value={selected}
        placeholder={`Select ${name}...`}
        onChange={onChangeLocal}
        useBasicStyles
        isReadOnly={isReadOnly}
        required={isRequired}
      />
    </Box>
  );
};

export const getChoiceFieldValues = (url) => {
  return axios
    .options(
      url,
      { headers: { "Content-Type": "application/json" } },
      { withCredentials: true }
    )
    .then((res) => {
      var choices = {};
      for (const [name, field] of Object.entries(res.data.actions.POST)) {
        if (field.type === "choice") {
          choices[name] = field.choices.map((choice) => ({
            value: choice.value,
            label: choice.display_name,
          }));
        }

        if (field.child?.children) {
          for (const [sub_name, sub_field] of Object.entries(
            field.child?.children
          )) {
            if (sub_field.type === "choice") {
              choices[sub_name] = sub_field.choices.map((choice) => ({
                value: choice.value,
                label: choice.display_name,
              }));
            }
          }
        }
      }
      return choices;
    })
    .catch((err) => console.log(err));
};
