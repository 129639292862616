import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { fetchTags } from "../../api/resources";
import { Context } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";
import { DropDown } from "../generic/dropDown";
import { ResourcesItems } from "./resourceItemsAdmin";
import { ResourceTagManagment } from "./tagAdmin";

export const ResourcesCategory = ({ customer_id }) => {
  const [loading, setLoading] = useState(true);
  const [resourceList, setResourceList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [toggleItems, setToggleItems] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [toggleTags, setToggleTags] = useState(false);

  const context = React.useContext(Context);

  useEffect(() => {
    const refreshResources = (customer_id) => {
      const url = `/api/v2/customers/${customer_id}/resourcecategories/`;
      axios
        .get(
          url,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setResourceList(res.data);
        })
        .catch((err) => console.log(err));
    };

    refreshResources(customer_id);
    setLoading(false);
  }, [customer_id]);

  const toggle = () => {
    setCurrentCategory({});
    setIsModalOpen(!isModalOpen);
  };

  const startCreateResourceCategories = () => {
    setCurrentCategory({});
    setIsModalOpen(true);
  };

  const startUpdateResourceCategories = (resourceCategoryData) => {
    setCurrentCategory(resourceCategoryData);
    setIsModalOpen(true);
  };

  const toggleDelete = () => {
    setCurrentCategory({});
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const startDeleteResourceCategories = (resourceCategoryData) => {
    setCurrentCategory(resourceCategoryData);
    setIsDeleteModalOpen(true);
  };

  const toggleView = () => {
    setToggleItems(true);
  };

  const handleSubmit = async (event, resource) => {
    event.preventDefault();
    setLoading(true);

    let url = `/api/v2/customers/${customer_id}/resourcecategories/`;

    try {
      let res;
      if (resource.id) {
        url += `${resource.id}/`;
        res = await axios.patch(url, resource);

        if (res.status < 300) {
          setResourceList(
            resourceList.map((o) => (o.id === res.data.id ? res.data : o))
          );
          context.fireToast(
            "Success",
            "Resource category has been updated",
            "success"
          );
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      } else {
        resource["customer"] = customer_id;
        res = await axios.post(url, resource);

        if (res.status < 300) {
          setResourceList([...resourceList, res.data]);
          context.fireToast("Success", "Resource category added", "success");
          toggle();
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
      }
    } catch (err) {
      console.error("There was an error updating the resource category!", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (resourceCategoryData) => {
    let url = `/api/v2/customers/${customer_id}/resourcecategories/`;

    url += `${resourceCategoryData.id}/`;

    axios
      .delete(url)
      .then((res) => {
        if (res.status < 300) {
          setResourceList(
            resourceList.filter((o) => o.id !== resourceCategoryData.id)
          );
          context.fireToast(
            "Success",
            "Resource category has been deleted",
            "success"
          );
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
        setLoading(false);
        toggleDelete();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      {!toggleItems && !toggleTags && (
        <>
          {loading && (
            <FontAwesomeIcon
              className="float-end fa-spin"
              icon={faSpinner}
              color="green"
            />
          )}
          <Stack direction="column" spacing={2} align="center">
            <CustomTableContainer width="100%">
              <ResourceCategoryModal
                isOpen={isModalOpen}
                toggle={toggle}
                title="Resource Category"
                resourceCategoryData={currentCategory}
                loading={loading}
                handleSubmit={handleSubmit}
                customer_id={customer_id}
              />
              <ResourceCategoryDeleteModal
                colorScheme="red"
                isOpen={isDeleteModalOpen}
                toggle={toggleDelete}
                resourceCategoryData={currentCategory}
                loading={loading}
                handleDelete={handleDelete}
              />
              <Flex pb="5">
                <Box>
                  <Heading as="h3" size="lg">
                    Resource Categories
                  </Heading>
                </Box>
                <Spacer />
                <Box>
                  <HStack direction="row" spacing={6} align="center">
                    <Button onClick={() => setToggleTags(true)}>
                      Manage Resource Tags
                    </Button>
                    <Button
                      onClick={startCreateResourceCategories}
                      leftIcon={<AddIcon boxSize={3} />}
                    >
                      Create Resource Category
                    </Button>
                  </HStack>
                </Box>
              </Flex>
              <Box
                maxHeight="calc(100vh - 350px)"
                bg="white"
                overflowY={"auto"}
              >
                <Table variant="unstyled" overflowY={"auto"}>
                  <Thead position="sticky" top={-1} zIndex={1}>
                    <Tr>
                      <Th>Category Name</Th>
                      <Th>Description</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {resourceList.map((resource) => (
                      <ResourceCategoryRow
                        key={resource.id}
                        resourceCategoryData={resource}
                        startUpdateResourceCategories={
                          startUpdateResourceCategories
                        }
                        toggle={toggleView}
                        category={setSelectedCategory}
                        startDeleteResourceCategories={
                          startDeleteResourceCategories
                        }
                        customer_id={customer_id}
                      />
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </CustomTableContainer>
          </Stack>
        </>
      )}{" "}
      {toggleItems && (
        <ResourcesItems
          customer_id={customer_id}
          selectedResourceCategory={selectedCategory}
          turnBack={setToggleItems}
        />
      )}
      {toggleTags && (
        <ResourceTagManagment
          toggle={setToggleTags}
          customer_id={customer_id}
        />
      )}
    </Container>
  );
};

const ResourceCategoryRow = ({
  resourceCategoryData,
  startUpdateResourceCategories,
  toggle,
  category,
  startDeleteResourceCategories,
}) => {
  return (
    <>
      <Tr key={resourceCategoryData.id}>
        <Td>{resourceCategoryData.name}</Td>
        <Td>{resourceCategoryData.description}</Td>
        <Td>
          <HStack spacing={2}>
            <Button
              onClick={() => {
                category(resourceCategoryData.id);
                toggle();
              }}
            >
              View Resources
            </Button>
            <Button
              onClick={() =>
                startUpdateResourceCategories(resourceCategoryData)
              }
            >
              Edit
            </Button>
            <Button
              colorScheme="red"
              onClick={() =>
                startDeleteResourceCategories(resourceCategoryData)
              }
            >
              Delete
            </Button>
          </HStack>
        </Td>
      </Tr>
    </>
  );
};

const ResourceCategoryDeleteModal = ({
  isOpen,
  toggle,
  resourceCategoryData,
  loading,
  handleDelete,
  customer_id,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this resource category{" "}
          {resourceCategoryData.name}?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            onClick={() => handleDelete(resourceCategoryData)}
            isDisabled={loading}
          >
            {loading ? "Processing..." : "Delete"}
          </Button>
          <Button onClick={toggle} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ResourceCategoryModal = ({
  isOpen,
  toggle,
  title,
  resourceCategoryData,
  loading,
  handleSubmit,
  customer_id,
}) => {
  const confirmText = !resourceCategoryData.id ? "Create" : "Update";
  const [resourceData, setResourceData] = useState({});
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    setResourceData({
      ...resourceCategoryData,
      name: resourceCategoryData.name || "",
      description: resourceCategoryData.description || "",
    });
  }, [resourceCategoryData]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTags(customer_id);
      setTagList(data);
    };
    fetchData();
  }, [customer_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setResourceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e, resourceData);
  };

  const handleSelect = (selectedOption) => {
    setResourceData((prev) => ({
      ...prev,
      tag: selectedOption ? selectedOption.value : null,
    }));
  };

  const tagOptions = tagList.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmText + " " + title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight={"700px"} minH={"600px"} overflowY="auto">
          <form id="ResourceCategoryForm" onSubmit={handleFormSubmit}>
            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                Resource Category Name
              </Text>
              <Input
                type="text"
                name="name"
                placeholder="Please enter the name of this resource category."
                value={resourceData.name}
                onChange={handleInputChange}
                required
              />
            </FormControl>

            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                Resource Category Description
              </Text>
              <Input
                type="text"
                name="description"
                placeholder="Please enter the resource description."
                value={resourceData.description}
                onChange={handleInputChange}
                required
              />
            </FormControl>

            <FormControl isRequired>
              <Text
                fontSize="20px"
                textDecoration="underline"
                padding="10px 0px"
              >
                Assign Tag to Resource
              </Text>
              <DropDown
                name="Assign tag to category"
                options={tagOptions}
                selectedValue={resourceData.tag}
                onChange={handleSelect}
                w="100%"
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={toggle}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="ResourceCategoryForm"
            isDisabled={loading}
            ml={3}
          >
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
