import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  Image,
  Input,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Upload } from "react-feather";
import { validateImageDimensions } from "../../utils";
import { Context, useCurrentProfile } from "../../ContextWrapper";
import NotificationContainer from "../generic/updateNotification";

export const MyDetails = ({
  setNavIndex = null,
  setNavVisible = null,
  trigger,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [profileData, setProfileData] = useState({});
  const [imagePreview, setImagePreview] = useState(
    profileData?.profile_picture || ""
  );
  const [initialData, setInitialData] = useState({});
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const context = React.useContext(Context);
  const { profile } = useCurrentProfile();

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshProfileData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(`/api/v2/profiles/${id}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            signal: controller.signal, // Attach the signal for cancellation
          });
          if (isActive) {
            setProfileData(res.data); // Update state only if the effect is active
            setInitialData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshProfileData(profile.id);

    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [profile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Validate file size
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        context.fireToast(
          "Error",
          "File size exceeds 2MB. Please upload a smaller file.",
          "error"
        );
        return;
      }

      // Validate file dimensions
      const validDimensions = { width: 1080, height: 1200 }; // Max dimensions
      const isValid = validateImageDimensions(file, validDimensions);
      if (!isValid) {
        context.fireToast(
          "Error",
          `Image dimensions must not exceed ${validDimensions.width}x${validDimensions.height} pixels.`,
          "error"
        );
        return;
      }

      // Validate file type and size
      const validTypes = [
        "image/svg+xml",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];

      if (!validTypes.includes(file.type)) {
        context.fireToast(
          "Error",
          "Unsupported file type. Please upload an SVG, PNG, JPEG, or GIF.",
          "error"
        );

        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result); // Update the preview URL
      };
      reader.readAsDataURL(file);

      // Update the state or form data
      setProfileData((prev) => ({
        ...prev,
        profile_picture: file,
      }));
    }
  };

  const checkMatch = (e) => {
    const firstName =
      profileData?.first_name !== undefined &&
      profileData?.first_name !== initialData?.user?.first_name;
    const lastName =
      profileData?.last_name !== undefined &&
      profileData?.last_name !== initialData?.user?.last_name;
    const phoneNumber = profileData?.phone_number !== initialData.phone_number;
    const profilePicture =
      profileData?.profile_picture !== initialData.profile_picture;

    if (firstName || lastName || phoneNumber || profilePicture) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = (e) => {
    setProfileData(initialData);
    setImagePreview("");
  };

  const handleSubmit = async (event, profileFormData) => {
    event.preventDefault();
    setToggleDisabled(true);

    let url = `/api/v2/profiles/${profile.id}/`;

    const formData = new FormData();
    if (profileFormData?.first_name) {
      formData.append("first_name", profileFormData.first_name);
    }
    if (profileFormData?.last_name) {
      formData.append("last_name", profileFormData.last_name);
    }
    if (profileFormData?.phone_number) {
      const phoneRegex = /^\+?\d{1,3}\d{9,15}$/;
      if (!phoneRegex.test(profileFormData.phone_number)) {
        // Show an error message if the phone number is invalid
        context.fireToast(
          "Error",
          "Please enter a valid phone number.",
          "error"
        );
        setToggleDisabled(false);
        return;
      }
      formData.append("phone_number", profileFormData.phone_number);
    }
    if (profileFormData?.profile_picture) {
      formData.append("profile_picture", profileFormData.profile_picture);
    }
    try {
      let res;
      res = await axios.patch(url, formData);

      if (res.status < 300) {
        setProfileData({
          ...profileData,
          ...res.data,
        });
        setInitialData({
          ...profileData,
          ...res.data,
        });
        trigger(true);
        context.fireToast(
          "Success",
          "Your profile has been successfully updated!",
          "success"
        );
        setToggleDisabled(false);
      } else {
        context.fireToast("Error", "Failed to fetch data from server", "error");
        setToggleDisabled(false);
      }
    } catch (err) {
      if (err?.response) {
        console.log(err);
      }
      setToggleDisabled(false);
    }
  };

  return (
    <Box pt={!isMobile ? "100px" : "150px"} pl={"24px"} pr={"24px"} pb={"24px"}>
      <NotificationContainer rounded={true} p={"0px 0px 24px"}/>
      <form id="profileForm" onSubmit={(e) => handleSubmit(e, profileData)}>
        <Card borderRadius={"12px"}>
          <CardHeader p={0}>
            <Text
              p={"20px 20px 6px 20px"}
              fontSize={"22px"}
              fontWeight={600}
              lineHeight={"30px"}
            >
              My Details
            </Text>
            <Text
              p={"0px 20px 20px 20px"}
              fontSize={"15px"}
              fontWeight={400}
              lineHeight={"24px"}
              color={"#4A5568"}
            >
              Please fill full details about yourself.
            </Text>
            <Box borderBottom={"1px"} borderColor={"#DEE2E6"}></Box>
          </CardHeader>
          <CardBody height={"700px"} pt={"32px"}>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              gridGap={{ base: "20px", md: "40px" }}
              pb={"20px"}
            >
              <Box>
                <Text
                  pb={"7px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  lineHeight={"21px"}
                >
                  First Name
                </Text>
                <FormControl>
                  <Input
                    h={"41px"}
                    borderRadius={"8px"}
                    type={"text"}
                    name="first_name"
                    onChange={handleInputChange}
                    value={
                      profileData?.first_name ??
                      profileData?.user?.first_name ??
                      ""
                    }
                    placeholder="Enter your first name"
                  />
                </FormControl>
              </Box>
              <Box>
                <Text
                  pb={"7px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  lineHeight={"21px"}
                >
                  Last Name
                </Text>
                <FormControl>
                  <Input
                    h={"41px"}
                    borderRadius={"8px"}
                    type={"text"}
                    name="last_name"
                    onChange={handleInputChange}
                    value={
                      profileData?.last_name ??
                      profileData?.user?.last_name ??
                      ""
                    }
                    placeholder="Enter your last name"
                  />
                </FormControl>
              </Box>
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              gridGap={{ base: "20px", md: "40px" }}
            >
              <Box>
                <Text
                  pb={"7px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  lineHeight={"21px"}
                >
                  Email
                </Text>
                <FormControl>
                  <Input
                    h={"41px"}
                    borderRadius={"8px"}
                    readOnly={true}
                    type={"email"}
                    value={
                      profileData?.user?.username
                        ? profileData.user.username
                        : ""
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <Text
                  pb={"7px"}
                  fontSize={"15px"}
                  fontWeight={600}
                  lineHeight={"21px"}
                >
                  Phone Number
                </Text>
                <FormControl>
                  <Input
                    h={"41px"}
                    borderRadius={"8px"}
                    type="text"
                    name="phone_number"
                    onChange={handleInputChange}
                    value={
                      profileData?.phone_number ? profileData.phone_number : ""
                    }
                    placeholder="Enter your phone number"
                  />
                </FormControl>
              </Box>
            </SimpleGrid>
          </CardBody>
          <CardFooter pt={"4px"} pb={"32px"}>
            <Box display={"block"} width={"100%"}>
              <Text
                pb={"7px"}
                fontSize={"15px"}
                fontWeight={600}
                lineHeight={"21px"}
              >
                Your Photo
              </Text>
              <Box>
                <Flex
                  pt="2px"
                  width="100%"
                  alignItems="left"
                  justifyContent="space-between"
                  flexDirection={{ base: "column", xxsm: "row" }} // Stack vertically on small screens
                >
                  {/* Left-aligned Image */}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"flex-start"} // Center on small screens
                    mb={{ xxsm: "0", base: "20px" }} // Add spacing when stacked
                  >
                    <Image
                      src={
                        imagePreview ||
                        profileData?.profile_picture ||
                        (profileData?.user?.first_name &&
                        profileData?.user?.last_name
                          ? `https://ui-avatars.com/api/?size=24&name=${profileData.user.first_name}+${profileData.user.last_name}`
                          : `https://ui-avatars.com/api/?size=24&name=${
                              profileData?.user?.username || "Placeholder"
                            }`)
                      }
                      alt={
                        profileData?.user?.first_name ||
                        profileData?.user?.username ||
                        "Placeholder"
                      }
                      boxSize="80px"
                      objectFit="cover"
                      borderRadius="full"
                      mr={"20px"}
                    />
                  </Box>

                  {/* Right-aligned Drag-and-Drop Box */}
                  <FormControl>
                    <Box
                      borderWidth="1px"
                      borderStyle="dashed"
                      borderColor="#B2CCFD"
                      borderRadius="16px"
                      backgroundColor="#ECF2FE"
                      flex="1"
                      width={{ base: "100%", md: "auto" }} // Full width on small screens, auto width on larger screens
                      height="141px"
                      minHeight={"141px"}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      cursor="pointer"
                      _hover={{ borderColor: "blue.300" }}
                      position="relative"
                    >
                      <Upload />
                      <Text
                        mt={2}
                        fontSize={"13px"}
                        fontWeight={400}
                        textAlign={"center"}
                      >
                        Click to upload or drag and drop
                      </Text>
                      <Text
                        fontSize={"13px"}
                        fontWeight={400}
                        color={"#3E80F9"}
                        textAlign={"center"}
                      >
                        SVG, PNG, JPEG OR GIF (max 1080px1200px)
                      </Text>
                      <Input
                        type="file"
                        accept=".svg,.png,.jpg,.jpeg,.gif,image/svg+xml,image/png,image/jpeg,image/gif"
                        opacity="0"
                        name="profile_picture"
                        position="absolute"
                        width="100%"
                        height="100%"
                        cursor="pointer"
                        onChange={(e) => handleFileChange(e)}
                      />
                    </Box>
                  </FormControl>
                </Flex>
                {checkMatch() && (
                  <Box
                    width={"100%"}
                    textAlign={"right"}
                    pt={"24px"}
                    display="flex"
                    justifyContent="flex-end"
                    gap="16px" // Add space between the buttons
                  >
                    <Button
                      type="submit"
                      isDisabled={toggleDisabled}
                      fontSize={"16px"}
                      fontWeight={"500"}
                      height={"36px"}
                      width={"90px"}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outline"
                      isDisabled={toggleDisabled}
                      fontSize={"16px"}
                      fontWeight={"500"}
                      height={"36px"}
                      width={"90px"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </CardFooter>
        </Card>
      </form>
    </Box>
  );
};
