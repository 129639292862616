import React from "react";
import {
  Box,
  Flex,
  Center,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Image,
} from "@chakra-ui/react";
import { Settings, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../ContextWrapper";
import NotificationContainer from "./generic/updateNotification";

export default function Navigation({ isAdmin }) {
  const context = React.useContext(Context);
  const navigate = useNavigate();

  return (
    <>
      <NotificationContainer />
      <Box bg="brand.1000" color="white" px={4}>
        <Flex h="80px" alignItems={"center"} justifyContent={"space-between"}>
          <Center w={280}>
            <Box>
              <Image
                onClick={() => navigate("/")}
                boxSize="170px"
                objectFit="contain"
                src="/lucro-logo-white.png"
                cursor={"pointer"}
              />
            </Box>
          </Center>
          <Flex alignItems={"center"}>
            {isAdmin && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Settings color="white" />
                </MenuButton>
                <MenuList zIndex={99999}>
                  <MenuItem onClick={() => navigate("/vendors")}>
                    Vendors
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate("/chart-of-accounts-template")}
                  >
                    CoA
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/users")}>Users</MenuItem>
                </MenuList>
              </Menu>
            )}
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
                pl="20px"
              >
                <User color="white" />
              </MenuButton>
              <MenuList zIndex={99999}>
                <MenuItem
                  onClick={() =>
                    context.fireToast(
                      "Profile settings",
                      "Under construction, Coming soon....",
                      "success"
                    )
                  }
                >
                  Profile Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem>
                  <Link className="nav-link" to="/logout">
                    Logout
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
