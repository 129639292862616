import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context, useCurrentProfile } from "../../ContextWrapper";
import {
  createCustomer,
  createCustomerGroup,
  deleteCustomer,
  fetchCustomers,
  fetchGroupCustomers,
  updateCustomer,
  updateCustomerGroup,
  uploadCustomerIndicators,
} from "../../api/customers";
import CustomTableContainer from "../../theme/components/tableContainer";
import { LogoField } from "../generic/logoField";
import { GenericModal } from "../generic/modal";
import { ClientCurrentStatus } from "./clientCurrentStatus";
import { DropDown } from "../generic/dropDown";
import { Trash } from "react-feather";

export const ClientList = () => {
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const context = React.useContext(Context);
  const { profile } = useCurrentProfile();
  const {
    isOpen: isClientOpen,
    onOpen: onClientOpen,
    onClose: onClientClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();

  const [selectedClient, setSelectedClient] = useState(null);
  const [error, setError] = useState([]);
  const refreshCustomersList = useCallback(
    (cursor) => {
      let params = {};
      if (cursor) {
        const urlObj = new URL(cursor);
        params.cursor = urlObj.searchParams.get("cursor");
      }

      if (search) {
        params.search = search;
      }

      const fetchData = async () => {
        const data = await fetchCustomers(profile, params);
        setCustomerList(data.results);
        setNextCursor(data.next);
        setPreviousCursor(data.previous);
      };
      fetchData();
    },
    [profile, search]
  );

  useEffect(() => {
    refreshCustomersList();
  }, [profile, refreshCustomersList]);

  const handleNextPage = () => {
    if (nextCursor) {
      refreshCustomersList(nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshCustomersList(previousCursor);
    }
  };

  const handleSubmitLinkClients = (event, linkedCustomers) => {
    event.preventDefault();
    const formData = new FormData();

    linkedCustomers.forEach((customer) => {
      formData.append("customer_group", customer.value);
    });

    if (selectedClient?.customer_group) {
      updateCustomerGroup(
        selectedClient.id,
        selectedClient.customer_group,
        formData
      )
        .then((res) => {
          setCustomerList(
            customerList.map((p) => (p.id === res.data.id ? res.data : p))
          );
          context.fireToast(
            "Success",
            "Client group has been updated.",
            "success"
          );
          onAssignClose();
        })
        .catch((err) => console.log(err));

    } else {
      createCustomerGroup(selectedClient.id, formData)
        .then((res) => {
          const updatedCustomerList = [...customerList];
          const customerToUpdate = updatedCustomerList.find(
            (customer) => customer.id === selectedClient.id
          );

          if (customerToUpdate) {
            customerToUpdate.customer_group = res.data.id;
          }

          setCustomerList(updatedCustomerList);
          context.fireToast(
            "Success",
            "Client group has been updated.",
            "success"
          );
          onAssignClose();
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = (event, data) => {
    event.preventDefault();

    if (data.id) {
      updateCustomer(data.id, data)
        .then((res) => {
          setCustomerList((prevList) =>
            prevList.map((c) => (c.id === res.data.id ? res.data : c))
          );
          onClientClose();
          context.fireToast("Success", "Client updated.", "success");
        })
        .catch((err) => console.log(err));
    } else {
      createCustomer(data)
        .then((res) => {
          setCustomerList((prevList) => [...prevList, res.data]);
          onClientClose();
          context.fireToast("Success", "Client created.", "success");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleUpload = (file) => {
    const formData = new FormData();
    formData.append("file_uploaded", file);

    setLoading(true);
    uploadCustomerIndicators(formData)
      .then((response) => {
        if (response.status === 200) {
          onUploadClose();
          setLoading(false);
          context.fireToast(
            "Success",
            "Successfully imported client data",
            "success"
          );
          refreshCustomersList();
        } else {
          onUploadClose();
          setLoading(false);
          setError(response.response.data.errors);
          onErrorOpen();
        }
      })
      .catch((error) => {
        onUploadClose();
        setLoading(false);
        setError(error.response.data.errors);
        onErrorOpen();
        console.error("There was an error uploading the file!", error);
      });
  };

  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const startCreateClient = () => {
    setSelectedClient(null);
    onClientOpen();
  };

  const startAssignClients = (customer) => {
    setSelectedClient(customer);
    onAssignOpen();
  };

  const startUpdateClient = (customer) => {
    setSelectedClient(customer);
    onClientOpen();
  };

  const startDelete = () => {
    onClientClose();
    onDeleteOpen();
  };

  const removeCustomerFromList = (customerId) => {
    setCustomerList((prevList) => prevList.filter((c) => c.id !== customerId));
    context.fireToast("Success", "Client Deleted.", "success");
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="90%">
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Client Listing
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction="row" spacing={6} align="center">
                <CustomerDelete
                  isOpen={isDeleteOpen}
                  onClose={onDeleteClose}
                  customer={selectedClient}
                  removeCustomerFromList={removeCustomerFromList}
                />
                <CustomerModal
                  isOpen={isClientOpen}
                  onClose={onClientClose}
                  customer={selectedClient}
                  handleSubmit={handleSubmit}
                  startDelete={startDelete}
                />
                <UploadModal
                  isOpen={isUploadOpen}
                  onClose={onUploadClose}
                  loading={loading}
                  handleUpload={handleUpload}
                />
                <ErrorModal
                  isOpen={isErrorOpen}
                  onClose={onErrorClose}
                  errors={error}
                />
                <CustomerAssignModal
                  isOpen={isAssignOpen}
                  onClose={onAssignClose}
                  customer={selectedClient}
                  loading={loading}
                  handleSubmit={handleSubmitLinkClients}
                />
                {profile?.type === "admin" && (
                  <Button ps={10} pe={10} onClick={onUploadOpen}>
                    Upload March Indicators
                  </Button>
                )}
                <Button
                  ps={10}
                  pe={10}
                  leftIcon={<AddIcon />}
                  onClick={startCreateClient}
                >
                  Create Client
                </Button>
                <InputGroup minW="340px">
                  <Input
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <InputRightElement
                    bg="brand.500"
                    borderBottomRightRadius={6}
                    borderTopRightRadius={6}
                  >
                    <SearchIcon variant="white" />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 300px)" bg="white" overflowX="auto">
            <Table variant="unstyled" width="100%">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Business Name</Th>
                  <Th>Email Address</Th>
                  <Th>Primary Contact</Th>
                  <Th>Current Status</Th>
                  <Th style={{ width: "118px" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {customerList.map((customer) => (
                  <Tr key={customer.id}>
                    <Td style={{ paddingRight: "0px" }}>
                      <Box display="inline-flex" alignItems="center">
                        <Image
                          src={
                            customer.logo ||
                            `https://ui-avatars.com/api/?size=24&name=${customer.name}`
                          }
                          alt={customer.name || "Placeholder"}
                          boxSize="25px"
                          objectFit="cover"
                          borderRadius="full"
                          mr="2"
                        />
                        {customer.name}
                      </Box>
                    </Td>
                    <Td>{customer.email}</Td>
                    <Td>{customer.primary_contact}</Td>
                    <Td>
                      <ClientCurrentStatus customer={customer} />
                    </Td>
                    <Td pt="1" pb="1">
                      <Stack p="0" direction="row" spacing={2} align="center">
                        {customer?.customer_type === "group" && (
                          <Button
                            variant="outline"
                            onClick={() => startAssignClients(customer)}
                          >
                            Assign to group
                          </Button>
                        )}
                        <Button
                          variant="outline"
                          onClick={() => startUpdateClient(customer)}
                        >
                          Edit
                        </Button>
                        <Link to={`/client/${customer.id}`}>
                          <Button>View</Button>
                        </Link>
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Stack direction="row" spacing={4} mt={4} justifyContent="center">
            {previousCursor && (
              <Button onClick={handlePreviousPage}>Previous</Button>
            )}
            {nextCursor && (
              <Button onClick={handleNextPage} disabled={!nextCursor}>
                Next
              </Button>
            )}
          </Stack>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const CustomerModal = ({
  isOpen,
  onClose,
  customer,
  loading,
  handleSubmit,
  startDelete,
}) => {
  const [clientData, setClientData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  const text = !customer?.id ? "Create" : "Update";

  const clientTypeOptions = [
    { value: "normal", label: "Normal" },
    { value: "group", label: "Group" },
  ];

  useEffect(() => {
    setImagePreview(null);
    if (customer?.logo) {
      setImagePreview(customer.logo);
      delete customer.logo;
    }
    setClientData(customer);
  }, [customer]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setClientData({ ...clientData, [e.target.name]: file });
    setImagePreview(URL.createObjectURL(file));
  };

  const [selectedClientType, setSelectedClientType] = useState(
    clientData?.customer_type ? clientData.customer_type : "normal"
  );

  useEffect(() => {
    setSelectedClientType(
      clientData?.customer_type ? clientData.customer_type : "normal"
    );
  }, [customer, isOpen, clientData?.customer_type]);

  const handleClientTypeChange = (option) => {
    setSelectedClientType(option.value);
    setClientData({ ...clientData, customer_type: option.value });
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={`${text} Client`}
      loading={loading}
      body={
        <form
          id="customerForm"
          onSubmit={(e) => handleSubmit(e, clientData)}
          encType="multipart/form-data"
        >
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              name="name"
              id="clientName"
              value={clientData?.name || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              id="clientEmail"
              value={clientData?.email || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="primary_contact">
            <FormLabel>Primary Contact</FormLabel>
            <Input
              type="text"
              name="primary_contact"
              id="clientPrimaryContact"
              value={clientData?.primary_contact || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          {text === "Create" && (
            <FormControl>
              <FormLabel>Client Type</FormLabel>
              <DropDown
                name="customer_type"
                options={clientTypeOptions}
                selectedValue={selectedClientType}
                onChange={handleClientTypeChange}
                w="100%"
              />
            </FormControl>
          )}
          <LogoField
            name="logo"
            text="Client Logo"
            imagePreview={imagePreview}
            onChange={handleImageChange}
          />
        </form>
      }
      leftButton={
        customer?.id && (
          <Button onClick={startDelete} colorScheme="red">
            Delete
          </Button>
        )
      }
      submitButton={
        <Button ml="3" type="submit" form="customerForm">
          {text}
        </Button>
      }
    />
  );
};

const CustomerDelete = ({
  isOpen,
  onClose,
  customer,
  removeCustomerFromList,
}) => {
  const context = React.useContext(Context);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    deleteCustomer(customer.id)
      .then((response) => {
        if (response.status === 200) {
          removeCustomerFromList(customer.id);
          onClose();
        } else {
          context.fireToast(
            "Error",
            response["request"]["responseText"],
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the client!", error);
        context.fireToast("Error", "Failed to delete the client.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Confirm Delete"}
      loading={loading}
      body={`Are you sure you want to delete ${customer?.name}?`}
      submitButton={
        <Button
          colorScheme="red"
          ml="3"
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? "Processing..." : "Delete"}
        </Button>
      }
    />
  );
};

const UploadModal = ({ isOpen, onClose, loading, handleUpload }) => {
  const [file, setFile] = useState(null);

  const onUploadFile = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Upload Client Data`}
      submitButton={
        <Button
          ml={5}
          onClick={() => handleUpload(file)}
          isDisabled={loading}
          form={"uploadCustomerData"}
        >
          {loading ? "Processing..." : "Upload"}
        </Button>
      }
      body={
        <form id={"uploadCustomerData"}>
          <FormControl isRequired>
            <FormLabel>Files</FormLabel>
            <Input
              type="file"
              accept=".xlsx"
              sx={{
                "::file-selector-button": {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                },
              }}
              onChange={onUploadFile}
            />
          </FormControl>
        </form>
      }
    ></GenericModal>
  );
};

const ErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Upload Errors`}
      body={
        <>
          {errors &&
            errors.map((e, i) => (
              <Box key={i}>
                <Text as="b">{e.error}</Text>
                {e.detail.map((d, i2) => (
                  <Text key={i + i2} mb={0}>
                    {d}
                  </Text>
                ))}
              </Box>
            ))}
          {!errors && <Text>Server error, please check template format</Text>}
        </>
      }
    ></GenericModal>
  );
};

const CustomerAssignModal = ({
  isOpen,
  onClose,
  customer,
  loading,
  handleSubmit,
}) => {


  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [linkedCustomers, setLinkedCustomers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const paramsLinked = { customer_id: customer?.id, exclude_group: false };
      const linkedResults = fetchGroupCustomers(paramsLinked, true);

      const paramsUnlinked = {
        customer_id: customer?.id,
        exclude_group: true,
      };
      const unlinkedResults = fetchGroupCustomers(paramsUnlinked, true);

      setLinkedCustomers((await linkedResults) ?? []);
      setCustomers((await unlinkedResults) ?? []);
    };
    fetchData();
  }, [isOpen, customer]);

  const handleSelectCustomer = (customer) => {
    setCustomers((prev) => prev.filter((c) => c.value !== customer.value));
    setLinkedCustomers([...linkedCustomers, customer]);
    setSelectedCustomer(null);
  };

  const removeCustomer = (customer) => {
    setLinkedCustomers((prev) =>
      prev.filter((c) => c.value !== customer.value)
    );
    setCustomers([...customers, customer]);
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title="Manage Client Group"
      submitButton={
        <Button
          type="submit"
          form="updateCustomerGroup"
          isDisabled={loading}
          ml={3}
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
      }
      body={
        <form
          id={"updateCustomerGroup"}
          onSubmit={(e) => handleSubmit(e, linkedCustomers)}
        >
          <FormControl>
            <FormLabel htmlFor="customer">Add client</FormLabel>
            <DropDown
              name="customer"
              options={customers}
              selectedValue={selectedCustomer}
              onChange={handleSelectCustomer}
              isRequired={false}
            />
          </FormControl>

          <Box
            mt={5}
            maxHeight="calc(100vh - 480px)"
            bg="white"
            overflowX="auto"
          >
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Td>Linked Clients</Td>
                  <Td></Td>
                </Tr>
              </Thead>
              <Tbody>
                {linkedCustomers?.map((c) => (
                  <Tr key={c.value}>
                    <Td pt="4px" pb="4px">
                      {c.label}
                    </Td>
                    <Td pt="4px" pb="4px" w="40px">
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        icon={<Trash />}
                        onClick={() => removeCustomer(c)}
                        variant="outline"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </form>
      }
    />
  );
};
