import { deleteRequest, get, patch, post } from "./api";

const fetchCustomers = (profile, params = {}, dropdown_format = false) => {
  if (profile?.hasAccountingAccess && profile?.type === "accountant") {
    params.having_profile = profile.id;
  } else if (profile?.type === "client") {
    params.having_profile = profile.id;
  }

  return get("/api/v2/customers/", params, dropdown_format);
};

const fetchAllCustomers = (profile, params = {}, dropdown_format = false) => {
  if (profile?.hasAccountingAccess && profile?.type === "accountant") {
    params.having_profile = profile.id;
  } else if (profile?.type === "client") {
    params.having_profile = profile.id;
  }

  return get("/api/v2/all_customers/", params, dropdown_format);
};

const fetchGroupCustomers = (params = {}, dropdown_format = false) => {

  return get("/api/v2/group_customers/", params, dropdown_format);
};

const updateCustomerGroup = (customerId, groupId, formData) => {
  return patch(`/api/v2/customers/${customerId}/group/${groupId}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const createCustomerGroup = (customerId, formData) => {
  return post(`/api/v2/customers/${customerId}/group/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateCustomer = (id, data) => {
  return patch(`/api/v2/customers/${id}/`, data, "multipart/form-data");
};

const createCustomer = (data) => {
  data.is_active = true;
  return post("/api/v2/customers/", data, "multipart/form-data");
};

const deleteCustomer = (id) => {
  return deleteRequest(`/api/v2/customers/${id}/`);
};

const uploadCustomerData = (file) => {
  return post("/api/v2/customer_data_import/", file, "multipart/form-data");
};

const uploadCustomerIndicators = (file) => {
  return post(
    "/api/v2/customer_indicator_import/",
    file,
    "multipart/form-data"
  );
};

export {
  fetchCustomers,
  fetchAllCustomers,
  fetchGroupCustomers,
  createCustomerGroup,
  updateCustomerGroup,
  updateCustomer,
  createCustomer,
  deleteCustomer,
  uploadCustomerData,
  uploadCustomerIndicators,
};
