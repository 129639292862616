import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Box,
    Card,
    CardBody,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    VStack
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./datePickerStyles.css";

const RevenueDropdown = ({ revenueGroup, setRevenueGroup }) => {
    const [showPicker, setShowPicker] = useState(false);
    const revenueRanges = [
      { label: "$0 - $50,000", value: "fiftyk" },
      { label: "$50,000 - $100,000", value: "hundredk" },
      { label: "$100,000 - $150,000", value: "onefiftyk" },
      { label: "$150,000 - $250,000", value: "twofiftyk" },
      { label: "$250,000+", value: "overtwofiftyk" },
    ];

    const dropdownRef = useRef();

    // Close dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowPicker(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <Box position="relative" ref={dropdownRef}>
        <Box onClick={() => setShowPicker(!showPicker)} cursor="pointer">
          <InputGroup width="200px" textAlign="center" ml={6}>
            <Input
              cursor="pointer"
              height="40px"
              fontSize="14px"
              readOnly
              value={revenueRanges.find((range) => range.value === revenueGroup)?.label || "Select Revenue Range"}
            />
            <InputRightElement height="100%" display="flex" alignItems="center">
              <ChevronDownIcon boxSize={7} mr={2} />
            </InputRightElement>
          </InputGroup>
        </Box>
        {showPicker && (
          <Card
            textAlign="left"
            p={0}
            zIndex={9999}
            width="200px"
            position="absolute"
            right="10%"
            top="120%"
            backgroundColor="white"
            boxShadow="md"
          >
            <CardBody p={1}>
              <VStack align="stretch" gap={1} p={"10px 5px"}>
                <Text pl={"5px"} fontSize={"14px"}>--- Select Revenue ---</Text>
                {revenueRanges.map(({ label, value }) => (
                  <Box
                    key={value}
                    p={2}
                    cursor="pointer"
                    color={revenueGroup === value ? "#536DEC" : "black"}
                    fontSize={"14px"}
                    onClick={() => {
                      setRevenueGroup(value);
                      setShowPicker(false);
                    }}
                  >
                    {label}
                  </Box>
                ))}
              </VStack>
            </CardBody>
          </Card>
        )}
      </Box>
    );
  };

  export default RevenueDropdown;

