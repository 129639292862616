import React from "react";
import {
  Image,
  Input,
  Box,
  FormControl,
  FormLabel,
  Icon,
  Text,
  FormHelperText,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaImage } from "react-icons/fa";

export const LogoField = ({ name, text, imagePreview, onChange }) => {
  const borderColor = useColorModeValue("blue.500", "blue.700");
  const backgroundColor = useColorModeValue("blue.50", "gray.700");
  return (
    <FormControl id={name} mt={4}>
      <FormLabel>{text}</FormLabel>
      <Box
        borderWidth="2px"
        borderStyle="dashed"
        borderColor={borderColor}
        borderRadius="lg"
        backgroundColor={backgroundColor}
        width="100%"
        maxW="400px"
        height="150px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        cursor="pointer"
        _hover={{ borderColor: "blue.300" }}
        position="relative"
      >
        <Icon as={FaImage} boxSize={10} color="gray.400" />
        <Text mt={2}>
          <Text as="span" color="blue.500">
            Click to upload
          </Text>{" "}
          or drag and drop
        </Text>
        <Text fontSize="sm" color="gray.500">
          Maximum file size of 1 MB
        </Text>
        <Input
          name={name}
          type="file"
          accept="image/*"
          onChange={onChange}
          opacity="0"
          position="absolute"
          width="100%"
          height="100%"
          cursor="pointer"
        />
      </Box>
      <FormHelperText>Choose an image file to upload.</FormHelperText>
      {imagePreview && (
        <Box boxSize="150px" mt={2}>
          <Image
            src={imagePreview}
            alt="Image preview"
            objectFit="cover"
            borderRadius="md"
          />
        </Box>
      )}
    </FormControl>
  );
};
