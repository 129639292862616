import {
  Card,
  CardBody,
  Grid,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  VStack,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MetricCard from "./metricCard";
import ProfitCard from "./profitCard";

import axios from "axios";

import MetricExpenseCard from "./metricExpenseCard";
import {
  customCurrencyFormat,
  colorFormat,
  colorExpenseFormat,
} from "../../utils";
import MetricGraph from "./metricGraph";
import NotificationContainer from "../generic/updateNotification";

const MetricView = ({
  customer_id,
  dashDate,
  trigger,
  triggerSetter,
  triggerExpenseBreakdown,
  setBreakdownData,
}) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [metricData, setMetricData] = useState({
    income: "0.00",
    income_forecast: "0.00",
    income_forecast_percentage: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    expense_forecast_percentage: "0.00",
    profit: "0.00",
    cogs: "0.00",
    cogs_percentage: "0.00",
    marketing: "0.00",
    marketing_percentage: "0.00",
    team: "0.00",
    team_percentage: "0.00",
    overhead: "0.00",
    overhead_percentage: "0.00",
    profit_percentage: "0.00",
    ytd_income: "0.00",
    ytd_expense: "0.00",
    ytd_income_percentage: "0.00",
    ytd_expense_percentage: "0.00",
  });

  const [metricGraphData, setMetricGraphData] = useState({
    income: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    expense: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    profit: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    months: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  });

  const [pLData, setPLData] = useState({
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshMetricData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/metricview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setMetricData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshMetricData(customer_id);
    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshMetricGraphData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/metricgraph/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setMetricGraphData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshMetricGraphData(customer_id);
    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshPLData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/plvalues/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setPLData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshPLData(customer_id);
    triggerSetter(false);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  const [isMobile] = useMediaQuery("(max-width: 1280px)", { ssr: false });

  return (
    <Stack marginBottom={"10px"} pt={isMobile ? "185px" : "120px"}>
      <NotificationContainer rounded={true} p={"0px 20px 0px"} />
      <Stack p="5px 20px">
        {!isMobile && (
          <Grid
            templateColumns="2fr 275px 2fr"
            spacing={2}
            p="10px 0px 10px 0px"
            alignItems="center"
            justifyItems="center"
          >
            <Stack
              width="100%"
              align="center"
              gridColumn={1}
              gridRow={"span 2"}
            >
              <MetricCard
                title="Total Income"
                amount={customCurrencyFormat(
                  metricData ? metricData.income : 0.0
                )}
                ytd={customCurrencyFormat(
                  metricData ? metricData.ytd_income : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  metricData ? metricData.ytd_income_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  metricData ? metricData.income_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  metricData ? metricData.income_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  metricData ? metricData.income_forecast_percentage : "0.00"
                )}
                ytd_color={colorFormat(
                  metricData ? metricData.ytd_income_percentage : "0.00"
                )}
                image={"/money-up-arrow.png"}
                breakdownTitle="Total Income"
                type="income"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>

            <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
              <ProfitCard
                profit={customCurrencyFormat(
                  metricData ? metricData.profit_percentage : 0.0,
                  true
                )}
                profitPercentage={customCurrencyFormat(
                  metricData ? metricData.profit : 0.0
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.profit_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.profit_pl : "0.00",
                  metricData ? metricData.profit_percentage : "0.00",
                  true,
                  true
                )}
                status={true}
                data={metricData ? metricData : {}}
              />
            </Stack>
            <Stack
              width="100%"
              align="center"
              gridColumn={3}
              gridRow={"span 2"}
            >
              <MetricCard
                title="Total Expenses"
                amount={customCurrencyFormat(
                  metricData ? metricData.expense : 0.0
                )}
                ytd={customCurrencyFormat(
                  metricData ? metricData.ytd_expense : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  metricData ? metricData.ytd_expense_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  metricData ? metricData.expense_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  metricData ? metricData.expense_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  metricData ? metricData.expense_forecast_percentage : "0.00",
                  true
                )}
                ytd_color={colorFormat(
                  metricData ? metricData.ytd_expense_percentage : "0.00",
                  true
                )}
                image={"/money-down-arrow.png"}
                breakdownTitle="Total Expense"
                type="expense"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>
          </Grid>
        )}
        {isMobile && (
          <VStack>
            <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
              <ProfitCard
                profit={customCurrencyFormat(
                  metricData ? metricData.profit_percentage : 0.0,
                  true
                )}
                profitPercentage={customCurrencyFormat(
                  metricData ? metricData.profit : 0.0
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.profit_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.profit_pl : "0.00",
                  metricData ? metricData.profit_percentage : "0.00",
                  true,
                  true
                )}
              />
            </Stack>

            <Stack
              width="100%"
              align="center"
              gridColumn={1}
              gridRow={"span 2"}
            >
              <MetricCard
                title="Total Income"
                amount={customCurrencyFormat(
                  metricData ? metricData.income : 0.0
                )}
                ytd={customCurrencyFormat(
                  metricData ? metricData.ytd_income : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  metricData ? metricData.ytd_income_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  metricData ? metricData.income_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  metricData ? metricData.income_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  metricData ? metricData.income_forecast_percentage : "0.00"
                )}
                ytd_color={colorFormat(
                  metricData ? metricData.ytd_income_percentage : "0.00"
                )}
                image={"/money-up-arrow.png"}
                breakdownTitle="Total Income"
                type="income"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>

            <Stack
              width="100%"
              align="center"
              gridColumn={3}
              gridRow={"span 2"}
            >
              <MetricCard
                title="Total Expenses"
                amount={customCurrencyFormat(
                  metricData ? metricData.expense : 0.0
                )}
                ytd={customCurrencyFormat(
                  metricData ? metricData.ytd_expense : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  metricData ? metricData.ytd_expense_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  metricData ? metricData.expense_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  metricData ? metricData.expense_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  metricData ? metricData.expense_forecast_percentage : "0.00",
                  true
                )}
                ytd_color={colorFormat(
                  metricData ? metricData.ytd_expense_percentage : "0.00",
                  true
                )}
                image={"/money-down-arrow.png"}
                breakdownTitle="Total Expense"
                type="expense"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Stack>
          </VStack>
        )}
        <Card
          height="320px"
          maxHeight={"100%"}
          width="100%"
          borderRadius={"12px"}
          marginBottom={"10px"}
        >
          <CardBody height="40%" p={"24px 34px 70px 34px "}>
            <HStack wrap="wrap" pb={"10px"}>
              <Text
                fontSize={"clamp(13px, calc(1.6cqw - 0.09vw), 16px)"}
                fontWeight={500}
              >
                12-Month Income, Expenses and Profit
              </Text>
              <Spacer />
              <HStack>
                <Box
                  backgroundColor={"#458CFF"}
                  height={"15px"}
                  width={"15px"}
                  borderRadius={"5px"}
                ></Box>
                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Income
                </Text>
                <Box
                  backgroundColor={"#FF5A5C"}
                  height={"15px"}
                  width={"15px"}
                  borderRadius={"5px"}
                ></Box>

                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Expenses
                </Text>
                <Box
                  backgroundColor={"#00D26C"}
                  height={"4px"}
                  width={"20px"}
                  borderRadius={"5px"}
                ></Box>
                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Profit
                </Text>
              </HStack>
            </HStack>
            <MetricGraph metricGraphData={metricGraphData} />
          </CardBody>
        </Card>
        {!isMobile && (
          <SimpleGrid
            templateColumns="1fr 1fr 1fr 1fr"
            spacing={6}
            maxWidth={"100%"}
          >
            <MetricExpenseCard
              title="Team"
              total={customCurrencyFormat(metricData ? metricData.team : 0.0)}
              percentage={customCurrencyFormat(
                metricData ? metricData.team_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.team_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.team_pl : "0.00",
                metricData ? metricData.team_percentage : "0.00"
              )}
              type="team"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <MetricExpenseCard
              title="Marketing"
              total={customCurrencyFormat(
                metricData ? metricData.marketing : 0.0
              )}
              percentage={customCurrencyFormat(
                metricData ? metricData.marketing_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.marketing_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.marketing_pl : "0.00",
                metricData ? metricData.marketing_percentage : "0.00"
              )}
              type="marketing"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <MetricExpenseCard
              title="Overhead"
              total={customCurrencyFormat(
                metricData ? metricData.overhead : 0.0
              )}
              percentage={customCurrencyFormat(
                metricData ? metricData.overhead_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.overhead_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.overhead_pl : "0.00",
                metricData ? metricData.overhead_percentage : "0.00"
              )}
              type="overhead"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
            <MetricExpenseCard
              title="Products (COGS)"
              total={customCurrencyFormat(metricData ? metricData.cogs : 0.0)}
              percentage={customCurrencyFormat(
                metricData ? metricData.cogs_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.cogs_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.cogs_pl : "0.00",
                metricData ? metricData.cogs_percentage : "0.00"
              )}
              type="cogs"
              triggerExpenseBreakdown={triggerExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          </SimpleGrid>
        )}{" "}
        {isMobile && (
          <VStack width="100%" spacing={4} align="stretch">
            <Grid
              width="100%"
              templateColumns={{
                base: "1fr", // Single column for smaller screens
                md: "repeat(2, 1fr)", // Two columns for medium screens
              }}
              gap={4} // Adds spacing between grid items
            >
              <MetricExpenseCard
                title="Team"
                total={customCurrencyFormat(metricData ? metricData.team : 0.0)}
                percentage={customCurrencyFormat(
                  metricData ? metricData.team_percentage : 0.0,
                  true
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.team_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.team_pl : "0.00",
                  metricData ? metricData.team_percentage : "0.00"
                )}
                type="team"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
              <MetricExpenseCard
                title="Marketing"
                total={customCurrencyFormat(
                  metricData ? metricData.marketing : 0.0
                )}
                percentage={customCurrencyFormat(
                  metricData ? metricData.marketing_percentage : 0.0,
                  true
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.marketing_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.marketing_pl : "0.00",
                  metricData ? metricData.marketing_percentage : "0.00"
                )}
                type="marketing"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
              <MetricExpenseCard
                title="Overhead"
                total={customCurrencyFormat(
                  metricData ? metricData.overhead : 0.0
                )}
                percentage={customCurrencyFormat(
                  metricData ? metricData.overhead_percentage : 0.0,
                  true
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.overhead_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.overhead_pl : "0.00",
                  metricData ? metricData.overhead_percentage : "0.00"
                )}
                type="overhead"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
              <MetricExpenseCard
                title="Products (COGS)"
                total={customCurrencyFormat(metricData ? metricData.cogs : 0.0)}
                percentage={customCurrencyFormat(
                  metricData ? metricData.cogs_percentage : 0.0,
                  true
                )}
                perfectPL={customCurrencyFormat(
                  pLData ? pLData.cogs_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  pLData ? pLData.cogs_pl : "0.00",
                  metricData ? metricData.cogs_percentage : "0.00"
                )}
                type="cogs"
                triggerExpenseBreakdown={triggerExpenseBreakdown}
                setBreakdownData={setBreakdownData}
              />
            </Grid>
          </VStack>
        )}
      </Stack>
    </Stack>
  );
};

export default MetricView;
