import {
  Card,
  Grid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IndustryInsightView from "./industryInsights";
import RevenueDropdown from "./revenueDropdown";

const IndustryInsightsSelection = ({
  customer_id,
  revenueGroup,
  setRevenueGroup,
  setIsInsights,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [tab, setTab] = useState("topOne");

  useEffect(() => {
    setIsInsights(true);
  }, [setIsInsights]);

  return (
    <Stack>
      <Card
        borderRadius={0}
        h="48px"
        borderBottom="1px solid #e0e0e0"
        mt={isMobile ? "122px" : "72px"}
        zIndex={300}
        position={"fixed"}
        width={isMobile ? "100vw" : "auto"}
      >
        <Tabs>
          <Grid
            templateColumns={isMobile ? "1fr" : "1fr 2fr 9fr"}
            alignItems="center" /* Vertically center all children */
            p={!isMobile ? "0px 20px 0px" : "0px 10px 0px"}
            height="48px" /* Ensure Grid has a defined height */
          >
            <TabList
              display="flex"
              alignItems="center"
              height="50px"
              overflowX={"auto"}
              minW={!isMobile ? "500px" : "auto"}
              width={isMobile ? "100%" : "auto"}
              sx={{
                overflowX: "auto",
                "::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                }}
                cursor="pointer"
                onClick={() => setTab("topOne")}
                _active={{ background: "none" }}
                h="100%" /* Matches parent height */
                p={0}
                display="flex"
                alignItems="center" /* Vertically center text */
                justifyContent="center" /* Horizontally center text */
                whiteSpace="nowrap"
                m={0}
              >
                <Text
                  fontWeight="400"
                  fontSize={isMobile ? "13px" : "16px"}
                  margin="0"
                >
                  Top 1% Performers
                </Text>
              </Tab>
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                }}
                cursor="pointer"
                onClick={() => setTab("topTen")}
                _active={{ background: "none" }}
                width={isMobile ? "140px" : "160px"}
                h="100%" /* Matches parent height */
                p={0}
                display="flex"
                alignItems="center" /* Vertically center text */
                justifyContent="center" /* Horizontally center text */
                whiteSpace="nowrap"
                m={0}
              >
                <Text
                  fontWeight="400"
                  fontSize={isMobile ? "13px" : "16px"}
                  margin="0"
                  p={"0px 8px"}
                >
                  Top 10% Performers
                </Text>
              </Tab>
              <Tab
                _focus={{ outline: "none", border: "none", boxShadow: "none" }}
                _selected={{
                  borderBottom: "3px solid #536DEC",
                  color: "#536DEC",
                }}
                cursor="pointer"
                onClick={() => setTab("average")}
                _active={{ background: "none" }}
                h="100%" /* Matches parent height */
                p={0}
                display="flex"
                alignItems="center" /* Vertically center text */
                justifyContent="center" /* Horizontally center text */
                whiteSpace="nowrap"
                m={0}
              >
                <Text
                  fontWeight="400"
                  fontSize={isMobile ? "13px" : "16px"}
                  margin="0"
                >
                  Average Performers
                </Text>
              </Tab>
            </TabList>

            <Stack
              gridColumn={9}
              textAlign="right"
              justifyContent="center" /* Vertically center content */
            >
              {!isMobile && (
                <RevenueDropdown
                  revenueGroup={revenueGroup}
                  setRevenueGroup={setRevenueGroup}
                />
              )}
            </Stack>
          </Grid>
        </Tabs>
      </Card>
      {tab === "topOne" ? (
        <IndustryInsightView
          customer_id={customer_id}
          performanceGroup={"one"}
          revenueGroup={revenueGroup}
        />
      ) : tab === "topTen" ? (
        <IndustryInsightView
          customer_id={customer_id}
          performanceGroup={"ten"}
          revenueGroup={revenueGroup}
        />
      ) : (
        <IndustryInsightView
          customer_id={customer_id}
          performanceGroup={"average"}
          revenueGroup={revenueGroup}
        />
      )}
    </Stack>
  );
};

export default IndustryInsightsSelection;
