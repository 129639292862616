import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

// import borders from './foundations/borders'
import Button from "./components/button";
import { cardTheme } from "./components/card";
import Icon from "./components/icon";
import { tableTheme } from "./components/table";
import { menuTheme } from "./components/menu";

// Colors brand note:
// I just added all the default purple colors with a comment on what they are being
// used for, as we add colors we should keep this updated.

const overrides = {
  styles: {
    global: {
      body: {
        fontFamily: "Lato",
      },
    },
  },
  colors: {
    brand: {
      50: "#EDF3FF", // not used
      100: "#E3EDFF", // not used
      200: "#97B1FF", // not used
      300: "#87A1FF", // button
      400: "#6D87FF", // not used
      500: "#627CFB", // not used
      600: "#536DEC", // not used
      700: "#455FDE", // not used
      800: "#3852D1", // not used
      900: "#233DBC",
      1000: "#2D3748", // This is the header and sidebar color from figma
    },
  },
  // borders,
  components: {
    Button,
    Icon,
    Table: tableTheme,
    Menu: menuTheme,
    Card: cardTheme,
  },
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "1024px",
    xl: "1200px",
    xxsm: "375px", // Add a custom breakpoint
  },
};

export default extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: "brand" })
);
