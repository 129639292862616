import {
  Box,
  Card,
  CardBody,
  Image,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { getBackgroundColor } from "../../utils";

const InsightCard = ({
  title = "",
  amount = "0.00",
  image = null,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 375px)");
  return (
    <Stack width="100%" align="center" p="10px 0px" fontFamily={"lato"}>
      <Card
        width="100%"
        height={"195px"}
        borderRadius="10px"
        position="relative"
      >
        <CardBody padding={"16px 24px"}>
          <VStack align={"center"} gap={0} spacing={0}>
            <Box
              backgroundColor={!isMobile && getBackgroundColor(image)}
              borderRadius="15px"
              width="80px"
              height="80px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src={image}
                alt="Top Right Icon"
                boxSize="50px"
                objectFit="contain"
              />
            </Box>
            <Text fontWeight={600} fontSize={"16px"} margin={0} color={"#202224"} pt={"5px"}>
              {title}
            </Text>
            <Text fontSize={"36px"} fontWeight={800} margin={0}>
              {amount}
            </Text>
          </VStack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default InsightCard;
