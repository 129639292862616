import { get } from "./api";

export const fetchAccounts = () => {
  return get("/api/v2/accounts/");
};

export const fetchCustomerAccounts = (
  customer_id,
  params = {},
  dropdown_format = false
) => {
  return get(
    `/api/v2/customers/${customer_id}/accounts/`,
    params,
    dropdown_format
  );
};

export const fetchAccountTypes = () => {
  return get("/api/v2/accounttypes/", {}, true);
};

export const fetchAccountSubTypes = () => {
  return get("/api/v2/accountsubtypes/", {}, false).then((data) =>
    data.map((o) => ({ value: o.id, label: o.name, type: o.type }))
  );
};

export const fetchAccountTaxTypes = (set) => {
  return get("/api/v2/accounttaxtypes/", {}, true);
};
