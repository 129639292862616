import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { customCurrencyFormat, DropDownIcon } from "../../utils";
import Scrollbar from "../generic/scrollBar";
import HorizontalScrollbar from "../generic/scrollBarX";
import { ExpenseGraph } from "./ExpenseGraph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  LineController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ExpenseBreakdown = ({
  customer_id,
  dashDate,
  setExpenseBreakdown,
  title,
  type,
  setTab,
  selectedPeriod,
  setSelectedPeriod,
  originalTab = "performanceReview",
}) => {
  const formattedDate = dashDate.toISOString().split("T")[0];
  const [metricGraphData, setMetricGraphData] = useState({
    values: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
    date_interval: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
  });

  const contentRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [hasXOverflow, setHasXOverflow] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [vendorData, setVendorData] = useState({});
  const [vendorYearData, setVendorYearData] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshVendorData = async (id) => {
      if (id) {
        if (type) {
          try {
            let endpoint;
            if (selectedPeriod === "month") {
              endpoint = `/api/v2/customers/${id}/vendorbreakdown/${formattedDate}/${
                type === "overhead" ? "overheadFull" : type
              }`;
            } else {
              endpoint = `/api/v2/customers/${id}/vendorexpensebreakdown/${formattedDate}/${
                type === "overhead" ? "overheads" : type
              }`;
            }

            const res = await axios.get(endpoint, {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            });

            if (isActive) {
              if (selectedPeriod === "month") {
                setVendorData(res.data);
              } else {
                setVendorYearData(res.data);
              }
            }
          } catch (err) {
            if (err?.response) {
              console.log(err);
            }
          }
        } else {
          setVendorData({});
        }
      } else {
        setVendorData({});
      }
    };

    refreshVendorData(customer_id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, formattedDate, type, selectedPeriod]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
      });
    }

    const checkOverflow = () => {
      if (contentRef.current && scrollContainerRef.current) {
        const hasOverflowX =
          contentRef.current.scrollWidth > contentRef.current.clientWidth;
        setHasXOverflow(hasOverflowX);

        if (hasOverflowX) {
          const contentWidth = contentRef.current.scrollWidth;
          const visibleWidth = scrollContainerRef.current.clientWidth;

          const thumbWidth = (visibleWidth / contentWidth) * 100;
          setScrollWidth(thumbWidth);
        } else {
          setScrollWidth(0); // Reset scroll width if no overflow
        }
      }
    };

    checkOverflow();

    window.addEventListener("resize", checkOverflow);

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [vendorData, selectedPeriod]);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const hasOverflowY =
          contentRef.current.scrollHeight > contentRef.current.clientHeight;
        setHasOverflow(hasOverflowY);

        if (hasOverflowY) {
          const contentHeight = contentRef.current.scrollHeight;
          const visibleHeight = contentRef.current.clientHeight;
          setScrollHeight((visibleHeight / contentHeight) * 100); // Set scrollbar thumb height as a percentage
        } else {
          setScrollHeight(0); // Reset thumb height if no overflow
        }
      }
    };

    // Run checkOverflow initially
    checkOverflow();

    // Observe changes to the contentRef's children
    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    // Recalculate on window resize
    window.addEventListener("resize", checkOverflow);

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", checkOverflow);
    };
  }, [vendorData, selectedPeriod]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshMetricGraphData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/expenseoverviewgraph/${type}/${selectedPeriod}/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setMetricGraphData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshMetricGraphData(customer_id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, formattedDate, selectedPeriod, type]);

  const [isMobile] = useMediaQuery("(max-width: 1280px)", { ssr: false });
  const [shiftToggle] = useMediaQuery("(max-width: 720px)", { ssr: false });
  return (
    <Stack>
      {!shiftToggle && (
        <Card
          position={"relative"}
          mt={isMobile ? "72px" : "0px"}
          borderRadius={0}
          width={"100%"}
          h="75px"
          zIndex={300}
          borderBottom="1px solid #e0e0e0"
        >
          <SimpleGrid
            templateColumns={isMobile ? "1fr" : "2fr 2fr 2fr 2fr"}
            top={"50%"}
            width={"100%"}
            maxW={"100%"}
            padding={"20px"}
          >
            <Box
              gridRow={1}
              gridColumn={1}
              textAlign={"left"}
              fontWeight={500}
              fontSize={"24px"}
            >
              {" "}
              {title} Breakdown
            </Box>
            <Box gridRow={1} gridColumn={4} textAlign={"right"}>
              {!isMobile && (
                <ButtonGroup isAttached variant="outline">
                  <Button
                    _hover={{
                      background: "initial",
                      boxShadow: "none",
                      opacity: "100%",
                      backgroundColor:
                        selectedPeriod === "month" ? "#536DEC" : "white",
                    }}
                    backgroundColor={
                      selectedPeriod === "month" ? "#536DEC" : "white"
                    }
                    textColor={selectedPeriod === "month" ? "white" : "#536DEC"}
                    onClick={() => setSelectedPeriod("month")}
                    border={"1px solid #536DEC"}
                    width={"195px"}
                  >
                    Current Month
                  </Button>
                  <Button
                    _hover={{
                      background: "initial",
                      boxShadow: "none",
                      opacity: "100%%",
                      backgroundColor:
                        selectedPeriod === "year" ? "#536DEC" : "white",
                    }}
                    backgroundColor={
                      selectedPeriod === "year" ? "#536DEC" : "white"
                    }
                    textColor={selectedPeriod === "year" ? "white" : "#536DEC"}
                    onClick={() => setSelectedPeriod("year")}
                    border={"1px solid #536DEC"}
                    width={"195px"}
                  >
                    12 - Month Breakdown
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </SimpleGrid>
        </Card>
      )}
      <Stack p={"5px 20px"}>
        <Stack align={"center"} mt={shiftToggle ? "77px" : "0"}>
          <Box width="100%">
            <Text
              textColor={"#536DEC"}
              cursor={"pointer"}
              fontSize={"16px"}
              width={"180px"}
              mb={"15px"}
              mt={"10px"}
              onClick={() => {
                setExpenseBreakdown(false);
                setTab(originalTab);
                setSelectedPeriod("month");
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faLongArrowLeft} strokeWidth={0.1} /> Back
              to
              {originalTab === "performanceReview"
                ? " Metric View"
                : " Growth Plan"}
            </Text>
            {shiftToggle && (
              <ButtonGroup
                isAttached
                variant="outline"
                mb={"20px"}
                width={"100%"}
              >
                <Button
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%",
                    backgroundColor:
                      selectedPeriod === "month" ? "#536DEC" : "white",
                  }}
                  backgroundColor={
                    selectedPeriod === "month" ? "#536DEC" : "white"
                  }
                  width={"50%"}
                  textColor={selectedPeriod === "month" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("month")}
                  border={"1px solid #536DEC"}
                >
                  Current Month
                </Button>
                <Button
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%%",
                    backgroundColor:
                      selectedPeriod === "year" ? "#536DEC" : "white",
                  }}
                  width={"50%"}
                  backgroundColor={
                    selectedPeriod === "year" ? "#536DEC" : "white"
                  }
                  textColor={selectedPeriod === "year" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("year")}
                  border={"1px solid #536DEC"}
                >
                  12 - Month
                </Button>
              </ButtonGroup>
            )}
            <Card height={"300px"} width={"100%"}>
              <CardHeader paddingBottom={0}>
                <SimpleGrid templateColumns={"2fr 2fr"}>
                  <Text fontSize={"16px"} textAlign={"left"}>{`${
                    selectedPeriod === "year"
                      ? "12-Month"
                      : dashDate.toLocaleString("default", {
                          month: "long",
                        })
                  } ${title} ${
                    title !== "Total Income" && title !== "Total Expense"
                      ? "Expense Trend"
                      : "Trend"
                  }`}</Text>
                  <Box textAlign={"right"} position={"relative"}>
                    <Flex
                      alignItems="center"
                      justifyContent="flex-end"
                      position={"absolute"}
                      bottom={0}
                      right={0}
                    >
                      <Box
                        width="18px"
                        borderRadius={"5px"}
                        height="5px"
                        padding={"0 4px"}
                        backgroundColor="#FF5A5C"
                        marginRight="5px"
                      />
                      <Text fontSize={"14px"}>Expense Trend Line</Text>
                    </Flex>
                  </Box>
                </SimpleGrid>
              </CardHeader>
              <CardBody height={"200px"}>
                <ExpenseGraph
                  metricGraphData={metricGraphData}
                  selectedPeriod={selectedPeriod}
                  dashDate={dashDate}
                />
              </CardBody>
            </Card>
          </Box>
        </Stack>
        <Stack align={"center"} pt={2} pb={2}>
          <Flex flexDirection={"column"} position="relative" width="100%">
            <Card
              borderRadius="12px"
              outlineColor={"gray.200"}
              marginRight={hasOverflow ? "20px" : 0}
            >
              <TableContainer
                ref={contentRef}
                maxHeight="424px"
                borderRadius="12px"
                position="relative"
                overflowY={"auto"}
                sx={{
                  "::-webkit-scrollbar": { display: "none" },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
                onWheel={(e) => e.stopPropagation()}
              >
                {selectedPeriod === "month" ? (
                  <Table
                    variant="unstyled"
                    bg="white"
                    padding={0}
                    rounded={"12px"}
                  >
                    <Thead position="sticky" top={0} zIndex={3} bg="#1A202C">
                      <Tr
                        fontSize="18px"
                        sx={{
                          "& td": {
                            color: "white",
                            fontWeight: "200",
                          },
                        }}
                      >
                        <Td>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text textAlign={"left"} mr={4} fontWeight={700}>
                              {" "}
                              Vendor
                            </Text>
                            <DropDownIcon />
                          </Flex>
                        </Td>
                        <Td>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text textAlign={"left"} mr={4} fontWeight={700}>
                              {" "}
                              Amount
                            </Text>
                            <DropDownIcon />
                          </Flex>
                        </Td>
                      </Tr>
                    </Thead>

                    <Tbody zIndex={1}>
                      {vendorData?.vendors?.length > 0 ? (
                        vendorData.vendors.map((vendor) => (
                          <Tr key={vendor.id} height={"40px"}>
                            <Td
                              border="1px solid"
                              borderColor="gray.200"
                              p={"10px 26px"}
                            >
                              <HStack>
                                <Flex align="center" justify="center">
                                  {vendor.id !== "uncategorized" && (
                                    <Image
                                      src={
                                        vendor.logo ||
                                        `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                      }
                                      alt={vendor.name || "Placeholder"}
                                      boxSize="29px"
                                      objectFit="cover"
                                      borderRadius="10px"
                                      mr={"2"}
                                    />
                                  )}
                                  {vendor.name}
                                </Flex>
                              </HStack>
                            </Td>
                            <Td
                              border="1px solid"
                              backgroundColor={"#F6F8FE"}
                              borderColor="gray.200"
                            >
                              {customCurrencyFormat(
                                vendor.amount,
                                false,
                                true,
                                true,
                                true
                              )}
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr
                          border="1px solid"
                          borderColor="gray.200"
                          height={"20px"}
                        >
                          <Td colSpan={2} textAlign="center">
                            No vendor data.
                          </Td>
                        </Tr>
                      )}
                    </Tbody>

                    <Tfoot position="sticky" bottom={0} zIndex={3} bg="#1A202C">
                      <Tr
                        fontSize="18px"
                        sx={{
                          "& td": {
                            color: "white",
                            fontWeight: "200",
                          },
                        }}
                      >
                        <Td>Total</Td>
                        <Td>
                          {customCurrencyFormat(
                            vendorData?.total ? vendorData.total : "0",
                            false,
                            true,
                            true,
                            true
                          )}
                        </Td>
                      </Tr>
                    </Tfoot>
                  </Table>
                ) : (
                  <Table variant="unstyled" bg="white" padding={0}>
                    <Thead position="sticky" top={0} bg="#1A202C" zIndex={5}>
                      <Tr
                        fontSize="18px"
                        sx={{
                          "& td": {
                            color: "white",
                            fontWeight: "200",
                          },
                        }}
                      >
                        <Td
                          textAlign="left"
                          position={"sticky"}
                          bg="#1A202C"
                          left={0}
                          zIndex={4}
                        >
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text textAlign={"left"} mr={4} fontWeight={700}>
                              {" "}
                              Vendor
                            </Text>
                            <DropDownIcon />
                          </Flex>
                        </Td>
                        {vendorYearData?.months
                          ? vendorYearData.months.map((month, index) => (
                              <Td key={index}>
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Text
                                    textAlign={"left"}
                                    mr={4}
                                    fontWeight={700}
                                  >
                                    {" "}
                                    {month}
                                  </Text>
                                  <DropDownIcon />
                                </Flex>
                              </Td>
                            ))
                          : Array.from({ length: 12 }, (_, index) => (
                              <Td key={index} textAlign="left" fontWeight={700}>
                                {" "}
                                -{" "}
                              </Td>
                            ))}
                        <Td
                          textAlign="left"
                          position={"sticky"}
                          bg="#1A202C"
                          right={0}
                          zIndex={4}
                        >
                          <Text fontWeight={700}>Total</Text>
                        </Td>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {vendorYearData?.vendors &&
                      vendorYearData?.vendors.length > 0 ? (
                        vendorYearData.vendors.map((vendor) => (
                          <Tr
                            key={vendor.id}
                            outline={"1px solid"}
                            outlineColor="gray.200"
                            height={"40px"}
                          >
                            <Td
                              textAlign="left"
                              position={"sticky"}
                              left={0}
                              zIndex={4}
                              backgroundColor={"white"}
                              color={"black"}
                              outline="1px solid"
                              outlineColor="gray.200"
                              padding={"10px"}
                              pl={"20px"}
                              pr={"20px"}
                              width={"100%"}
                            >
                              <HStack>
                                <Flex align="center" justify="center">
                                  {vendor.id !== "uncategorized" && (
                                    <Image
                                      src={
                                        vendor.logo ||
                                        `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                                      }
                                      alt={vendor.name || "Placeholder"}
                                      boxSize="29px"
                                      objectFit="cover"
                                      borderRadius="10px"
                                      mr={"2"}
                                    />
                                  )}

                                  {vendor.name}
                                </Flex>
                              </HStack>
                            </Td>
                            {vendor.monthly_amounts.map((amount, index) => (
                              <Td
                                key={index}
                                textAlign="left"
                                color={"black"}
                                outline="1px solid"
                                outlineColor="gray.200"
                              >
                                {customCurrencyFormat(
                                  amount,
                                  false,
                                  true,
                                  true,
                                  true
                                )}
                              </Td>
                            ))}
                            <Td
                              textAlign="left"
                              position={"sticky"}
                              right={0}
                              backgroundColor={"#F6F8FE"}
                              color={"black"}
                              outline="1px solid"
                              outlineColor="gray.200"
                              zIndex={3}
                            >
                              {customCurrencyFormat(
                                vendor.total,
                                false,
                                true,
                                true,
                                true
                              )}
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td
                            textAlign="left"
                            position={"sticky"}
                            left={0}
                            zIndex={4}
                            backgroundColor={"white"}
                            color={"black"}
                            outline="1px solid"
                            outlineColor="gray.200"
                          >
                            No vendor data
                          </Td>
                          {Array.from({ length: 12 }, (_, index) => (
                            <Td
                              key={index}
                              textAlign="left"
                              color={"black"}
                              outline="1px solid"
                              outlineColor="gray.200"
                            >
                              {" "}
                              -{" "}
                            </Td>
                          ))}
                          <Td
                            textAlign="left"
                            position={"sticky"}
                            right={0}
                            backgroundColor={"#F6F8FE"}
                            color={"black"}
                            outline="1px solid"
                            outlineColor="gray.200"
                            zIndex={3}
                          >
                            -
                          </Td>
                        </Tr>
                      )}
                    </Tbody>

                    <Tfoot
                      bg="#1A202C"
                      fontSize="18px"
                      sx={{
                        "& td": {
                          fontWeight: "200",
                        },
                      }}
                    >
                      {vendorYearData?.total ? (
                        <Tr>
                          <Td
                            textAlign="left"
                            color={"white"}
                            position={"sticky"}
                            bg="#1A202C"
                            left={0}
                            bottom={0}
                            zIndex={4}
                            borderColor="gray.200"
                          >
                            Total
                          </Td>
                          {vendorYearData.total.monthly_amounts.map(
                            (amount, index) => (
                              <Td
                                key={index}
                                textAlign="left"
                                position="sticky"
                                bottom={0}
                                backgroundColor={"#F6F8FE"}
                                color={"black"}
                                outline="1px solid"
                                outlineColor="gray.200"
                              >
                                {customCurrencyFormat(
                                  amount,
                                  false,
                                  true,
                                  true,
                                  true
                                )}
                              </Td>
                            )
                          )}
                          <Td
                            textAlign="left"
                            color={"white"}
                            position={"sticky"}
                            right={0}
                            bottom={0}
                            bg="#1A202C"
                            zIndex={4}
                          >
                            {customCurrencyFormat(
                              vendorYearData.total.total,
                              false,
                              true,
                              true,
                              true
                            )}
                          </Td>
                        </Tr>
                      ) : (
                        <Tr>
                          <Td
                            textAlign="left"
                            color={"white"}
                            position={"sticky"}
                            bg="#1A202C"
                            left={0}
                            bottom={0}
                            zIndex={4}
                            borderColor="gray.200"
                          >
                            Total
                          </Td>
                          {Array.from({ length: 12 }, (_, index) => (
                            <Td
                              key={index}
                              textAlign="left"
                              position="sticky"
                              bottom={0}
                              backgroundColor={"#F6F8FE"}
                              color={"black"}
                              outline="1px solid"
                              outlineColor="gray.200"
                            >
                              {" "}
                              -{" "}
                            </Td>
                          ))}
                          <Td
                            textAlign="left"
                            color={"white"}
                            position={"sticky"}
                            right={0}
                            bottom={0}
                            bg="#1A202C"
                            zIndex={4}
                          >
                            -
                          </Td>
                        </Tr>
                      )}
                    </Tfoot>
                  </Table>
                )}
              </TableContainer>
            </Card>
            {hasOverflow ? (
              <Box>
                <Scrollbar
                  targetRef={contentRef}
                  sx={{
                    position: "absolute",
                    right: 0, // Align to the right
                    top: 0,
                    height: "100%",
                    visibility: hasOverflow ? "visible" : "hidden", // Hide scrollbar if no overflow
                    "&::-webkit-scrollbar-thumb": {
                      height: `${scrollHeight}%`, // Adjust the thumb size based on content height
                      visibility: hasOverflow ? "visible" : "hidden",
                    },
                    "&::-webkit-scrollbar-track": {
                      visibility: hasOverflow ? "visible" : "hidden", // Hide track if no overflow
                    },
                  }}
                />
              </Box>
            ) : (
              <></>
            )}
          </Flex>
          <Box
            position={"relative"}
            width={"100%"}
            marginTop={"10px"}
            ref={scrollContainerRef}
          >
            {hasXOverflow ? (
              <HorizontalScrollbar
                targetRef={contentRef}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: "100%",
                  height: "100%",
                  visibility: hasXOverflow ? "visible" : "hidden",
                  "&::-webkit-scrollbar-thumb": {
                    width: `${scrollWidth}%`,
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                  "&::-webkit-scrollbar-track": {
                    visibility: hasXOverflow ? "visible" : "hidden",
                  },
                }}
              />
            ) : (
              <></>
            )}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ExpenseBreakdown;
