import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { FaFile, FaFileImage, FaTrash } from "react-icons/fa";
import { Context } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";
import { Select } from "chakra-react-select";
import { validateImageDimensions } from "../../utils";

export const ResourcesItems = ({
  customer_id,
  selectedResourceCategory,
  turnBack,
}) => {
  const [loading, setLoading] = useState(true);
  const [resourceList, setResourceList] = useState([]);
  const [initialResource, setInitialResource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});

  const context = React.useContext(Context);

  useEffect(() => {
    const refreshResources = (customer_id) => {
      const url = `/api/v2/customers/${customer_id}/resourcecategories/${selectedResourceCategory}/resourceitems`;
      axios
        .get(
          url,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setResourceList(res.data);
        })
        .catch((err) => console.log(err));
    };

    refreshResources(customer_id);
    setLoading(false);
  }, [customer_id, selectedResourceCategory]);

  const toggle = () => {
    setCurrentCategory({});
    setIsModalOpen(!isModalOpen);
  };

  const startCreateResourceItems = () => {
    setCurrentCategory({});
    setIsModalOpen(true);
  };

  const startUpdateResourceItems = (resourceCategoryData) => {
    setCurrentCategory(resourceCategoryData);
    setIsModalOpen(true);
  };

  const toggleDelete = () => {
    setCurrentCategory({});
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const startDeleteResourceItems = (resourceCategoryData) => {
    setCurrentCategory(resourceCategoryData);
    setIsDeleteModalOpen(true);
  };

  const handleSubmit = async (event, resource) => {
    event.preventDefault();
    setLoading(true);

    if (
      (resource.resource_type === "video" && !resource.external_link) ||
      (resource.resource_type === "pdf" && !resource.resource_file)
    ) {
      context.fireToast("Error", "A resource source must be set", "error");
      setLoading(false);
    } else {
      let url = `/api/v2/customers/${customer_id}/resourcecategories/${selectedResourceCategory}/resourceitems/`;

      const formData = new FormData();
      resource.resource_file &&
        initialResource?.resource_file !== resource.resource_file &&
        formData.append("resource_file", resource.resource_file);

      resource.card_image &&
        initialResource?.card_image !== resource.card_image &&
        formData.append("card_image", resource.card_image);

      formData.append("description", resource.description);
      resource?.external_link &&
        formData.append("external_link", resource.external_link);
      formData.append("parent_category", selectedResourceCategory);
      formData.append("resource_type", resource.resource_type);

      try {
        let res;
        if (resource.id) {
          formData.append("id", resource.id);
          url += `${resource.id}/`;
          res = await axios.patch(url, formData);

          if (res.status < 300) {
            setResourceList(
              resourceList.map((o) => (o.id === res.data.id ? res.data : o))
            );
            context.fireToast(
              "Success",
              "Resource item has been updated",
              "success"
            );
            toggle();
          } else {
            context.fireToast("Error", res.request.responseText, "error");
          }
        } else {
          res = await axios.post(url, formData);

          if (res.status < 300) {
            setResourceList([...resourceList, res.data]);
            context.fireToast("Success", "Resource item added", "success");
            toggle();
          } else {
            context.fireToast("Error", res.request.responseText, "error");
          }
        }
      } catch (err) {
        console.error("There was an error updating the resource item!", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = (resourceCategoryData) => {
    let url = `/api/v2/customers/${customer_id}/resourcecategories/${selectedResourceCategory}/resourceitems/`;

    url += `${resourceCategoryData.id}/`;

    axios
      .delete(url)
      .then((res) => {
        if (res.status < 300) {
          setResourceList(
            resourceList.filter((o) => o.id !== resourceCategoryData.id)
          );
          context.fireToast(
            "Success",
            "Resource category has been deleted",
            "success"
          );
        } else {
          context.fireToast("Error", res.request.responseText, "error");
        }
        setLoading(false);
        toggleDelete();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <>
        {loading && (
          <FontAwesomeIcon
            className="float-end fa-spin"
            icon={faSpinner}
            color="green"
          />
        )}
        <Stack direction="column" spacing={2} align="center">
          <CustomTableContainer width="100%">
            <ResourceModal
              isOpen={isModalOpen}
              toggle={toggle}
              title="Create Resource"
              resourceCategoryData={currentCategory}
              loading={loading}
              handleSubmit={handleSubmit}
              parentID={selectedResourceCategory}
              setInitialData={setInitialResource}
            />
            <ResourceDeleteModal
              colorScheme="red"
              isOpen={isDeleteModalOpen}
              toggle={toggleDelete}
              resourceCategoryData={currentCategory}
              loading={loading}
              handleDelete={handleDelete}
            />
            <Flex pb="5">
              <Box>
                <Heading as="h3" size="lg">
                  Resource Items
                </Heading>
              </Box>
              <Spacer />
              <Box>
                <Stack direction="row" spacing={6} align="center">
                  <Button
                    onClick={startCreateResourceItems}
                    leftIcon={<AddIcon boxSize={3} />}
                  >
                    Create resource item
                  </Button>
                </Stack>
              </Box>
            </Flex>
            <Box maxHeight="calc(100vh - 350px)" bg="white" overflowY={"auto"}>
              <Table variant="unstyled" overflowY={"auto"}>
                <Thead position="sticky" top={-1} zIndex={1}>
                  <Tr>
                    <Th>Description</Th>
                    <Th>Resource Type</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {resourceList.map((resource) => (
                    <ResourceRow
                      key={resource.id}
                      resourceCategoryData={resource}
                      startUpdateResourceItems={startUpdateResourceItems}
                      startDeleteResourceItems={startDeleteResourceItems}
                    />
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Button mt={"10px"} onClick={() => turnBack(false)}>
              Back to resource categories
            </Button>
          </CustomTableContainer>
        </Stack>
      </>
    </Container>
  );
};

const ResourceRow = ({
  resourceCategoryData,
  startUpdateResourceItems,
  startDeleteResourceItems,
}) => {
  return (
    <>
      <Tr key={resourceCategoryData.id}>
        <Td>{resourceCategoryData.description}</Td>
        <Td>{resourceCategoryData.resource_type}</Td>
        <Td>
          <HStack spacing={2}>
            <Button
              onClick={() => {
                if (resourceCategoryData.resource_type === "video") {
                  window.open(resourceCategoryData.external_link, "_blank");
                } else {
                  window.open(resourceCategoryData.resource_file, "_blank");
                }
              }}
            >
              View resource
            </Button>
            <Button
              onClick={() => startUpdateResourceItems(resourceCategoryData)}
            >
              Edit
            </Button>
            <Button
              colorScheme="red"
              onClick={() => startDeleteResourceItems(resourceCategoryData)}
            >
              Delete
            </Button>
          </HStack>
        </Td>
      </Tr>
    </>
  );
};

const ResourceDeleteModal = ({
  isOpen,
  toggle,
  resourceCategoryData,
  loading,
  handleDelete,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Resource</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete this resource item with description:{" "}
          {resourceCategoryData.description}?
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            onClick={() => handleDelete(resourceCategoryData)}
            isDisabled={loading}
          >
            {loading ? "Processing..." : "Delete"}
          </Button>
          <Button onClick={toggle} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ResourceModal = ({
  isOpen,
  toggle,
  title,
  resourceCategoryData,
  loading,
  handleSubmit,
  parentID,
  setInitialData,
}) => {
  const confirmText = !resourceCategoryData.id ? "Create" : "Update";
  const [resourceData, setResourceData] = useState({});
  const context = React.useContext(Context);
  const [fileAttached, setFileAttached] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const fileTypes = useMemo(
    () => [
      { value: "video", label: "VIDEO" },
      { value: "pdf", label: "PDF" },
    ],
    []
  );

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      context.fireToast(
        "Error",
        "No file selected. Please upload a file.",
        "error"
      );
      return;
    }

    // Validate the file type
    if (selectedFile.type !== "application/pdf") {
      context.fireToast("Error", "Only PDF formats are allowed.", "error");
      return;
    }

    setResourceData({
      ...resourceData,
      resource_file: selectedFile,
    });
    setFileAttached(selectedFile?.name || null);
  };

  const handleRemoveFile = () => {
    setResourceData({ ...resourceData, resource_file: "" });
    setFileAttached(null);
  };

  useEffect(() => {
    setResourceData({
      ...resourceCategoryData,
      description: resourceCategoryData.description,
    });
    setInitialData({ ...resourceCategoryData });
    setFileAttached(resourceCategoryData.resource_file);
    setPreview(resourceCategoryData.card_image)
  }, [resourceCategoryData, setInitialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setResourceData({
      ...resourceData,
      [name]: value,
    });
  };

  useEffect(() => {
    setSelectedType(
      fileTypes.find((option) => option.value === resourceData.resource_type)
    );
  }, [resourceData, fileTypes]);

  const [preview, setPreview] = useState(null);

  const handleImageChange = (e) => {
      const file = e.target.files[0];

      if (file) {
        const maxSize = 2 * 1024 * 1024;
        if (file.size > maxSize) {
          context.fireToast(
            "Error",
            "File size exceeds 2MB. Please upload a smaller file.",
            "error"
          );
          return;
        }

        const validDimensions = { width: 846, height: 370 };
        const isValid = validateImageDimensions(file, validDimensions);
        if (!isValid) {
          context.fireToast(
            "Error",
            `Image dimensions must not exceed ${validDimensions.width}x${validDimensions.height} pixels.`,
            "error"
          );
          return;
        }

        const validTypes = [
          "image/svg+xml",
          "image/png",
          "image/jpeg",
          "image/gif",
        ];

        if (!validTypes.includes(file.type)) {
          context.fireToast(
            "Error",
            "Unsupported file type. Please upload an SVG, PNG, JPEG, or GIF.",
            "error"
          );

          return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
          setPreview(event.target.result);
        };
        reader.readAsDataURL(file);

        setResourceData((prev) => ({
          ...prev,
          card_image: file,
        }));
      }
    };

  const removeImage = () => {
    setPreview(null);
  };

  return (
    <Modal isOpen={isOpen} onClose={toggle} parentID={parentID}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody minHeight={"400px"} maxHeight={"600px"} overflowY="auto">
          <form
            id="ResourceForm"
            onSubmit={(e) => handleSubmit(e, resourceData)}
          >
            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                Item description
              </Text>
              <Input
                type="text"
                name="description"
                placeholder="Please enter the resource description."
                defaultValue={resourceData.description || ""}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                Card Background Image
              </Text>
              <Box
                borderWidth="2px"
                borderStyle="dashed"
                borderColor="gray"
                borderRadius="lg"
                backgroundColor="white"
                width="100%"
                maxW="400px"
                height="200px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                cursor="pointer"
                _hover={{ borderColor: "blue.300" }}
                position="relative"
                overflow="hidden"
              >
                {preview ? (
                  <Image
                    src={preview}
                    alt="Uploaded preview"
                    maxH="100%"
                    maxW="100%"
                    objectFit="contain"
                    borderRadius="md"
                  />
                ) : (
                  <>
                    <Icon as={FaFileImage} boxSize={10} color="gray.400" />
                    <Text mt={2}>
                      <Text as="span" fontWeight="bold" color="blue.500">
                        Click to upload
                      </Text>{" "}
                      or drag and drop
                    </Text>
                  </>
                )}
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  opacity="0"
                  position="absolute"
                  width="100%"
                  height="100%"
                  cursor="pointer"
                  name="card_image"
                  required
                />
              </Box>
              <FormHelperText>Choose an image to upload.</FormHelperText>
              {preview && (
                <Button
                leftIcon={<FaTrash />}
                colorScheme="red"
                variant="outline"
                mt={2}
                onClick={removeImage}
              >
                Remove File
              </Button>
              )}
            </FormControl>
            <FormControl>
              <Text
                fontSize="20px"
                textDecoration={"underline"}
                padding={"10px 0px"}
              >
                Resource type
              </Text>
              <Select
                options={fileTypes}
                name="resource_type"
                placeholder="Please select a resource type."
                value={selectedType}
                onChange={(e) => {
                  setResourceData({
                    ...resourceData,
                    resource_type: e.value,
                  });
                }}
                useBasicStyles
              />
            </FormControl>

            {resourceData.resource_type === "video" && (
              <FormControl>
                <Text
                  fontSize="19px"
                  textDecoration={"underline"}
                  padding={"10px 0px"}
                >
                  External link source
                </Text>
                <Input
                  type="url"
                  name="external_link"
                  placeholder="Please enter the external link to resource"
                  defaultValue={resourceData.external_link || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            )}

            {resourceData.resource_type === "pdf" && (
              <FormControl id="resource_file">
                <Text
                  fontSize="20px"
                  textDecoration={"underline"}
                  padding={"10px 0px"}
                >
                  Upload file source
                </Text>
                <Box
                  borderWidth="2px"
                  borderStyle="dashed"
                  borderColor={"gray"}
                  borderRadius="lg"
                  backgroundColor={"white"}
                  width="100%"
                  maxW="400px"
                  height="150px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  cursor="pointer"
                  _hover={{ borderColor: "blue.300" }}
                  position="relative"
                >
                  <Icon as={FaFile} boxSize={10} color="gray.400" />
                  <Text mt={2}>
                    <Text as="span" fontWeight="bold" color="blue.500">
                      Click to upload
                    </Text>{" "}
                    or drag and drop
                  </Text>
                  <Input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    opacity="0"
                    position="absolute"
                    width="100%"
                    height="100%"
                    cursor="pointer"
                    name="resource_file"
                  />
                </Box>
                <FormHelperText>Choose a file to upload.</FormHelperText>
              </FormControl>
            )}
            {fileAttached && resourceData.resource_type === "pdf" && (
              <Flex alignItems="center" mt={2}>
                <Box>
                  {}
                  <Text>File:</Text>
                  <Text>{fileAttached}</Text>
                  <Button
                    leftIcon={<FaTrash />}
                    colorScheme="red"
                    variant="outline"
                    mt={2}
                    onClick={handleRemoveFile}
                  >
                    Remove File
                  </Button>
                </Box>
              </Flex>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button type="submit" form="ResourceForm" isDisabled={loading} ml={3}>
            {loading ? <Spinner size="sm" /> : confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
