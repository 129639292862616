import { ChakraProvider } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ChartOfAccounts } from "./components/account/chartOfAccounts";
import { ClientDetail } from "./components/client/clientDetail";
import { ClientList } from "./components/client/clientList";
import { ClientQuickbooksLink } from "./components/client/clientQuickbooksLink";
import { ClientNavigation } from "./components/dashboard/clientNavigation";
import { ForgotPassword } from "./components/forgotPassword";
import { Login } from "./components/login";
import { Logout } from "./components/logout";
import Navigation from "./components/navigation";
import { ResetPassword } from "./components/resetPassword";
import ThemeView from "./components/themeView";
import { UserManagement } from "./components/user/userManagement";
import { VendorsList } from "./components/vendors/vendorsList";
import ContextWrapper from "./ContextWrapper";
import theme from "./theme";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
  const handleStorage = () => {
    // Check access_token from either localStorage or sessionStorage
    const accessToken =
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token");

    if (accessToken !== "null" && accessToken !== null) {
      setIsAuth(true);

      axios
        .get("/api/v2/current_profile/", {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((res) => {
          if (res.data) {
            if (!res.data[0].is_active) {
              setIsAuth(false);
            } else {
              const profile = res.data[0];
              // TODO: remove this temporary hack we're using to limit access to nwe accounting things
              profile.hasAccountingAccess = profile.is_admin;
              profile.isAdmin = profile.type === "admin";
              setProfile(profile);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setIsAuth(false);
    }
  };

  handleStorage();

  window.addEventListener("storage", handleStorage);

  return () => window.removeEventListener("storage", handleStorage);
}, []);

  return (
    <ChakraProvider theme={theme}>
      <ContextWrapper profile={profile}>
        <BrowserRouter>
          {profile?.type === "admin" || profile?.type === "accountant" ? (
            isAuth && <Navigation isAdmin={profile?.isAdmin}></Navigation>
          ) : (
            <></>
          )}
          {profile?.type !== "client" && (
            <Routes>
              <Route
                path="/"
                element={
                  profile?.type === "admin" ||
                  profile?.type === "accountant" ? (
                    <ClientList />
                  ) : (
                    <></>
                  )
                }
              />
              <Route path="/client/:customer_id" element={<ClientDetail />} />
              {profile?.isAdmin && (
                <Route path="/vendors" element={<VendorsList />} />
              )}
              {profile?.isAdmin && (
                <Route
                  path="/chart-of-accounts-template"
                  element={<ChartOfAccounts customer_id={""} template={true} />}
                />
              )}
              {profile?.isAdmin && (
                <Route path="/users" element={<UserManagement />} />
              )}
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route
                path="/reset"
                element={<ResetPassword register={false} />}
              />
              <Route
                path="/register"
                element={<ResetPassword register={true} />}
              />
              <Route path="/qblink" element={<ClientQuickbooksLink />} />
              <Route path="/themeView" element={<ThemeView />} />
            </Routes>
          )}
          {profile?.type === "client" && (
            <Routes>
              <Route
                path="/"
                element={<ClientNavigation customers={profile.customers} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route
                path="/reset"
                element={<ResetPassword register={false} />}
              />
              <Route
                path="/register"
                element={<ResetPassword register={true} />}
              />
            </Routes>
          )}
          {!isAuth && (
            <Routes>
              <Route path="/" element={<Login />} />{" "}
            </Routes>
          )}
        </BrowserRouter>
      </ContextWrapper>
    </ChakraProvider>
  );
}
export default App;
