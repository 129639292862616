import React, { useState, useEffect, useCallback } from "react";
import {
  Image,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Stack,
  Container,
  Heading,
  Input,
  Flex,
  Box,
  InputGroup,
  InputRightElement,
  Spacer,
  HStack,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import CustomTableContainer from "../../theme/components/tableContainer";
import { Context } from "../../ContextWrapper";
import { GitMerge, Trash } from "react-feather";
import { VendorSelect } from "../account/vendorDropDown";
import { fetchAccounts } from "../../api/accounts";
import { GenericModal } from "../generic/modal";
import { DropDown } from "../generic/dropDown";
import { LogoField } from "../generic/logoField";
import {
  createVendor,
  deleteVendor,
  fetchVendors,
  mergeVendors,
  updateVendor,
} from "../../api/vendors";

export const VendorsList = () => {
  const [vendorsList, setVendorsList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [search, setSearch] = useState("");
  const [nextCursor, setNextCursor] = useState(null);
  const [previousCursor, setPreviousCursor] = useState(null);
  const context = React.useContext(Context);
  const {
    isOpen: isMergeOpen,
    onOpen: onMergeOpen,
    onClose: onMergeClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isVendorOpen,
    onOpen: onVendorOpen,
    onClose: onVendorClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const refreshVendorsList = useCallback(
    (cursor) => {
      let params = {};
      if (cursor) {
        const urlObj = new URL(cursor);
        params.cursor = urlObj.searchParams.get("cursor");
      }

      if (search) {
        params.name__icontains = search;
      }

      const fetchData = async () => {
        const data = await fetchVendors(params);
        setVendorsList(data.results);
        setNextCursor(data.next);
        setPreviousCursor(data.previous);
      };
      fetchData();
    },
    [search]
  );

  const removeVendorFromList = (vendorId) => {
    setVendorsList((prevList) =>
      prevList.filter((vendor) => vendor.id !== vendorId)
    );
    context.fireToast("Success", "Vendor Deleted.", "success");
  };

  useEffect(() => {
    refreshVendorsList();

    const getData = async () => {
      var accountResults = fetchAccounts();
      setAccountList(await accountResults);
    };
    getData();
  }, [refreshVendorsList]);

  const handleNextPage = () => {
    if (nextCursor) {
      refreshVendorsList(nextCursor);
    }
  };

  const handlePreviousPage = () => {
    if (previousCursor) {
      refreshVendorsList(previousCursor);
    }
  };

  const handleMergeSubmit = (data) => {
    setLoading(true);
    mergeVendors(data)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          onMergeClose();
          context.fireToast(
            "Success",
            "Successfully merged the vendors",
            "success"
          );
          setVendorsList((prevList) =>
            prevList.filter(
              (vendor) => data.duplicates.indexOf(vendor.id) === -1
            )
          );
        } else {
          context.fireToast(
            "Error",
            "There was an error merging the vendors.",
            "error"
          );
          onMergeClose();
        }
      })
      .catch((error) => {
        setLoading(false);
        context.fireToast(
          "Error",
          "There was an error merging the vendors!",
          "error"
        );
        console.error("There was an error merging the vendors!", error);
      });
  };

  const startDeleteVendor = (vendor) => {
    setSelectedVendor(vendor);
    onDeleteOpen();
  };

  const startCreateVendor = () => {
    setSelectedVendor(null);
    onVendorOpen();
  };

  const startUpdateVendor = (vendor) => {
    setSelectedVendor(vendor);
    onVendorOpen();
  };

  const getAccountName = (vendor) => {
    const account = accountList?.find((a) => a.id === vendor.account);
    if (account) {
      return account.number + " " + account.name;
    }
    return "";
  };

  const handleSubmit = (event, data) => {
    event.preventDefault();
    setLoading(true);

    if (data.id) {
      updateVendor(data.id, data)
        .then((res) => {
          setVendorsList((prevList) =>
            prevList.map((v) => (v.id === res.data.id ? res.data : v))
          );
          onVendorClose();
          context.fireToast("Success", "Vendor updated.", "success");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      createVendor(data)
        .then((res) => {
          setVendorsList((prevList) => [...prevList, res.data]);
          onVendorClose();
          context.fireToast("Success", "Vendor created.", "success");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Container maxW="100%" bg="brand.50" h="calc(91vh)">
      <Stack direction="column" spacing={2} align="center">
        <CustomTableContainer width="90%">
          <Flex pb="5">
            <Box>
              <Heading as="h3" size="lg">
                Vendor Listing
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <HStack p="0" direction="row" spacing={2} align="center">
                <Button
                  ps={7}
                  pe={7}
                  leftIcon={<GitMerge />}
                  onClick={onMergeOpen}
                >
                  Merge
                </Button>
                <MergeVendorsPopup
                  isOpen={isMergeOpen}
                  onClose={onMergeClose}
                  loading={loading}
                  handleSubmit={handleMergeSubmit}
                />
                <VendorDelete
                  isOpen={isDeleteOpen}
                  onClose={onDeleteClose}
                  vendor={selectedVendor}
                  removeVendorFromList={removeVendorFromList}
                />
                <VendorModal
                  isOpen={isVendorOpen}
                  onClose={onVendorClose}
                  vendor={selectedVendor}
                  accountList={accountList}
                  handleSubmit={handleSubmit}
                />
                <Button
                  ps={10}
                  pe={10}
                  leftIcon={<AddIcon />}
                  onClick={startCreateVendor}
                >
                  Create Vendor
                </Button>
                <InputGroup minW="340px">
                  <Input
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <InputRightElement
                    bg="brand.500"
                    borderBottomRightRadius={6}
                    borderTopRightRadius={6}
                  >
                    <SearchIcon variant="white" />
                  </InputRightElement>
                </InputGroup>
              </HStack>
            </Box>
          </Flex>
          <Box maxHeight="calc(100vh - 300px)" bg="white" overflowX="auto">
            <Table variant="unstyled">
              <Thead position="sticky" top={-1} zIndex={1}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Account</Th>
                  <Th>Website</Th>
                  <Th style={{ width: "118px" }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vendorsList.map((vendor) => (
                  <Tr key={vendor.id}>
                    <Td style={{ paddingRight: "0px" }}>
                      <Box display="inline-flex" alignItems="center">
                        <Image
                          src={
                            vendor.logo ||
                            `https://ui-avatars.com/api/?size=24&name=${vendor.name}`
                          }
                          alt={vendor.name || "Placeholder"}
                          boxSize="25px"
                          objectFit="cover"
                          borderRadius="full"
                          mr="2"
                        />
                        {vendor.name}
                      </Box>
                    </Td>
                    <Td>{getAccountName(vendor)}</Td>
                    <Td>{vendor.website}</Td>
                    <Td pt="2" pb="2">
                      <HStack p="0" direction="row" spacing={2} align="center">
                        <Button onClick={() => startUpdateVendor(vendor)}>
                          Update
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={() => startDeleteVendor(vendor)}
                        >
                          Delete
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Stack direction="row" spacing={4} mt={4} justifyContent="center">
            {previousCursor && (
              <Button onClick={handlePreviousPage}>Previous</Button>
            )}
            {nextCursor && (
              <Button onClick={handleNextPage} disabled={!nextCursor}>
                Next
              </Button>
            )}
          </Stack>
        </CustomTableContainer>
      </Stack>
    </Container>
  );
};

const MergeVendorsPopup = ({ isOpen, onClose, loading, handleSubmit }) => {
  const [originalVendor, setOriginalVendor] = useState(null);
  const [duplicateVendors, setDuplicateVendors] = useState([]);

  const updateOriginalVendor = (vendor) => {
    setOriginalVendor(vendor.value);
  };

  const addDuplicateVendor = (vendor) => {
    if (duplicateVendors.indexOf(vendor) < 0) {
      setDuplicateVendors([...duplicateVendors, vendor]);
    }
  };

  const removeDuplicateVendor = (vendor) => {
    setDuplicateVendors((prev) => prev.filter((v) => v.value !== vendor.value));
  };

  const handleLocalSubmit = (event) => {
    event.preventDefault();
    const data = {
      original_vendor: originalVendor,
      duplicates: duplicateVendors.map((v) => v.value),
    };
    setOriginalVendor(null);
    setDuplicateVendors([]);
    handleSubmit(data);
  };

  const isFormValid = () => {
    if (!originalVendor || duplicateVendors.length <= 0) {
      return false;
    }
    if (duplicateVendors.find((v) => v.value === originalVendor)) {
      return false;
    }
    return true;
  };
  const valid = isFormValid();

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Merge Vendors"}
      loading={loading}
      body={
        <>
          <form id="vendorMergeForm" onSubmit={(e) => handleLocalSubmit(e)}>
            <FormControl>
              <FormLabel htmlFor="original_vendor">Original Vendor</FormLabel>
              <VendorSelect onChange={updateOriginalVendor} w="400px" />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="duplicate_vendor">
                Duplicate Vendors
              </FormLabel>
              <VendorSelect
                onChange={addDuplicateVendor}
                metaData={"Duplicate_vendor"}
                w="400px"
              />
            </FormControl>
          </form>
          <Box
            mt={5}
            maxHeight="calc(100vh - 480px)"
            bg="white"
            overflowX="auto"
          >
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Td>Duplicates to merge</Td>
                  <Td></Td>
                </Tr>
              </Thead>
              <Tbody>
                {duplicateVendors.map((v) => (
                  <Tr key={v.value}>
                    <Td pt="4px" pb="4px">
                      {v.label}
                    </Td>
                    <Td pt="4px" pb="4px" w="40px">
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        icon={<Trash />}
                        onClick={() => removeDuplicateVendor(v)}
                        variant="outline"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </>
      }
      submitButton={
        <Button type="submit" form="vendorMergeForm" ml={3} isDisabled={!valid}>
          {loading ? <Spinner size="sm" /> : "Merge"}
        </Button>
      }
    />
  );
};

const VendorDelete = ({ isOpen, onClose, vendor, removeVendorFromList }) => {
  const context = React.useContext(Context);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    deleteVendor(vendor.id)
      .then((response) => {
        if (response.status === 204) {
          removeVendorFromList(vendor.id);
          onClose();
        } else {
          context.fireToast(
            "Error",
            response["request"]["responseText"],
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the vendor!", error);
        context.fireToast("Error", "Failed to delete the vendor.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Confirm Delete"}
      loading={loading}
      body={`Are you sure you want to delete ${vendor?.name}?`}
      submitButton={
        <Button
          colorScheme="red"
          ml="3"
          onClick={handleDelete}
          disabled={loading}
        >
          {loading ? "Processing..." : "Delete"}
        </Button>
      }
    />
  );
};

const VendorModal = ({
  isOpen,
  onClose,
  vendor,
  loading,
  handleSubmit,
  accountList,
}) => {
  const [vendorData, setVendorData] = useState({});
  const [imagePreview, setImagePreview] = useState(null);

  const text = !vendor?.id ? "Create" : "Update";

  useEffect(() => {
    setImagePreview(null);
    if (vendor?.logo) {
      setImagePreview(vendor.logo);
      delete vendor.logo;
    }
    setVendorData(vendor);
  }, [vendor]);

  const formattedAccountList = accountList.map((account) => {
    return {
      value: account.id,
      label: account.number + " - " + account.name,
    };
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorData({ ...vendorData, [name]: value });
  };

  const handleSelect = (selected, name) => {
    setVendorData({ ...vendorData, [name]: selected.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setVendorData({ ...vendorData, [e.target.name]: file });
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={`${text} Vendor`}
      loading={loading}
      body={
        <form
          id="vendorForm"
          onSubmit={(e) => handleSubmit(e, vendorData)}
          encType="multipart/form-data"
        >
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              type="text"
              value={vendorData?.name || ""}
              onChange={handleInputChange}
              required
            />
          </FormControl>

          <FormControl id="website">
            <FormLabel>Website</FormLabel>
            <Input
              name="website"
              type="url"
              value={vendorData?.website || ""}
              onChange={handleInputChange}
              required
            />
          </FormControl>

          <FormControl id="description">
            <FormLabel>Description</FormLabel>
            <Input
              name="description"
              type="text"
              value={vendorData?.description || ""}
              onChange={handleInputChange}
              required
            />
          </FormControl>

          <FormControl id="account">
            <FormLabel>Account</FormLabel>
            <DropDown
              name="account"
              options={formattedAccountList}
              selectedValue={vendorData?.account || ""}
              onChange={handleSelect}
              metaData={"account"}
            />
          </FormControl>
          <LogoField
            name="logo"
            text="Vendor Logo"
            imagePreview={imagePreview}
            onChange={handleImageChange}
          />
        </form>
      }
      submitButton={
        <Button ml="3" type="submit" form="vendorForm">
          {text}
        </Button>
      }
    />
  );
};
