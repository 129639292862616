import React, {
  useEffect,
  useState
} from "react";
import { Link } from "react-router-dom";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
  useTab
} from "@chakra-ui/react";
import axios from "axios";
import {
  BarChart,
  BookOpen,
  Home,
  LogOut,
  Minus,
  Plus,
  Settings,
} from "react-feather";
import { useCurrentProfile } from "../../ContextWrapper";
import { ClientPlaid } from "../client/clientPlaid";
import ClientSelector from "../dashboardComponents/clientSelector";
import { ProfileSettings } from "../profileSettings/profileSettingsPage";
import { GrowthPlan } from "./growthPlan";

export const ClientNavigation = ({ customers }) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const [navOverlap] = useMediaQuery("(max-width: 1439px)");
  const dropdownRef = React.useRef();
  const [customerInfo, setCustomerInfo] = useState({});
  const [isClientSelectOpen, setClientSelectOpen] = useState(false);
  const [isGrowthPlanOpen, setIsGrowthPlanOpen] = useState(true);
  const [isPerformanceOpen, setIsPerformanceOpen] = useState(false);
  const [isGrowthToolsOpen, setIsGrowthToolsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingssOpen] = useState(false);
  const [isLogoutSelected, setIsLogoutSelected] = useState(false);
  const [dashboardDate, setDashboardDate] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(isMobile ? true : false);
  const [userInput, setUserInput] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tabs] = useState(["Growth Plan", "Performance Review"]);
  const [profileData, setProfileData] = useState({});
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const { profile } = useCurrentProfile();

  const filteredTabs = tabs.filter((tab) =>
    tab.toLowerCase().includes(userInput.toLowerCase())
  );

  const handleOutsideClick = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  useEffect(() => {
    if (customers) {
      setCustomerInfo(customers[0]);
    }
  }, [customers]);

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshProfileData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(`/api/v2/profiles/${id}/`, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            signal: controller.signal,
          });
          if (isActive) {
            setProfileData(res.data);
            setTriggerRefresh(false);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshProfileData(profile.id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [profile, triggerRefresh]);

  return (
    <Tabs
      orientation="vertical"
      isLazy
      index={selectedIndex}
      onChange={(index) => setSelectedIndex(index)}
    >
      <TabList
        position={"fixed"}
        visibility={!visible && isMobile && "hidden"}
        transition="width 0.1s ease-in-out"
        top="0"
        left="0"
        maxHeight="100vh"
        height={"100%"}
        zIndex={1000}
        borderRight={"1px solid #e0e0e0"}
        bg="white"
        color="black"
        pl={"22.50px"}
        pr={"22.50px"}
        width={visible ? "100%" : !isMobile ? "105px" : "0px"}
        maxW={"324px"}
        display="flex"
        flexDirection="column"
      >
        <Flex h="80px" alignItems={"center"} justifyContent={"space-between"}>
          {visible ? (
            <Box align="left">
              <Image
                onClick={() => setSelectedIndex(0)}
                boxSize="170px"
                objectFit="contain"
                src="../lucro-logo-light.png"
                cursor={"pointer"}
              />
            </Box>
          ) : (
            <></>
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            align="center"
            height="80px"
            width={"57px"}
            cursor="pointer"
            onClick={() => {
              setVisible(!visible);
            }}
          >
            <Box
              height="48px"
              width="48px"
              border="2px solid #F5F5F5"
              backgroundColor="#FBFBFB"
              borderRadius="12px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {!visible ? (
                <Image
                  src="/customer-nav-arrow.png"
                  alt="Client Navigation Open"
                  transform="rotate(180deg)"
                  boxSize="30px"
                  objectFit="contain"
                />
              ) : (
                <Image
                  src="/customer-nav-arrow.png"
                  alt="Client Navigation Collapse"
                  boxSize="30px"
                  objectFit="contain"
                />
              )}
            </Box>
          </Box>
        </Flex>

        <ClientSelector
          customers={customers}
          customerInfo={customerInfo}
          setCustomerInfo={setCustomerInfo}
          visible={visible}
          setVisible={setVisible}
          isClientSelectOpen={isClientSelectOpen}
          setClientSelectOpen={setClientSelectOpen}
        />

        <VStack
          zIndex={5}
          align="stretch"
          spacing="2"
          overflowY={"auto"}
          height={"100%"}
          maxHeight={"100%"}
          mb={2}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
          overflowX={"hidden"}
        >
          {visible ? (
            <Box
              width={"100%"}
              m={"10px 0px 0px"}
              position="relative"
              ref={dropdownRef}
              bg="white"
              borderRadius={"12px"}
            >
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  pl={7}
                  fontSize={"25px"}
                  color={"black"}
                  height={"55px"}
                >
                  <SearchIcon color="gray.500" />
                </InputLeftElement>
                <Input
                  border={0}
                  value={userInput}
                  onChange={(e) => {
                    setUserInput(e.target.value);
                    setIsDropdownOpen(!isDropdownOpen);
                    setVisible(true);
                  }}
                  placeholder={visible ? "Search..." : ""}
                  onFocus={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                    setIsGrowthPlanOpen(false);
                    setIsPerformanceOpen(false);
                    setIsGrowthToolsOpen(false);
                    setIsSettingssOpen(false);
                  }}
                  bg="gray.100"
                  color="black"
                  fontSize="16px"
                  borderTopRadius={"12px"}
                  height={"55px"}
                  borderBottomRadius={isDropdownOpen ? "0px" : "12px"}
                  pl="14"
                  _focus={{
                    boxShadow: "0 0 0 0px #3182ce",
                    border: "none",
                  }}
                />
              </InputGroup>

              {isDropdownOpen && filteredTabs.length > 0 && (
                <Box
                  bg="gray.100"
                  position="absolute"
                  top="100%"
                  left="0"
                  width="100%"
                  overflowY={"auto"}
                  borderBottomRadius="12px"
                  maxH={"20vh"}
                  zIndex={10}
                >
                  <List spacing={1}>
                    {filteredTabs.map((tab, index) => (
                      <ListItem
                        key={index}
                        p={2}
                        pl={6}
                        height={12}
                        _hover={{
                          bg: "gray.100",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setUserInput(tab);
                          setSelectedIndex(index);
                        }}
                      >
                        <HStack>
                          <Text>{tab}</Text>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              {isDropdownOpen && filteredTabs.length === 0 && (
                <Box
                  bg="gray.100"
                  position="absolute"
                  top="100%"
                  left="0"
                  width="100%"
                  overflowY={"auto"}
                  borderBottomRadius="12px"
                  maxH={"20vh"}
                  zIndex={10}
                >
                  <Box height={12} p={2}>
                    <HStack pl="4">
                      <Minus />
                      <Text>No matching tabs found.</Text>
                    </HStack>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Button
              width={"100%"}
              height={"57px"}
              fontSize={"25px"}
              m={"10px 0px"}
              position="relative"
              ref={dropdownRef}
              bg="gray.100"
              onClick={() => {
                setIsDropdownOpen(true);
                setVisible(true);
              }}
              p={3}
              _hover={{ bg: "gray.100" }}
              borderRadius={"12px"}
            >
              <SearchIcon color="gray.500" />
            </Button>
          )}

          <Box
            mt={4}
            bg={isGrowthPlanOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <CustomTab w="100%" justifyContent="stretch" pl={0} pr={0}>
              <Button
                as={"div"}
                w="100%"
                justifyContent="space-between"
                height={"57px"}
                variant="ghost"
                textColor={selectedIndex === 0 ? "white" : "#30384A"}
                borderRadius={"12px"}
                onClick={() => {
                  setIsGrowthPlanOpen(true);
                  setIsPerformanceOpen(false);
                  setIsGrowthToolsOpen(false);
                  setIsSettingssOpen(false);
                  setVisible(true);
                }}
                bg={selectedIndex === 0 ? "#536DEC" : "white"}
                _hover={{ bg: "#536DEC", color: "white" }}
                _selected={{ bg: "#536DEC", color: "white" }}
                p={visible ? 6 : 3}
              >
                <Flex
                  align="center"
                  justifyContent="center"
                  w={!visible ? "57px" : "auto"}
                  h={!visible ? "57px" : "auto"}
                >
                  <Home mr={visible ? 2 : 0} p={3} />
                  {visible ? <Text pl={3}>Growth Plan</Text> : <></>}
                </Flex>
              </Button>
              {/* <Collapse in={isDashboardOpen} animateOpacity>
              <Box bg="gray.100" py="2" borderBottomRadius={"12px"}>
                <CustomTab justifyContent="stretch">
                  <Text>Growth Plan</Text>
                </CustomTab>
                <CustomTab justifyContent="stretch">
                  <Text>Performance Review</Text>
                </CustomTab>

                 <CustomTab justifyContent="stretch">
                  <Text>Industry Insights</Text>
                </CustomTab>
              </Box>
            </Collapse> Kept here for reference */}
            </CustomTab>
          </Box>
          <Box
            mt={2}
            bg={isPerformanceOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Button
              w="100%"
              justifyContent="space-between"
              height={"57px"}
              variant="ghost"
              textColor={isPerformanceOpen ? "white" : "#30384A"}
              borderRadius={"12px"}
              onClick={() => {
                setIsPerformanceOpen(!isPerformanceOpen);
                setIsSettingssOpen(false);
                setVisible(true);
              }}
              bg={isPerformanceOpen ? "#536DEC" : "transparent"}
              _hover={{ bg: "#536DEC", color: "white" }}
              _selected={{ bg: "#536DEC", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex
                align="center"
                justifyContent="center"
                w={!visible ? "57px" : "auto"}
                h={!visible ? "57px" : "auto"}
              >
                <BookOpen mr={visible ? 2 : 0} p={3} />
                {visible ? <Text pl={3}>Performance Review</Text> : <></>}
              </Flex>
              {visible ? (
                isPerformanceOpen ? (
                  <Minus pl={9} fontSize="40px" />
                ) : (
                  <Plus pl={9} fontSize="40px" />
                )
              ) : (
                <></>
              )}
            </Button>
            <Collapse in={isPerformanceOpen && visible} animateOpacity>
              <Box bg="gray.100" py={2} borderBottomRadius={"12px"}>
                <CustomTab
                  justifyContent="stretch"
                  onClick={() => {
                    setIsGrowthPlanOpen(false);
                    setIsGrowthToolsOpen(false);
                  }}
                >
                  <Text>Metric View</Text>
                </CustomTab>
                <CustomTab
                  justifyContent="stretch"
                  onClick={() => {
                    setIsGrowthPlanOpen(false);
                    setIsGrowthToolsOpen(false);
                  }}
                >
                  <Text>Detail View</Text>
                </CustomTab>
              </Box>
            </Collapse>
          </Box>

          <Box
            mt={0}
            bg={isGrowthToolsOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Button
              w="100%"
              justifyContent="space-between"
              height="57px"
              variant="ghost"
              textColor={isGrowthToolsOpen ? "white" : "#30384A"}
              borderRadius="12px"
              onClick={() => {
                window.open(
                  "https://morelucro.com/the-vault/",
                  "_blank",
                  "noopener,noreferrer"
                );
                setIsGrowthToolsOpen(!isGrowthToolsOpen);
                setIsGrowthPlanOpen(false);
                setIsPerformanceOpen(false);
                setIsSettingssOpen(false);
                setVisible(true);
              }}
              _selected={{ bg: "#536DEC", color: "white" }}
              bg={isGrowthToolsOpen ? "#536DEC" : "transparent"}
              _hover={{ bg: "#536DEC", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex
                align="center"
                justifyContent="center"
                w={!visible ? "57px" : "auto"}
                h="57px"
              >
                <BarChart />
                {visible ? <Text pl={3}>Growth Tools</Text> : null}
              </Flex>
            </Button>
          </Box>

          <Box
            mt="auto"
            bg={isSettingsOpen ? "gray.100" : "transparent"}
            borderRadius={"12px"}
          >
            <Button
              w="100%"
              justifyContent="space-between"
              height="57px"
              variant="ghost"
              borderRadius="12px"
              textColor={isSettingsOpen ? "white" : "#30384A"}
              onClick={() => {
                setIsSettingssOpen(!isSettingsOpen);
                setIsPerformanceOpen(false);
                setVisible(true);
              }}
              bg={isSettingsOpen ? "#536DEC" : "transparent"}
              _hover={{ bg: "#536DEC", color: "white" }}
              _selected={{ bg: "#536DEC", color: "white" }}
              p={visible ? 6 : 3}
            >
              <Flex
                align="center"
                justifyContent="center"
                w={!visible ? "57px" : "auto"}
                h="57px"
              >
                <Settings />
                {visible ? <Text pl={3}>Settings</Text> : null}
              </Flex>
            </Button>
            <Collapse in={isSettingsOpen && visible} animateOpacity>
              <Box bg="gray.100" py="2" borderBottomRadius={"12px"}>
                <Flex justifyContent="stretch">
                  <CustomTab
                    justifyContent="stretch"
                    onClick={() => {
                      setIsGrowthPlanOpen(false);
                      setIsGrowthToolsOpen(false);
                    }}
                  >
                    <Text>Account Management</Text>
                  </CustomTab>
                </Flex>
                <Flex justifyContent="stretch">
                  <CustomTab
                    justifyContent="stretch"
                    onClick={() => {
                      setIsGrowthPlanOpen(false);
                      setIsGrowthToolsOpen(false);
                    }}
                  >
                    <Text>Profile Settings</Text>
                  </CustomTab>
                </Flex>
              </Box>
            </Collapse>
          </Box>
          <Box>
            <Link className="nav-link" to="/logout">
              <Button
                w="100%"
                justifyContent="space-between"
                height="57px"
                variant="ghost"
                onClick={() => setIsLogoutSelected(true)}
                textColor={isLogoutSelected ? "white" : "#30384A"}
                borderRadius="12px"
                bg={isLogoutSelected ? "#536DEC" : "transparent"}
                _hover={{ bg: "#536DEC", color: "white" }}
                _selected={{ bg: "#536DEC", color: "white" }}
                p={visible ? 6 : 3}
              >
                <Flex
                  align="center"
                  justifyContent="center"
                  w={!visible ? "57px" : "auto"}
                  h="57px"
                >
                  <LogOut />
                  {visible ? <Text pl={3}>Log Out</Text> : null}
                </Flex>
              </Button>
            </Link>
          </Box>
        </VStack>
        <Box pl={visible ? 2 : 0} mb="5" pr={visible ? 2 : 0}>
          <Divider color="gray.50" />

          <HStack pt={2}>
            <Flex>
              {/* Left element */}
              <Image
                src={
                  profileData?.profile_picture ||
                  (profileData?.user?.first_name && profileData?.user?.last_name
                    ? `https://ui-avatars.com/api/?size=24&name=${profileData?.user?.first_name}+${profileData?.user?.last_name}`
                    : `https://ui-avatars.com/api/?size=24&name=${
                        profileData?.user?.username || "Placeholder"
                      }`)
                }
                alt={
                  profileData?.user?.first_name ||
                  profileData?.user?.username ||
                  "Placeholder"
                }
                boxSize="57px"
                objectFit="cover"
                borderRadius="full"
                mr={visible ? 2 : 0}
              />

              {/* Right stacked elements */}
              {visible ? (
                <Box alignContent={"center"}>
                  <Text>
                    {profileData?.user?.first_name}{" "}
                    {profileData?.user?.last_name}
                  </Text>
                  <Text>{profileData?.user?.username}</Text>
                </Box>
              ) : (
                <></>
              )}
            </Flex>
          </HStack>
        </Box>
      </TabList>
      <TabPanels>
        <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
          transition="margin 0.1s ease-in-out"
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
          transition="margin 0.1s ease-in-out"
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                toggleView={"performanceReview"}
                toggleSubView={"metric"}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
          transition="margin 0.1s ease-in-out"
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                toggleView={"performanceReview"}
                toggleSubView={"detail"}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        {/* <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <GrowthPlan
                customer_id={customerInfo.id}
                dateFromNav={dashboardDate}
                setDateFromNav={setDashboardDate}
                setNavIndex={setSelectedIndex}
                toggleView={"industryInsights"}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel> */}

        <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
          transition="margin 0.1s ease-in-out"
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <ClientPlaid
                customer_id={customerInfo.id}
                setNavVisible={setVisible}
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel
          p="0"
          ml={
            visible
              ? isMobile || navOverlap
                ? "0px"
                : "324px"
              : isMobile
              ? "0px"
              : "105px"
          }
          transition="margin 0.1s ease-in-out"
        >
          <Box h="calc(100vh - 80px)">
            {customerInfo && (
              <ProfileSettings
                setNavVisible={setVisible}
                trigger={setTriggerRefresh}
              />
            )}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const CustomTab = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });

  return (
    <Button
      w="290px"
      bg="grey.100"
      textColor={"#30384A"}
      _hover={{ bg: "grey.100" }}
      _selected={{ bg: "grey.100", color: "blue" }}
      _focus={{ color: "blue" }}
      _active={{ bg: "grey.100", color: "blue" }}
      {...tabProps}
    >
      {tabProps.children}
    </Button>
  );
});
