import {
  Box,
  Card,
  CardBody,
  Grid,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProfitCard from "./profitCard";

import {
  colorExpenseFormat,
  colorFormat,
  customCurrencyFormat,
} from "../../utils";
import NotificationContainer from "../generic/updateNotification";
import ExpenseCard from "./expenseCard";
import InsightCard from "./insightCard";
import MetricGraph from "./metricGraph";
import axios from "axios";

const IndustryInsightView = ({
  customer_id,
  performanceGroup,
  revenueGroup,
}) => {
  const [insightData, setInsightData] = useState({
    revenue: "0.00",
    income_forecast: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    profit: "0.00",
    cogs: "0.00",
    cogs_percentage: "0.00",
    marketing: "0.00",
    marketing_percentage: "0.00",
    team: "0.00",
    team_percentage: "0.00",
    overhead: "0.00",
    overhead_percentage: "0.00",
    profit_percentage: "0.00",
    graph: {
      income: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      expense: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      profit: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      months: ["-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
    },
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });

  useEffect(() => {
    const controller = new AbortController();
    let isActive = true;

    const refreshInsightData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/industryinsights/${performanceGroup}/${revenueGroup}`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal,
            }
          );
          if (isActive) {
            setInsightData(res.data);
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshInsightData(customer_id);

    return () => {
      isActive = false;
      controller.abort();
    };
  }, [customer_id, performanceGroup, revenueGroup]);

  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  return (
    <Stack marginBottom={"10px"} pt={isMobile ? "185px" : "120px"}>
      <NotificationContainer rounded={true} p={"0px 20px 0px"} />
      <Stack p="5px 20px">
        {!isMobile ? (
          <Grid
            templateColumns="2fr 275px 2fr"
            spacing={2}
            p="10px 0px 10px 0px"
            alignItems="center"
            justifyItems="center"
          >
            <Stack
              width="100%"
              align="center"
              gridColumn={1}
              gridRow={"span 2"}
            >
              <InsightCard
                title="Total Income"
                amount={customCurrencyFormat(
                  insightData ? insightData.revenue : 0.0
                )}
                ytd={customCurrencyFormat(
                  insightData ? insightData.ytd_income : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  insightData ? insightData.ytd_income_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  insightData ? insightData.income_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  insightData ? insightData.income_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  insightData ? insightData.income_forecast_percentage : "0.00"
                )}
                ytd_color={colorFormat(
                  insightData ? insightData.ytd_income_percentage : "0.00"
                )}
                image={"/money-up-arrow.png"}
              />
            </Stack>

            <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
              <ProfitCard
                profit={customCurrencyFormat(
                  insightData ? insightData.profit_percentage : 0.0,
                  true
                )}
                profitPercentage={customCurrencyFormat(
                  insightData ? insightData.profit : 0.0
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.profit_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.profit_pl : "0.00",
                  insightData ? insightData.profit_percentage : "0.00",
                  true,
                  true
                )}
                showPL={false}
              />
            </Stack>
            <Stack
              width="100%"
              align="center"
              gridColumn={3}
              gridRow={"span 2"}
            >
              <InsightCard
                title="Total Expenses"
                amount={customCurrencyFormat(
                  insightData ? insightData.expense : 0.0
                )}
                ytd={customCurrencyFormat(
                  insightData ? insightData.ytd_expense : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  insightData ? insightData.ytd_expense_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  insightData ? insightData.expense_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  insightData ? insightData.expense_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  insightData
                    ? insightData.expense_forecast_percentage
                    : "0.00",
                  true
                )}
                ytd_color={colorFormat(
                  insightData ? insightData.ytd_expense_percentage : "0.00",
                  true
                )}
                image={"/money-down-arrow.png"}
              />
            </Stack>
          </Grid>
        ) : (
          <VStack>
            <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
              <ProfitCard
                profit={customCurrencyFormat(
                  insightData ? insightData.profit_percentage : 0.0,
                  true
                )}
                profitPercentage={customCurrencyFormat(
                  insightData ? insightData.profit : 0.0
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.profit_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.profit_pl : "0.00",
                  insightData ? insightData.profit_percentage : "0.00",
                  true,
                  true
                )}
                showPL={false}
              />
            </Stack>

            <Stack
              width="100%"
              align="center"
              gridColumn={1}
              gridRow={"span 2"}
            >
              <InsightCard
                title="Total Income"
                amount={customCurrencyFormat(
                  insightData ? insightData.revenue : 0.0
                )}
                ytd={customCurrencyFormat(
                  insightData ? insightData.ytd_income : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  insightData ? insightData.ytd_income_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  insightData ? insightData.income_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  insightData ? insightData.income_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  insightData ? insightData.income_forecast_percentage : "0.00"
                )}
                ytd_color={colorFormat(
                  insightData ? insightData.ytd_income_percentage : "0.00"
                )}
                image={"/money-up-arrow.png"}
              />
            </Stack>

            <Stack
              width="100%"
              align="center"
              gridColumn={3}
              gridRow={"span 2"}
            >
              <InsightCard
                title="Total Expenses"
                amount={customCurrencyFormat(
                  insightData ? insightData.expense : 0.0
                )}
                ytd={customCurrencyFormat(
                  insightData ? insightData.ytd_expense : 0.0
                )}
                ytd_percentage={customCurrencyFormat(
                  insightData ? insightData.ytd_expense_percentage : 0.0,
                  true
                )}
                forecast={customCurrencyFormat(
                  insightData ? insightData.expense_forecast : 0.0
                )}
                forecast_percentage={customCurrencyFormat(
                  insightData ? insightData.expense_forecast_percentage : 0.0,
                  true
                )}
                forecast_color={colorFormat(
                  insightData
                    ? insightData.expense_forecast_percentage
                    : "0.00",
                  true
                )}
                ytd_color={colorFormat(
                  insightData ? insightData.ytd_expense_percentage : "0.00",
                  true
                )}
                image={"/money-down-arrow.png"}
              />
            </Stack>
          </VStack>
        )}

        <Card
          height="320px"
          maxHeight={"100%"}
          width="100%"
          borderRadius={"12px"}
          marginBottom={"10px"}
        >
          <CardBody height="40%" p={"24px 34px 70px 34px "}>
            <HStack wrap="wrap" pb={"10px"}>
              <Text
                fontSize={"clamp(13px, calc(1.6cqw - 0.09vw), 16px)"}
                fontWeight={500}
              >
                12-Month Income, Expenses and Profit
              </Text>
              <Spacer />
              <HStack>
                <Box
                  backgroundColor={"#458CFF"}
                  height={"15px"}
                  width={"15px"}
                  borderRadius={"5px"}
                ></Box>
                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Income
                </Text>
                <Box
                  backgroundColor={"#FF5A5C"}
                  height={"15px"}
                  width={"15px"}
                  borderRadius={"5px"}
                ></Box>

                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Expenses
                </Text>
                <Box
                  backgroundColor={"#00D26C"}
                  height={"4px"}
                  width={"20px"}
                  borderRadius={"5px"}
                ></Box>
                <Text fontSize={"14px"} mr={"4px"} textAlign={"left"}>
                  Profit
                </Text>
              </HStack>
            </HStack>
            <MetricGraph metricGraphData={insightData.graph} />
          </CardBody>
        </Card>
        {!isMobile ? (
          <SimpleGrid
            templateColumns="1fr 1fr 1fr 1fr"
            spacing={4}
            maxWidth={"100%"}
          >
            <ExpenseCard
              title="Team"
              percentage={customCurrencyFormat(
                `${insightData ? insightData.team_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${insightData ? insightData.team : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                insightData ? insightData.team_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                insightData ? insightData.team_pl : "0.00",
                insightData ? insightData.team_percentage : "0.00"
              )}
              p={"0"}
              clickable={false}
            />
            <ExpenseCard
              title="Marketing"
              percentage={customCurrencyFormat(
                `${insightData ? insightData.marketing_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${insightData ? insightData.marketing : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                insightData ? insightData.marketing_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                insightData ? insightData.marketing_pl : "0.00",
                insightData ? insightData.marketing_percentage : "0.00"
              )}
              p={"0"}
              clickable={false}
            />
            <ExpenseCard
              title="Overheads"
              percentage={customCurrencyFormat(
                `${insightData ? insightData.overhead_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${insightData ? insightData.overhead : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                insightData ? insightData.overhead_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                insightData ? insightData.overhead_pl : "0.00",
                insightData ? insightData.overhead_percentage : "0.00"
              )}
              p={"0"}
              clickable={false}
            />
            <ExpenseCard
              title="Products (COGS)"
              percentage={customCurrencyFormat(
                `${insightData ? insightData.cogs_percentage : 0.0}`,
                true
              )}
              total={customCurrencyFormat(
                `${insightData ? insightData.cogs : 0.0}`
              )}
              perfectPL={customCurrencyFormat(
                insightData ? insightData.cogs_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                insightData ? insightData.cogs_pl : "0.00",
                insightData ? insightData.cogs_percentage : "0.00"
              )}
              p={"0"}
              clickable={false}
            />
          </SimpleGrid>
        ) : (
          <VStack width="100%" spacing={4} align="stretch">
            <Grid
              width="100%"
              templateColumns={{
                base: "1fr", // Single column for smaller screens
                md: "repeat(2, 1fr)", // Two columns for medium screens
              }}
              gap={4} // Adds spacing between grid items
            >
              <ExpenseCard
                title="Team"
                percentage={customCurrencyFormat(
                  `${insightData ? insightData.team_percentage : 0.0}`,
                  true
                )}
                total={customCurrencyFormat(
                  `${insightData ? insightData.team : 0.0}`
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.team_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.team_pl : "0.00",
                  insightData ? insightData.team_percentage : "0.00"
                )}
                p={"0"}
                clickable={false}
              />
              <ExpenseCard
                title="Marketing"
                percentage={customCurrencyFormat(
                  `${insightData ? insightData.marketing_percentage : 0.0}`,
                  true
                )}
                total={customCurrencyFormat(
                  `${insightData ? insightData.marketing : 0.0}`
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.marketing_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.marketing_pl : "0.00",
                  insightData ? insightData.marketing_percentage : "0.00"
                )}
                p={"0"}
                clickable={false}
              />
              <ExpenseCard
                title="Overheads"
                percentage={customCurrencyFormat(
                  `${insightData ? insightData.overhead_percentage : 0.0}`,
                  true
                )}
                total={customCurrencyFormat(
                  `${insightData ? insightData.overhead : 0.0}`
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.overhead_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.overhead_pl : "0.00",
                  insightData ? insightData.overhead_percentage : "0.00"
                )}
                p={"0"}
                clickable={false}
              />
              <ExpenseCard
                title="Products (COGS)"
                percentage={customCurrencyFormat(
                  `${insightData ? insightData.cogs_percentage : 0.0}`,
                  true
                )}
                total={customCurrencyFormat(
                  `${insightData ? insightData.cogs : 0.0}`
                )}
                perfectPL={customCurrencyFormat(
                  insightData ? insightData.cogs_pl : 0.0,
                  true
                )}
                color={colorExpenseFormat(
                  insightData ? insightData.cogs_pl : "0.00",
                  insightData ? insightData.cogs_percentage : "0.00"
                )}
                p={"0"}
                clickable={false}
              />
            </Grid>
          </VStack>
        )}
      </Stack>
    </Stack>
  );
};

export default IndustryInsightView;
