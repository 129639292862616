import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Center,
  Container,
  Spinner,
  Button,
  HStack,
  Text,
  Spacer,
  Box,
  ListItem,
  ListIcon,
  List,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Context } from "../../ContextWrapper";
import { DropDown } from "../generic/dropDown";
import { FaLink, FaUnlink } from "react-icons/fa";
import {
  fetchAccountSubTypes,
  fetchAccountTaxTypes,
  fetchAccountTypes,
  fetchCustomerAccounts,
} from "../../api/accounts";
import { AccountModal } from "../account/chartOfAccounts";

export const ClientQuickbooks = ({ customer_id }) => {
  const [qbLinked, setQbLinked] = useState(false);
  const [missingMainAccounts, setMissingMainAccounts] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [authUrl, setAuthUrl] = useState("");
  const [extraAccounts, setExtraAccounts] = useState([]);
  const [renumberAccounts, setRenumberAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const [loadingRenumber, setLoadingRenumber] = useState(false);
  const [loadingRestructure, setLoadingRestructure] = useState(false);
  const [errors, setErrors] = useState([]);
  const [syncType, setSyncType] = useState("");
  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();
  const {
    isOpen: isSyncOpen,
    onOpen: onSyncOpen,
    onClose: onSyncClose,
  } = useDisclosure();
  const {
    isOpen: isRenumberOpen,
    onOpen: onRenumberOpen,
    onClose: onRenumberClose,
  } = useDisclosure();
  const {
    isOpen: isRestructureOpen,
    onOpen: onRestructureOpen,
    onClose: onRestructureClose,
  } = useDisclosure();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const context = React.useContext(Context);

  useEffect(() => {
    loadQuickbooksAccounts(customer_id);
  }, [customer_id]);

  const loadQuickbooksAccounts = (id, setChildLoading) => {
    axios
      .get(
        `/api/v2/client_quickbooks_accounts/${id}/`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setQbLinked(res.data.qb_linked);
        setMissingMainAccounts(res.data.missing_main_accounts);
        setAccounts(res.data.accounts);
        setAuthUrl(res.data.auth_url);
        setExtraAccounts(res.data.extra_qb_accounts);
        setRenumberAccounts(res.data.renumber_qb_accounts);

        localStorage.setItem("customer_id", id);

        setLoading(false);
        if (setChildLoading) {
          setChildLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const disconnectQuickbooks = () => {
    setLoading(true);
    axios
      .post(
        `/api/v2/client_link_quickbooks/`,
        {
          customer_id: customer_id,
          action: "disconnect",
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then(() => {
        setQbLinked(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const createMainAccounts = () => {
    setLoading(true);
    axios
      .post(
        `/api/v2/create_main_qb_account/`,
        {
          customer_id: customer_id,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then(() => {
        loadQuickbooksAccounts(customer_id);
      })
      .catch((err) => {
        context.fireToast("Error", err.response.data.error, "error");
        setLoading(false);
        console.log(err);
      });
  };

  const exportTransactions = (startDate, endDate) => {
    setLoadingExport(true);
    axios
      .post(
        `/api/v2/export_quickbooks_transactions/${customer_id}/`,
        {
          start_date: startDate,
          end_date: endDate,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.data.file;
        link.setAttribute("download", response.data.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        onExportClose();
        setLoadingExport(false);
      })
      .catch((err) => console.log(err));
  };

  const syncQB = (startDate, endDate) => {
    setLoadingSync(true);

    var url = `/api/v2/sync_quickbooks_transactions/${customer_id}/`;
    if (syncType === "TB") {
      url = `/api/v2/sync_quickbooks_tb/${customer_id}/`;
    }

    axios
      .post(
        url,
        {
          start_date: startDate,
          end_date: endDate,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.errors) {
          setErrors(response.data.errors);
          onErrorOpen();
        }
        onSyncClose();
        setLoadingSync(false);
        context.fireToast(
          "Success",
          `${syncType} successfully synced.`,
          "success"
        );
      })
      .catch((err) => console.log(err));
  };

  const openExport = () => {
    onExportOpen();
    setLoadingExport(false);
  };

  const openSync = (type) => {
    setSyncType(type);
    onSyncOpen();
    setLoadingSync(false);
  };

  const renumberQBAccounts = (qb_accounts) => {
    setLoadingRenumber(true);
    axios
      .post(
        `/api/v2/renumber_qb_accounts/`,
        {
          customer_id: customer_id,
          qb_accounts: qb_accounts,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status < 300) {
          setLoading(true);
          loadQuickbooksAccounts(customer_id, setLoading);
        } else {
          context.fireToast("Error", res.response.data.error, "error");
        }
        setLoadingRenumber(false);
        onRenumberClose();
      })
      .catch((err) => {
        context.fireToast("Error", err.response.data.error, "error");
        setLoadingRenumber(false);
        onRenumberClose();
      });
  };

  const restructureAccounts = (qb_accounts) => {
    setLoadingRestructure(true);
    axios
      .post(
        `/api/v2/restructure_qb_accounts/${customer_id}/`,
        {
          qb_account_ids: qb_accounts,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (Object.keys(res.data.errors).length === 0) {
          setLoading(true);
          loadQuickbooksAccounts(customer_id, setLoading);
        } else {
          setErrors(res.data.errors);
          onErrorOpen();
        }
        setLoadingRestructure(false);
        onRestructureClose();
      })
      .catch((err) => {
        context.fireToast("Error", err.response.data.error, "error");
        setLoadingRestructure(false);
        onRestructureClose();
      });
  };

  const openRenumber = () => {
    onRenumberOpen();
    setLoadingRenumber(false);
  };

  const openRestructure = () => {
    onRestructureOpen();
    setLoadingRestructure(false);
  };

  return (
    <Container maxW="100%" height="calc(100vh - 80px)" bg="brand.50">
      <Center pt="8">
        {loading && <Spinner color="brand.600" />}
        {!qbLinked && !loading && (
          <Link to={authUrl}>
            <Button>Connect Quickbooks</Button>
          </Link>
        )}
      </Center>

      {qbLinked && !loading && (
        <>
          <ExportModal
            isOpen={isExportOpen}
            onClose={onExportClose}
            exportTransactions={exportTransactions}
            loading={loadingExport}
          />
          <SyncModal
            isOpen={isSyncOpen}
            onClose={onSyncClose}
            syncSubmit={syncQB}
            loading={loadingSync}
            type={syncType}
          />
          <RenumberModal
            isOpen={isRenumberOpen}
            onClose={onRenumberClose}
            renumberQBAccounts={renumberQBAccounts}
            loading={loadingRenumber}
            accounts={renumberAccounts}
          />
          {isRestructureOpen && (
            <RestructureModal
              isOpen={isRestructureOpen}
              onClose={onRestructureClose}
              restructureAccounts={restructureAccounts}
              loading={loadingRestructure}
              customer_id={customer_id}
            />
          )}
          <ErrorModal
            isOpen={isErrorOpen}
            onClose={onErrorClose}
            errors={errors}
          />
          <Center>
            <HStack>
              <Button onClick={disconnectQuickbooks}>
                Disconnect Quickbooks
              </Button>
              {missingMainAccounts && (
                <Button onClick={createMainAccounts}>
                  Create main accounts
                </Button>
              )}
              {renumberAccounts && (
                <Button onClick={openRenumber}>Renumber QB accounts</Button>
              )}
              <Button onClick={openRestructure}>Restructure QB accounts</Button>
              <Button onClick={openExport}>Export Transactions</Button>
              <Button onClick={() => openSync("Transactions")}>
                Sync Transactions
              </Button>
              <Button onClick={() => openSync("TB")}>Sync TB</Button>
            </HStack>
          </Center>
          <Box
            maxHeight="calc(100vh - 184px)"
            p="8"
            m="8"
            bg="white"
            overflowX="scroll"
          >
            <Text fontSize="xl" pb={3}>
              Sync accounts to Quickbooks
            </Text>
            {accounts && (
              <List>
                {accounts.map((account) => (
                  <QuickbooksAccount
                    key={account.id}
                    account={account}
                    customer_id={customer_id}
                    loadQuickbooksAccounts={loadQuickbooksAccounts}
                  />
                ))}
              </List>
            )}
            <CreateLocalAccounts
              customer_id={customer_id}
              accounts={extraAccounts}
              setAccounts={setExtraAccounts}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

const CreateLocalAccounts = ({ customer_id, accounts, setAccounts }) => {
  const [accountList, setAccountList] = useState([]);
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [accountSubTypeList, setAccountSubTypeList] = useState([]);
  const [accountTaxTypeList, setAccountTaxTypeList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentAccount, setCurrentAccount] = useState({});

  useEffect(() => {
    const getData = async () => {
      const accountResults = fetchCustomerAccounts(customer_id);
      const typeResults = fetchAccountTypes();
      const subTypeResults = fetchAccountSubTypes();
      const taxTypeResults = fetchAccountTaxTypes();

      setAccountList(await accountResults);
      setAccountTypeList(await typeResults);
      setAccountSubTypeList(await subTypeResults);
      setAccountTaxTypeList(await taxTypeResults);
    };
    getData();
  }, [customer_id]);

  const openModal = (account) => {
    setCurrentAccount({ name: account.name, number: account.number });
    onOpen();
  };

  const closeModal = (account) => {
    setCurrentAccount({});
    onClose();
  };

  const handleSubmit = async (event, account) => {
    event.preventDefault();

    axios
      .post(
        `/api/v2/customers/${customer_id}/accounts/`,
        account,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setAccounts(accounts.filter((a) => a.number !== res.data.number));
        setCurrentAccount({});
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <AccountModal
        isOpen={isOpen}
        toggle={closeModal}
        account={currentAccount}
        accountList={accountList}
        accountTypeList={accountTypeList}
        accountSubTypeList={accountSubTypeList}
        accountTaxTypeList={accountTaxTypeList}
        handleSubmit={handleSubmit}
      />

      <Text fontSize="xl" pb={3}>
        Sync accounts from Quickbooks
      </Text>
      {accounts && (
        <List>
          {accounts.map((account) => (
            <ListItem key={account.id}>
              <HStack pb={2}>
                <ListIcon as={FaLink} color="blue.500" />
                <Text>
                  {account.number} - {account.name}
                </Text>
                <Spacer />
                <Button onClick={() => openModal(account)}>Create</Button>
              </HStack>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

const QuickbooksAccount = ({
  account,
  customer_id,
  loadQuickbooksAccounts,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const context = React.useContext(Context);

  const accounts = account.potential_matches.map((a) => ({
    value: a.Id,
    label: a.Name,
  }));

  const createAccount = (account_id) => {
    setLoading(true);
    axios
      .post(
        `/api/v2/create_qb_account/`,
        {
          customer_id: customer_id,
          account_id: account_id,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status < 300) {
          loadQuickbooksAccounts(customer_id, setLoading);
        } else {
          context.fireToast("Error", res.response.data.error, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        context.fireToast("Error", err.response.data.error, "error");
        setLoading(false);
      });
  };

  const linkAccount = (account_id) => {
    if (!selectedItem) {
      context.fireToast("Error", "Please select account to link.", "error");
      return;
    }

    setLoading(true);
    axios
      .post(
        `/api/v2/link_qb_account/`,
        {
          customer_id: customer_id,
          account_id: account_id,
          qb_account_id: selectedItem.value,
        },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.status < 300) {
          loadQuickbooksAccounts(customer_id, setLoading);
        } else {
          context.fireToast("Error", res.response.data.error, "error");
          setLoading(false);
        }
      })
      .catch((err) => {
        context.fireToast("Error", err.response.data.error, "error");
        setLoading(false);
      });
  };

  return (
    <ListItem key={account.id}>
      <HStack pb={2}>
        {account.match_found && <ListIcon as={FaLink} color="green.500" />}
        {!account.match_found && <ListIcon as={FaUnlink} color="red.500" />}
        <Text>
          {account.number} - {account.name}
        </Text>
        <Spacer></Spacer>
        {!account.match_found && (
          <>
            {loading && <Spinner color="brand.600" />}
            <DropDown
              name="account"
              options={accounts}
              selectedValue={selectedItem ? selectedItem.value : null}
              onChange={setSelectedItem}
              w="450px"
              menuPortalTarget={document.body}
            />
            <Button onClick={() => linkAccount(account.id)}>Link</Button>
            <Button onClick={() => createAccount(account.id)}>Create</Button>
          </>
        )}
      </HStack>
      {account.match_found && account.children.length > 0 && (
        <List pl={5}>
          {account.children.map((account) => (
            <QuickbooksAccount
              key={account.id}
              account={account}
              customer_id={customer_id}
              loadQuickbooksAccounts={loadQuickbooksAccounts}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
};

const ExportModal = ({ isOpen, onClose, exportTransactions, loading }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const currentDate = new Date();

    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      2
    );
    const lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    setStartDate(firstDay.toISOString().split("T")[0]);
    setEndDate(lastDay.toISOString().split("T")[0]);
  }, []);

  const handleStartChange = (e) => {
    const { value } = e.target;
    setStartDate(value);
  };

  const handleEndChange = (e) => {
    const { value } = e.target;
    setEndDate(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Transactions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              defaultValue={startDate}
              name="startDate"
              onChange={handleStartChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              value={endDate}
              name="endDate"
              onChange={handleEndChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => exportTransactions(startDate, endDate)}
            isDisabled={loading}
          >
            {loading ? "Exporting..." : "Export"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SyncModal = ({ isOpen, onClose, syncSubmit, loading, type }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // const currentDate = new Date();
    // const firstDay = new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth() - 1,
    //   2
    // );
    // const lastDay = new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth(),
    //   1
    // );
    // setStartDate(firstDay.toISOString().split("T")[0]);
    // setEndDate(lastDay.toISOString().split("T")[0]);

    if (type === "Transactions") {
      setStartDate("2024-01-01");
      setEndDate("2025-02-28");
    } else {
      setStartDate("2023-12-31");
      setEndDate("2023-12-31");
    }
  }, [type]);

  const handleStartChange = (e) => {
    const { value } = e.target;
    setStartDate(value);
  };

  const handleEndChange = (e) => {
    const { value } = e.target;
    setEndDate(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sync {type}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              value={startDate}
              name="startDate"
              onChange={handleStartChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              value={endDate}
              name="endDate"
              onChange={handleEndChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => syncSubmit(startDate, endDate)}
            isDisabled={loading}
          >
            {loading ? "Syncing..." : "Sync"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RenumberModal = ({
  isOpen,
  onClose,
  renumberQBAccounts,
  loading,
  accounts,
}) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  useEffect(() => {
    setSelectedAccounts(accounts.map((a) => a.value));
  }, [accounts]);

  const handleAccountCheck = (value, checked) => {
    let accs = selectedAccounts.slice() || [];
    if (checked) {
      accs.push(value);
    } else {
      accs = accs.filter((e) => e !== value);
    }
    setSelectedAccounts(accs);
  };

  const toggleAll = () => {
    if (selectedAccounts.length > 0) {
      setSelectedAccounts([]);
    } else {
      setSelectedAccounts(accounts.map((a) => a.value));
    }
  };

  const select4000 = () => {
    setSelectedAccounts(
      accounts
        .filter(
          (a) =>
            a.label.substring(0, 4) * 1 >= 4000 &&
            a.label.substring(0, 4) * 1 < 8000
        )
        .map((a) => a.value)
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Renumber Quickbooks accounts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack pb={3}>
            <Button size={"sm"} onClick={toggleAll}>
              Toggle All
            </Button>
            <Button size={"sm"} onClick={select4000}>
              Select P&L
            </Button>
          </HStack>
          <FormControl id="accounts">
            {/* <FormLabel>Bank Accounts</FormLabel> */}
            <VStack>
              {accounts.map((a) => {
                return (
                  <Box w="100%" key={a.value}>
                    <Checkbox
                      value={a.value}
                      isChecked={selectedAccounts?.includes(a.value)}
                      onChange={(e) =>
                        handleAccountCheck(e.target.value, e.target.checked)
                      }
                    >
                      {a.label}
                    </Checkbox>
                  </Box>
                );
              })}
            </VStack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => renumberQBAccounts(selectedAccounts)}
            isDisabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RestructureModal = ({
  isOpen,
  onClose,
  customer_id,
  restructureAccounts,
  loading,
}) => {
  const [loadingInternal, setLoadingInternal] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  useEffect(() => {
    axios
      .get(
        `/api/v2/restructure_qb_accounts/${customer_id}/`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setAccounts(res.data.detail);
        setLoadingInternal(false);
        setSelectedAccounts(res.data.detail.map((a) => a.value));
      })
      .catch((err) => console.log(err));
  }, [customer_id]);

  const handleAccountCheck = (value, checked) => {
    let accs = selectedAccounts.slice() || [];
    if (checked) {
      accs.push(value);
    } else {
      accs = accs.filter((e) => e !== value);
    }
    setSelectedAccounts(accs);
  };

  const toggleAll = () => {
    if (selectedAccounts.length > 0) {
      setSelectedAccounts([]);
    } else {
      setSelectedAccounts(accounts.map((a) => a.value));
    }
  };

  const select4000 = () => {
    setSelectedAccounts(
      accounts
        .filter(
          (a) =>
            a.label.substring(0, 4) * 1 >= 4000 &&
            a.label.substring(0, 4) * 1 < 8000
        )
        .map((a) => a.value)
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Restructure Quickbooks accounts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loadingInternal && <Spinner color="brand.600" />}
          {!loadingInternal && (
            <HStack pb={3}>
              <Button size={"sm"} onClick={toggleAll}>
                Toggle All
              </Button>
              <Button size={"sm"} onClick={select4000}>
                Select P&L
              </Button>
            </HStack>
          )}
          <FormControl id="accounts">
            <VStack>
              {accounts?.map((a) => {
                return (
                  <Box w="100%" key={a.value}>
                    <Checkbox
                      value={a.value}
                      isChecked={selectedAccounts?.includes(a.value)}
                      onChange={(e) =>
                        handleAccountCheck(e.target.value, e.target.checked)
                      }
                    >
                      {a.label}
                    </Checkbox>
                  </Box>
                );
              })}
            </VStack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => restructureAccounts(selectedAccounts)}
            isDisabled={loading}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ErrorModal = ({ isOpen, onClose, errors }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Errors</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {Array.isArray(errors) &&
            errors.map((e, i) => (
              <>
                <Text key={i} as="b">
                  {e.error}
                </Text>
                {e.detail.map((d, i2) => (
                  <Text key={i + i2} mb={0}>
                    {d}
                  </Text>
                ))}
              </>
            ))}
          {!Array.isArray(errors) &&
            Object.entries(errors).map(([error, accounts]) => {
              return (
                <>
                  <Text key={error} fontWeight={"bold"}>
                    {error}
                  </Text>
                  {accounts.map((a) => {
                    return <Text>{a}</Text>;
                  })}
                  <Text>&nbsp;</Text>
                </>
              );
            })}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
