import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Link,
  Stack,
} from "@chakra-ui/react";
import { useUpdateCheck } from "react-update-notification";

const NotificationContainer = ({ rounded = null, p = null }) => {
  const { status, reloadPage } = useUpdateCheck({
    type: "interval",
    interval: 20000,
    ignoreServerCache: true,
  });

  if (status === "checking" || status === "current") {
    return null;
  }

  return (
    <Stack p={p ? p : 0} width={"100%"}>
      <Alert
        zIndex={100}
        status="info"
        backgroundColor={"#BEE3F8"}
        height={"48px"}
        width={"100%"}
        borderRadius={rounded ? "10px" : "0px"}
      >
        <AlertIcon />
        <AlertTitle>New version available!</AlertTitle>
        <AlertDescription>
          Please{" "}
          <Link onClick={reloadPage} textDecoration={"underline"}>
            click here
          </Link>{" "}
          to reload.
        </AlertDescription>
      </Alert>
    </Stack>
  );
};

export default NotificationContainer;
