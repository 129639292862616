import {
  Button,
  Card,
  Container,
  Grid,
  HStack,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Menu } from "react-feather";
import { MyDetails } from "./myDetails";

export const ProfileSettings = ({
  setNavIndex = null,
  setNavVisible = null,
  trigger,
}) => {
  const [tab, setTab] = useState("myDetails");

  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  return (
    <Container
      maxW="100%"
      bg="#F4F4F2"
      minH={"100vh"}
      p="0px"
      overflowY={setNavIndex ? "hidden" : "auto"}
      overflowX={"hidden"}
      position={"relative"}
    >
      {isMobile ? (
        <Card
          position="fixed"
          zIndex={302}
          borderRadius={0}
          h="72px"
          borderBottom="1px solid #e0e0e0"
          width="100%"
        >
          <HStack
            justifyContent="space-between"
            alignItems="center"
            h={"100%"}
            w="100%"
            px={4}
          >
            <Button
              bg="white"
              _hover={{ color: "white" }}
              _active={{ color: "white" }}
              w="50px"
              h="50px"
              p={2}
              onClick={() => setNavVisible && setNavVisible(true)}
            >
              <Menu style={{ width: "50px", height: "100%" }} color="black" />
            </Button>
          </HStack>
        </Card>
      ) : (
        <></>
      )}
      <>
        <Card
          position={"fixed"}
          zIndex={301}
          borderRadius={0}
          mt={isMobile ? "72px" : "0px"}
          h={isMobile ? "50px" : "72px"}
          borderBottom="1px solid #e0e0e0"
          width={isMobile ? "100%" : "100%"}
          marginBottom={100}
        >
          <Tabs index={tab === "myDetails" ? 0 : 1}>
            {!isMobile ? (
              <Grid
                templateColumns="1fr 2fr 9fr"
                width="100%"
                alignItems="center"
                p="0px 20px"
                h={isMobile ? "50px" : "72px"}
              >
                <TabList
                  display="flex"
                  alignItems="center"
                  height={isMobile ? "50px" : "73px"}
                >
                  <Tab
                    _focus={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                    _selected={{
                      borderBottom: "3px solid #536DEC",
                      color: "#536DEC",
                    }}
                    _active={{ background: "none" }}
                    cursor="pointer"
                    onClick={() => {
                      setTab("myDetails");
                      setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                    }}
                    width="90px"
                    height="73px"
                    p="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontWeight="400" fontSize="16px">
                      My Details
                    </Text>
                  </Tab>
                </TabList>
              </Grid>
            ) : (
              <HStack width="100%" align="center" justify="flex-start">
                <TabList
                  display="flex"
                  justifyContent="flex-start"
                  paddingLeft={2}
                  paddingRight={2}
                  w="100%"
                >
                  <Tab
                    _focus={{
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                    _selected={{
                      borderBottom: "3px solid #536DEC",
                      color: "#536DEC",
                    }}
                    _active={{ background: "none" }}
                    cursor="pointer"
                    onClick={() => {
                      setTab("growthPlan");
                      setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                    }}
                    h="50px"
                    p="2px 4px"
                    whiteSpace="nowrap" // Prevents text from wrapping
                  >
                    <Text fontWeight="400" fontSize="16px" textAlign="center">
                      My Details
                    </Text>
                  </Tab>
                </TabList>
              </HStack>
            )}
          </Tabs>
        </Card>

        {tab === "myDetails" ? (
          <MyDetails trigger={trigger} />
        ) : (
          <Text>wrong tab</Text>
        )}
      </>
    </Container>
  );
};
