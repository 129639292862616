import React, { useState } from "react";
import { Input, FormControl, FormLabel, Button } from "@chakra-ui/react";
import { GenericModal } from "../generic/modal";
import { createVendor } from "../../api/vendors";

export const VendorsQuickCreate = ({
  addVendorToList,
  isOpen,
  onClose,
  initialName,
}) => {
  const [name, setName] = useState(initialName);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    createVendor({ name: name })
      .then((res) => {
        addVendorToList({ value: res.data.id, label: res.data.name });
        setName("");
        onClose();
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Create Vendor"}
      loading={loading}
      body={
        <form id="vendorForm" onSubmit={handleSubmit}>
          <FormControl id="name" isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
        </form>
      }
      submitButton={
        <Button ml="3" type="submit" form="vendorForm">
          Create
        </Button>
      }
    />
  );
};
