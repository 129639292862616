import {
    Button,
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Download } from 'react-feather';
import PopupUpdate from '../popup_update';

export const PlaidBankTransactionExport = ({ account_id }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [detailed, setDetailed] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const currentDate = new Date();

        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 2);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        setStartDate(firstDay.toISOString().split('T')[0]);
        setEndDate(lastDay.toISOString().split('T')[0]);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (!startDate || !endDate) {
            setError('Start date and end date are required.');
            return;
        }

        if (new Date(startDate) > new Date(endDate)) {
            setError('Start date cannot be greater than end date.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`/api/v2/bankaccounts/transactions/export/${account_id}/`,
            { start_date: startDate, end_date: endDate, detailed: detailed, account_id: account_id },
            { responseType: 'blob' });

            if (response.status === 200) {
                const filename = response.headers['content-disposition'].split('filename=')[1];
                let filename_modified = filename.slice(1, -1)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename_modified);
                document.body.appendChild(link);
                link.click();
                link.remove();
                onClose();
            } else {
                setError('Unexpected response from the server.');
            }
        } catch (error) {
            setError('There was an error submitting the form!');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button onClick={onOpen} _hover={{ bg: "black", border: "1px solid white", color: "white" }} backgroundColor={"white"} color={"black"} gap={1} border={"1px solid black"}><Download size={"15px"}/>Export</Button>
            <PopupUpdate
                isOpen={isOpen}
                onClose={onClose}
                title="Export - Bank Transactions"
                body="Please fill in the details below to create a new export."
                confirmText="Export"
                confirmAction={handleSubmit}
                loading={loading}
                errorMessage={error}
            >
                <form onSubmit={handleSubmit}>
                    <FormControl id="start_date">
                        <FormLabel>Start Date</FormLabel>
                        <Input
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </FormControl>
                    <FormControl id="end_date">
                        <FormLabel>End Date</FormLabel>
                        <Input
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </FormControl>
                    <FormControl display="flex" alignItems="center" mt={4}>
                        <Checkbox
                        isChecked={detailed}
                        onChange={(e) => setDetailed(e.target.checked)}
                        >
                        Detailed
                        </Checkbox>
                    </FormControl>
                    <Input
                        type="text"
                        id="account_id"
                        value={account_id}
                        hidden
                        readOnly
                    />
                    {error && (
                        <FormErrorMessage>{error}</FormErrorMessage>
                    )}
                </form>
            </PopupUpdate>
        </div>
    );
};
