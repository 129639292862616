import { LinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AlertCircle, Cloud, CloudOff, Edit3, Menu } from "react-feather";
import { useLocation } from "react-router-dom";
import { fetchCustomerAccounts } from "../../api/accounts";
import { Context, useCurrentProfile } from "../../ContextWrapper";
import CustomTableContainer from "../../theme/components/tableContainer";
import { AccessToken, titleCase } from "../../utils";
import { DropDown } from "../generic/dropDown";
import NotificationContainer from "../generic/updateNotification";
import { LinkPlaidButton } from "../plaid/linkPlaid";
import { PlaidBankTransactionExport } from "../plaid/plaidBankTransaction";
import { PlaidItemCreate } from "../plaid/plaidItemCreate";
import ClientPLaidItemDelete from "./clientPlaidDelete";
import EditQuickbooksName from "./editQuickbooksName";

export const ClientPlaid = ({ customer_id, setNavVisible = null }) => {
  const [plaidItemList, setPlaidItemList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const {
    isOpen: isStartOpen,
    onOpen: onStartOpen,
    onClose: onStartClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  const context = React.useContext(Context);
  const { profile } = useCurrentProfile();
  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  useEffect(() => {
    let accessToken = AccessToken();
    if (accessToken === null) {
      window.location.href = "/login";
    } else {
      refreshPlaidItemList(customer_id);
      refreshSubscription(customer_id);

      if (customer_id) {
        const getData = async () => {
          var accountResults = fetchCustomerAccounts(
            customer_id,
            { type__name__in: "Bank,Credit Card", is_main_account: false },
            true
          );
          setAccountList(await accountResults);
        };
        getData();
      }
      setLoading(false);
    }
  }, [customer_id]);

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
      setTimeout(() => setMessage(""), 3000);
    }
  }, [location.state]);

  const refreshPlaidItemList = (id) => {
    if (id) {
      setLoading(true);
      axios
        .get(
          `/api/v2/customers/${id}/plaiditems`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setPlaidItemList(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const addPlaidItemToList = (plaid_item) => {
    setPlaidItemList((prevList) => [...prevList, plaid_item]);
    context.fireToast("Success", "Bank created.", "success");
  };

  const removePlaidItemFromList = (plaid_item_id) => {
    setPlaidItemList((prevList) =>
      prevList.filter((plaid_item) => plaid_item.id !== plaid_item_id)
    );
    context.fireToast("Success", "Bank deleted.", "success");
  };

  const refreshSubscription = (id) => {
    if (id) {
      axios
        .get(
          `/api/v2/customers/${id}/subscriptions/?status=active`,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          if (res.data.length > 0) {
            setSubscription(res.data[0]);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const openSubscription = () => {
    if (subscription?.status === "active") {
      onViewOpen();
    } else {
      onStartOpen();
    }
  };

  return (
    <Container
      maxW="100%"
      bgColor="#F4F4F2"
      h={profile?.type !== "client" ? "auto" : "calc(100vh)"}
      p={"0px"}
      overflowY={"auto"}
      position={"relative"}
      minH={"100%"}
    >
      {isMobile && profile?.type === "client" ? (
        <Card
          position="absolute"
          zIndex={10}
          borderRadius={0}
          h="75px"
          border="1px solid #e0e0e0"
          width="100%"
        >
          <HStack
            justifyContent="space-between"
            alignItems="center"
            h={"100%"}
            w="100%"
            px={4}
          >
            <Button
              bg="white"
              _hover={{ color: "white" }}
              _active={{ color: "white" }}
              w="50px"
              h="50px"
              p={2}
              onClick={() => setNavVisible && setNavVisible(true)}
            >
              <Menu style={{ width: "50px", height: "100%" }} color="black" />
            </Button>
          </HStack>
        </Card>
      ) : (
        <></>
      )}
      {profile?.type === "client" ? (
        <Card
          mt={isMobile ? "72px" : "0px"}
          borderRadius={0}
          width="100%"
          h="75px"
          position={"absolute"}
          borderBottom="1px solid #e0e0e0"
          mb={1}
        >
          <SimpleGrid
            templateColumns={isMobile ? "1fr" : "2fr 2fr"}
            top={"50%"}
            padding={"24px"}
          >
            <Box
              gridRow={1}
              gridColumn={1}
              textAlign={"left"}
              fontWeight={700}
              fontSize={"24px"}
              whiteSpace={"nowrap"}
            >
              Account Management
            </Box>
          </SimpleGrid>
        </Card>
      ) : (
        <></>
      )}
      <Stack
        pt={
          isMobile && profile?.type === "client"
            ? "174px"
            : !isMobile && profile?.type === "client"
            ? "99px"
            : "20px"
        }
        pl={"24px"}
        pr={"24px"}
        width={"100%"}
      >
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 2 }} // Ensure it switches to 1 column on smaller screens
          pb="24px"
          gap="10px"
          alignItems="center" // Align items properly
        >
          <Text
            fontSize="24px"
            fontWeight={700}
            width="100%"
            whiteSpace="nowrap"
          >
            Plaid Listing
          </Text>

          <Flex
            justifyContent={{ base: "flex-start", md: "flex-end" }}
            overflow="hidden"
          >
            <Box w="100%" maxW="100%">
              <Stack
                direction="row"
                align="center"
                wrap="wrap" // Ensure wrapping instead of overflow
                gap="10px" // Add spacing between buttons
                justify={{ base: "flex-start", md: "flex-end" }} // Align based on screen size
              >
                {profile?.type !== "client" && (
                  <>
                    {subscription?.status !== "active" && (
                      <Box mr="10px">
                        <CloudOff color="red" />
                      </Box>
                    )}
                    {subscription?.status === "active" && (
                      <Box mr="10px">
                        <Cloud color="green" />
                      </Box>
                    )}
                  </>
                )}
                <Button onClick={openSubscription}>Subscription</Button>
                <StartSubscriptionModal
                  isOpen={isStartOpen}
                  onClose={onStartClose}
                  loading={loading}
                  setSubscription={setSubscription}
                  customer_id={customer_id}
                  plaidItemList={plaidItemList}
                />
                <ViewSubscriptionModal
                  isOpen={isViewOpen}
                  onClose={onViewClose}
                  loading={loading}
                  subscription={subscription}
                  setSubscription={setSubscription}
                  customer_id={customer_id}
                />
                {profile?.type !== "client" && (
                  <PlaidItemCreate
                    customer_id={customer_id}
                    addPlaidItemToList={addPlaidItemToList}
                  />
                )}
                <LinkPlaidButton
                  customer_id={customer_id}
                  refreshList={refreshPlaidItemList}
                  setLoading={setLoading}
                />
                {loading && (
                  <FontAwesomeIcon
                    className="float-end fa-spin"
                    icon={faSpinner}
                    color="green"
                  />
                )}
              </Stack>
            </Box>
          </Flex>
        </SimpleGrid>
        <NotificationContainer rounded={true} p={"12px 0px 0px"} />
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}

        {plaidItemList &&
          plaidItemList.map((item) => (
            <PlaidItem
              key={item.id}
              item={item}
              customer_id={customer_id}
              accountList={accountList}
              setLoading={setLoading}
              removePlaidItemFromList={removePlaidItemFromList}
              refreshPlaidItemList={refreshPlaidItemList}
            />
          ))}
      </Stack>
    </Container>
  );
};

const PlaidItem = ({
  item,
  customer_id,
  accountList,
  setLoading,
  removePlaidItemFromList,
  refreshPlaidItemList,
}) => {
  const { profile } = useCurrentProfile();

  const hasError = item.plaiditemerror_set.some(
    (error) =>
      error.code === "ITEM_LOGIN_REQUIRED" ||
      error.code === "USER_PERMISSION_REVOKED"
  );

  return (
    <CustomTableContainer p={0} m={"0px 0px 20px"} border={0}>
      <Table>
        <Thead h={"56px"} backgroundColor={"#1A202C"}>
          <Tr key={item.id}>
            <Td>
              <HStack>
                <Text
                  mr={"5px"}
                  backgroundColor={"#1A202C"}
                  fontSize={"20px"}
                  color={"white"}
                  fontWeight={600}
                >
                  {item.name}
                </Text>
                {item.plaiditemerror_set.map((error) => (
                  <Tooltip key={error.id} label={error.message} fontSize="md">
                    <AlertCircle color="red" />
                  </Tooltip>
                ))}
              </HStack>
            </Td>
            <Td width="100px">
              <HStack>
                {profile?.type !== "client" && (
                  <ClientPLaidItemDelete
                    customer_id={customer_id}
                    plaiditem_id={item.id}
                    removePlaidItemFromList={removePlaidItemFromList}
                  />
                )}
                {hasError && (
                  <LinkPlaidButton
                    customer_id={customer_id}
                    refreshList={refreshPlaidItemList}
                    setLoading={setLoading}
                    plaiditem_id={item.id}
                  />
                )}
              </HStack>
            </Td>
          </Tr>
        </Thead>
      </Table>
      <CustomTableContainer
        p={0}
        border={0}
        borderBottomRadius={0}
        boxShadow={0}
        borderBottom={"1px solid #0000000F"}
        m={"30px 30px 24px"}
      >
        <Table variant="unstyled" size="sm" width="100%">
          <Thead h={"40px"}>
            <Tr>
              <Th width={"72px"}></Th>
              <Th fontSize={"16px"} fontWeight={700}>
                Account Name
              </Th>
              {profile?.type !== "client" && (
                <Th fontSize={"16px"} fontWeight={700}>
                  QB Name
                </Th>
              )}
              {profile?.hasAccountingAccess && profile?.type !== "client" && (
                <Th fontSize={"16px"} fontWeight={700}>
                  Account Link
                </Th>
              )}
              <Th fontSize={"16px"} fontWeight={700}>
                Type
              </Th>
              {profile?.type !== "client" && (
                <>
                  <Th></Th>
                  <Th></Th>
                </>
              )}
              {profile?.type !== "client" ? (
                <Th fontSize={"16px"} fontWeight={700}>
                  Actions
                </Th>
              ) : (
                <Th></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {item.bankaccount_set.map((account) => (
              <BankAccount
                key={account.id}
                account={account}
                accountList={accountList}
                setLoading={setLoading}
              />
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td></Td>
            </Tr>
          </Tfoot>
        </Table>
      </CustomTableContainer>
    </CustomTableContainer>
  );
};

const BankAccount = ({ account, accountList, setLoading }) => {
  const { profile } = useCurrentProfile();
  const [quickbooksName, setQuickBooksName] = useState(
    account.quickbooks_name ? account.quickbooks_name : "..."
  );
  const [isEditing, setIsEditing] = useState(false);
  const context = React.useContext(Context);
  const updateBankAccount = (selectedAccount) => {
    setLoading(true);

    const formattedAccountData = { accounting_account_id: selectedAccount };

    axios
      .patch(`/api/v2/bankaccounts/${account.id}/`, formattedAccountData)
      .then((res) => {
        setLoading(false);
        context.fireToast("Success", "Account updated", "success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Tr key={account.id} h={"52px"}>
      <Td fontSize={"18px"} width={"72px"} textAlign={"center"}>
        {account.plaid_linked ? (
          <LinkIcon color="#536DEC" />
        ) : (
          <LinkIcon color="red" />
        )}
      </Td>
      <Td fontSize={"18px"}>{account.name}</Td>
      {profile?.type !== "client" && (
        <Td>
          <EditQuickbooksName
            bankAccountId={account.id}
            initialName={quickbooksName}
            setQBName={setQuickBooksName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Td>
      )}
      {profile?.hasAccountingAccess && profile?.type !== "client" && (
        <Td fontSize={"18px"}>
          <DropDown
            name="account"
            options={accountList}
            selectedValue={account.accounting_account?.id}
            onChange={(account) => updateBankAccount(account.value)}
            w="350px"
            menuPortalTarget={document.body}
          />
        </Td>
      )}
      <Td fontSize={"18px"}>{account.subtype}</Td>
      {profile?.type !== "client" && (
        <>
          <Td></Td>
          <Td></Td>
        </>
      )}
      {profile?.type !== "client" ? (
        <Td>
          <Flex direction={"row"} gap={2}>
            <Button
              backgroundColor={"white"}
              color={"black"}
              _hover={{
                backgroundColor: "#627CFB",
                border: "1px solid #627CFB",
                color: "white",
              }}
              border={"1px solid black"}
              onClick={() => setIsEditing(true)}
              cursor="pointer"
              size="sm"
              h="40px"
            >
              <Edit3 size={15} />
              <Text ml={1}>Edit</Text>
            </Button>
            <PlaidBankTransactionExport account_id={account.id} />
          </Flex>
        </Td>
      ) : (
        <Td></Td>
      )}
    </Tr>
  );
};

const ViewSubscriptionModal = ({
  isOpen,
  onClose,
  loading,
  subscription,
  setSubscription,
  customer_id,
}) => {
  const cancelSubscription = () => {
    axios
      .delete(
        `/api/v2/customers/${customer_id}/subscriptions/${subscription.id}/`,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setSubscription({});
        onClose();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subscription?.id && (
            <Text>
              {titleCase(subscription.status)} since {subscription.start_date}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="button"
            isDisabled={loading}
            ml={3}
            onClick={cancelSubscription}
          >
            {loading ? <Spinner size="sm" /> : "End Subscription"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StartSubscriptionModal = ({
  isOpen,
  onClose,
  loading,
  setSubscription,
  customer_id,
  plaidItemList,
}) => {
  const [data, setData] = useState({ day_of_month: 25 });

  const handleSubmit = (event) => {
    event.preventDefault();
    data.customer = customer_id;
    axios
      .post(
        `/api/v2/customers/${customer_id}/subscriptions/`,
        data,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setSubscription(res.data);
        onClose();
      })
      .catch((err) => console.log(err));
  };

  const bankAccounts = plaidItemList
    ?.map((item) =>
      item.bankaccount_set.map((bankAccount) => {
        return {
          value: bankAccount.id,
          label: bankAccount.name,
        };
      })
    )
    .flat();

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleDayChange = (_, day) => {
    const name = "day_of_month";
    setData({ ...data, [name]: day });
  };

  const handleSelectBankAccount = (bankaccount) => {
    setData({ ...data, bankaccount: bankaccount.value });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Subscription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="subscribeForm" onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="bankaccount">Bank Account</FormLabel>
              <DropDown
                name="bankaccount"
                options={bankAccounts}
                selectedValue={data.parent}
                onChange={handleSelectBankAccount}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="legal_name">Legal Name</FormLabel>
              <Input
                type="text"
                name="legal_name"
                defaultValue={data.legal_name}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="day_of_month">Day of Month</FormLabel>

              <NumberInput
                name="day_of_month"
                value={data.day_of_month}
                onChange={handleDayChange}
                required
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                name="start_date"
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            isDisabled={loading}
            colorScheme="blackAlpha"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form="subscribeForm"
            isDisabled={loading}
            ml={3}
          >
            {loading ? <Spinner size="sm" /> : "Subscribe"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
