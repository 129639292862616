import { Input, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import { Context } from "../../ContextWrapper";

const EditQuickbooksName = ({ bankAccountId, initialName, setQBName, isEditing, setIsEditing }) => {
  const [name, setName] = useState(initialName || "...");
  const context = React.useContext(Context);

  const handleBlur = async () => {
    setIsEditing(false);

    if (!name.trim() || name !== initialName) {
      try {
        await axios.patch(`/api/v2/bankaccounts/${bankAccountId}/`, {
          quickbooks_name: name,
        });
        context.fireToast("Success", "Quickbooks name updated.", "success");
      } catch (error) {
        console.error("Error updating name:", error);
        setName(initialName || "...");
        setQBName(initialName || "...");
        context.fireToast("Error", "Quickbooks update error.", "error");
      }
      if (!name.trim()) {
        setName("...");
        setQBName("...");
      }
    }
  };

  return isEditing ? (
    <Input
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleBlur();
        }
      }}
      autoFocus
      size="sm"
      h="30px"
      width="100%"
    />
  ) : (
    <Text isTruncated maxWidth="100%">{name}</Text>
  );
};

export default EditQuickbooksName;
